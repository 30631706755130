import React from "react";
import _ from "lodash";
import { Button, Modal } from "react-bootstrap";
import { votingApplicatoon } from "../../../../client/GovernanceManagerClient";
import { makeStyles, withStyles } from "@material-ui/core/styles";
import {
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
  Paper
} from "@material-ui/core";
import { deepOrange, deepPurple } from "@material-ui/core/colors";

const StyledTableCell = withStyles(theme => ({
  head: {
    backgroundColor: "#8950FC",
    color: theme.palette.common.white
  },
  body: {
    fontSize: 14
  }
}))(TableCell);

const useStyles = makeStyles(theme => ({
  root: {
    width: "100%",
    marginTop: theme.spacing(3),
    overflowX: "auto"
  },
  table: {
    minWidth: 650
  },
  avatar: {
    margin: 10
  },
  orangeAvatar: {
    margin: 10,
    color: "#fff",
    backgroundColor: deepOrange[500]
  },
  purpleAvatar: {
    margin: 10,
    color: "#fff",
    backgroundColor: deepPurple[500]
  },
  button: {
    margin: theme.spacing(1)
  },
  input: {
    display: "none"
  }
}));

export default function ApplicationDetailModal({
  authToken,
  applyApplication,
  setApplicationDetailModalShow,
  getApplyApplicatons
}) {
  const classes = useStyles();
  const myVoteItem = _.find(
    applyApplication.governanceEvaluationResults,
    f => f.isMyVote
  );

  const handleVoting = async status => {
    const confirm = window.confirm(`Save the evaluation state as ${status}?`);
    if (!confirm) {
      return;
    }

    try {
      const response = await votingApplicatoon({
        authToken,
        id: applyApplication.id,
        body: {
          type: "FINAL",
          status
        }
      });
      if (response) {
        window.alert(`Saved!`);
        setApplicationDetailModalShow(false);
        getApplyApplicatons();
      }
    } catch (e) {
      window.alert(e.message);
    }
  };

  return (
    <Modal
      show={true}
      size="lg"
      aria-labelledby="contained-modal-title-vcenter"
      onHide={() => {
        setApplicationDetailModalShow(false);
      }}
      centered
    >
      <Modal.Header closeButton>
        <Modal.Title id="contained-modal-title-vcenter">
          Apply Application Infomation
        </Modal.Title>
      </Modal.Header>
      <Modal.Body style={{ maxHeight: "650px", overflowX: "scroll" }}>
        <div style={{ textAlign: "right" }}>
          <Button
            variant={`${
              myVoteItem && myVoteItem.finalApproval === "WAIT"
                ? "success"
                : "outline-info"
            }`}
            onClick={() => {
              handleVoting("WAIT");
            }}
          >
            WAIT
          </Button>
          <Button
            variant={`${
              myVoteItem && myVoteItem.finalApproval === "PENDING"
                ? "success"
                : "outline-info"
            }`}
            style={{ marginLeft: "3px" }}
            onClick={() => {
              handleVoting("PENDING");
            }}
          >
            PENDING
          </Button>
          <Button
            variant={`${
              myVoteItem && myVoteItem.finalApproval === "REJECTED"
                ? "success"
                : "outline-info"
            }`}
            style={{ marginLeft: "3px" }}
            onClick={() => {
              handleVoting("REJECTED");
            }}
          >
            REJECTED
          </Button>
          <Button
            variant={`${
              myVoteItem && myVoteItem.finalApproval === "APPROVE"
                ? "success"
                : "outline-info"
            }`}
            style={{ marginLeft: "3px" }}
            onClick={() => {
              handleVoting("APPROVE");
            }}
          >
            APPROVE
          </Button>
        </div>
        <div className="form">
          <div className="card-body">
            <div className="row">
              <label className="col-xl-3"></label>
              <div className="col-lg-9 col-xl-6">
                <h5 className="font-weight-bold mt-10 mb-6">
                  Contact Information
                </h5>
              </div>
            </div>
            <div className="form-group row">
              <label className="col-xl-3 col-lg-3 col-form-label">
                Contact Name:
              </label>
              <div className="col-lg-9 col-xl-6">
                {applyApplication.contactName}
              </div>
            </div>

            <div className="form-group row">
              <label className="col-xl-3 col-lg-3 col-form-label">
                Contact Email:
              </label>
              <div className="col-lg-9 col-xl-6">
                {applyApplication.contactEmail}
              </div>
            </div>

            <div className="form-group row">
              <label className="col-xl-3 col-lg-3 col-form-label">
                Contact Telegram:
              </label>
              <div className="col-lg-9 col-xl-6">
                <a
                  href={applyApplication.contactTelegram}
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  {applyApplication.contactTelegram}
                </a>
              </div>
            </div>

            <div className="row">
              <label className="col-xl-3"></label>
              <div className="col-lg-9 col-xl-6">
                <h5 className="font-weight-bold mt-10 mb-6">
                  Basic Information
                </h5>
              </div>
            </div>
            <div className="form-group row">
              <label className="col-xl-3 col-lg-3 col-form-label">
                Project Name:
              </label>
              <div className="col-lg-9 col-xl-6">
                {applyApplication.projectName}
              </div>
            </div>
            <div className="form-group row">
              <label className="col-xl-3 col-lg-3 col-form-label">
                Project Type:
              </label>
              <div className="col-lg-9 col-xl-6">
                {applyApplication.projectType}
              </div>
            </div>
            <div className="form-group row">
              <label className="col-xl-3 col-lg-3 col-form-label">
                Website URL:
              </label>
              <div className="col-lg-9 col-xl-6">
                <a
                  href={applyApplication.website}
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  {applyApplication.website}
                </a>
              </div>
            </div>
            <div className="form-group row">
              <label className="col-xl-3 col-lg-3 col-form-label">
                Whitepaper:
              </label>
              <div className="col-lg-9 col-xl-6">
                <a
                  href={applyApplication.whitepaper}
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  {applyApplication.whitepaper}
                </a>
              </div>
            </div>
            <div className="form-group row">
              <label className="col-xl-3 col-lg-3 col-form-label">
                Twitter URL:
              </label>
              <div className="col-lg-9 col-xl-6">
                <a
                  href={applyApplication.twitter}
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  {applyApplication.twitter}
                </a>
              </div>
            </div>
            <div className="form-group row">
              <label className="col-xl-3 col-lg-3 col-form-label">
                Telegram Handle:
              </label>
              <div className="col-lg-9 col-xl-6">
                <a
                  href={applyApplication.telegram}
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  {applyApplication.telegram}
                </a>
              </div>
            </div>
            <div className="form-group row">
              <label className="col-xl-3 col-lg-3 col-form-label">
                Token Information:
              </label>
              <div className="col-lg-9 col-xl-6">
                {applyApplication.tokenInfomation}
              </div>
            </div>
            <div className="form-group row">
              <label className="col-xl-3 col-lg-3 col-form-label">
                Description:
              </label>
              <div className="col-lg-9 col-xl-6">
                {applyApplication.description}
              </div>
            </div>
            <div className="row">
              <label className="col-xl-3"></label>
              <div className="col-lg-9 col-xl-6">
                <h5 className="font-weight-bold mt-10 mb-6">
                  Progress Information
                </h5>
              </div>
            </div>
            <div className="form-group row">
              <label className="col-xl-3 col-lg-3 col-form-label">Team:</label>
              <div className="col-lg-9 col-xl-6">
                {applyApplication.founderLinkedins}
              </div>
            </div>
            <div className="form-group row">
              <label className="col-xl-3 col-lg-3 col-form-label">
                Previous Work
              </label>
              <div className="col-lg-9 col-xl-6">
                {applyApplication.previousWork}
              </div>
            </div>
            <div className="form-group row">
              <label className="col-xl-3 col-lg-3 col-form-label">
                Github URL:
              </label>
              <div className="col-lg-9 col-xl-6">
                <a
                  href={applyApplication.github}
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  {applyApplication.github}
                </a>
              </div>
            </div>
            <div className="form-group row">
              <label className="col-xl-3 col-lg-3 col-form-label">
                State of Development:
              </label>
              <div className="col-lg-9 col-xl-6">
                {applyApplication.serviceStatus}
              </div>
            </div>
            <div className="form-group row">
              <label className="col-xl-3 col-lg-3 col-form-label">
                How much you raised?:
              </label>
              <div className="col-lg-9 col-xl-6">
                {applyApplication.predictionRaised}
              </div>
            </div>

            <div className="row">
              <label className="col-xl-3"></label>
              <div className="col-lg-9 col-xl-6">
                <h5 className="font-weight-bold mt-10 mb-6">
                  Dexlab Pool Information
                </h5>
              </div>
            </div>
            <div className="form-group row">
              <label className="col-xl-3 col-lg-3 col-form-label">
                How much are you looking to raise with us? ($):
              </label>
              <div className="col-lg-9 col-xl-6">
                {applyApplication.totalRaise}
              </div>
            </div>
            <div className="form-group row">
              <label className="col-xl-3 col-lg-3 col-form-label">
                When would you like to launch:
              </label>
              <div className="col-lg-9 col-xl-6">
                {applyApplication.launchDate}
              </div>
            </div>
            <div className="form-group row">
              <label className="col-xl-3 col-lg-3 col-form-label">
                IDO Chain:
              </label>
              <div className="col-lg-9 col-xl-6">
                {applyApplication.idoChain}
              </div>
            </div>
          </div>
        </div>

        <div className="form-group row">
          <label className="col-xl-3 col-lg-3 col-form-label">
            <h4>Voting</h4>
          </label>
        </div>
        <div style={{ marginTop: "10px" }}>
          {!_.isEmpty(applyApplication.governanceEvaluationResults) ? (
            <Paper className={classes.root}>
              <Table className={classes.table}>
                <TableHead>
                  <TableRow>
                    <StyledTableCell style={{ wwordBreak: "break-all" }}>
                      Name
                    </StyledTableCell>
                    <StyledTableCell style={{ wwordBreak: "break-all" }}>
                      Status
                    </StyledTableCell>
                    <StyledTableCell style={{ wwordBreak: "break-all" }}>
                      Memo
                    </StyledTableCell>
                    <StyledTableCell style={{ wwordBreak: "break-all" }}>
                      UpdatedAt
                    </StyledTableCell>
                  </TableRow>
                </TableHead>
                <TableBody>
                  {applyApplication.governanceEvaluationResults.map(
                    (evaluation, idx) => {
                      return (
                        <TableRow key={idx}>
                          <StyledTableCell component="th" scope="row">
                            {`${evaluation.isMyVote ? "[me] " : ""}`}
                            {evaluation.userName}
                          </StyledTableCell>
                          <StyledTableCell component="th" scope="row">
                            {evaluation.finalApproval}
                          </StyledTableCell>
                          <StyledTableCell component="th" scope="row">
                            {evaluation.memo ?? "-"}
                          </StyledTableCell>
                          <StyledTableCell component="th" scope="row">
                            {evaluation.updatedAt}
                          </StyledTableCell>
                        </TableRow>
                      );
                    }
                  )}
                </TableBody>
              </Table>
            </Paper>
          ) : (
            "There are no voters."
          )}
        </div>
      </Modal.Body>
      <Modal.Footer>
        <Button
          onClick={() => {
            setApplicationDetailModalShow(false);
          }}
        >
          Close
        </Button>
      </Modal.Footer>
    </Modal>
  );
}
