import React, { useState } from "react";
import _ from "lodash";
import { makeStyles, withStyles } from "@material-ui/core/styles";
import {
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
  Paper
} from "@material-ui/core";
import { deepOrange, deepPurple } from "@material-ui/core/colors";
import ApplicationDetailModal from "../component/ApplicationDetailModal";

const StyledTableCell = withStyles(theme => ({
  head: {
    backgroundColor: "#8950FC",
    color: theme.palette.common.white
  },
  body: {
    fontSize: 14
  }
}))(TableCell);

const useStyles = makeStyles(theme => ({
  root: {
    width: "100%",
    marginTop: theme.spacing(3),
    overflowX: "auto"
  },
  table: {
    minWidth: 650
  },
  avatar: {
    margin: 10
  },
  orangeAvatar: {
    margin: 10,
    color: "#fff",
    backgroundColor: deepOrange[500]
  },
  purpleAvatar: {
    margin: 10,
    color: "#fff",
    backgroundColor: deepPurple[500]
  },
  button: {
    margin: theme.spacing(1)
  },
  input: {
    display: "none"
  }
}));

export default function ApplyApplicationList({
  authToken,
  applyApplications,
  getApplyApplicatons
}) {
  const classes = useStyles();
  const [applicationDetailModalShow, setApplicationDetailModalShow] = useState(
    false
  );
  const [selectApplication, setSelectApplication] = useState(null);

  return (
    <>
      <Paper className={classes.root}>
        <Table className={classes.table}>
          <TableHead>
            <TableRow>
              <StyledTableCell style={{ wwordBreak: "break-all" }}>
                #Id
              </StyledTableCell>
              <StyledTableCell style={{ wwordBreak: "break-all" }}>
                Project Name
              </StyledTableCell>
              <StyledTableCell style={{ wwordBreak: "break-all" }}>
                Website
              </StyledTableCell>
              <StyledTableCell style={{ wwordBreak: "break-all" }}>
                Contact Info
              </StyledTableCell>
              <StyledTableCell style={{ wwordBreak: "break-all" }}>
                My Voting Status
              </StyledTableCell>
              <StyledTableCell style={{ wwordBreak: "break-all" }}>
                Request Date
              </StyledTableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {applyApplications.map((application, idx) => {
              const myVoteItem = _.find(
                application.governanceEvaluationResults,
                f => f.isMyVote
              );

              return (
                <TableRow key={idx}>
                  <StyledTableCell component="th" scope="row">
                    {idx + 1}
                  </StyledTableCell>
                  <StyledTableCell component="th" scope="row">
                    <span
                      style={{ cursor: "pointer" }}
                      onClick={() => {
                        setSelectApplication(application);
                        setApplicationDetailModalShow(true);
                      }}
                    >
                      {application.projectName}
                    </span>
                  </StyledTableCell>
                  <StyledTableCell component="th" scope="row">
                    <a
                      href={application.website}
                      target="_blank"
                      rel="noopener noreferrer"
                    >
                      {application.website}
                    </a>
                  </StyledTableCell>
                  <StyledTableCell component="th" scope="row">
                    {application.contactName}({application.contactEmail})
                  </StyledTableCell>
                  <StyledTableCell
                    style={{ fontWeight: "bold" }}
                    component="th"
                    scope="row"
                  >
                    {myVoteItem ? myVoteItem.finalApproval : "Please Vote!"}
                  </StyledTableCell>
                  <StyledTableCell component="th" scope="row">
                    {application.createdAt}
                  </StyledTableCell>
                </TableRow>
              );
            })}
          </TableBody>
        </Table>
      </Paper>
      {selectApplication && applicationDetailModalShow && (
        <ApplicationDetailModal
          authToken={authToken}
          applyApplication={selectApplication}
          setApplicationDetailModalShow={setApplicationDetailModalShow}
          getApplyApplicatons={getApplyApplicatons}
        />
      )}
    </>
  );
}
