import React, { useEffect, useState } from "react";
import _ from "lodash";
import { Link } from "react-router-dom";
import { useFormik } from "formik";
import * as Yup from "yup";
import { Spinner, Button, Badge } from "react-bootstrap";
import { ModalProgressBar } from "../../../_dexlab/_partials/controls";
import { toast } from "react-toastify";
import ConfirmModal from "../common/ConfirmModal";
import TokenBurnModal from "./component/TokenBurnModal";
import TokenAddSupplyModal from "./component/TokenAddSupplyModal";
import { useSubheader } from "../../../_dexlab/layout";
import {
  updateTokenInfo,
  fetchMyToken,
  disableToken,
  changeTokenAuthority,
  fixedSupplyToken as requestFixedSupplyToken
} from "../../../client/TokenManagerClient";
import TokenWalletList from "./component/TokenWalletList";
import { fetchMySPLWalletsAndTokenMint } from "../../../client/WalletManagerClient";
import UrlMultiInput from "../../../_dexlab/layout/components/extras/UrlMultiInput";
import EmailTwoFactoreConfirmModal from "../common/EmailTwoFactoreConfirmModal";
import { tokenAccountInfo } from "../../../util/solanaApi";

//eslint-disable-next-line
const DEFAULT_SYMBOL_URL =
  "https://github.com/dexlab-project/assets/blob/master/tokens/solana/{{symbol}}/symbol.png?raw=true";
//eslint-disable-next-line
const REGEX_URL = /^((https?|ftp):\/\/)?(www.)?(((([a-z]|\d|-|\.|_|~|[\u00A0-\uD7FF\uF900-\uFDCF\uFDF0-\uFFEF])|(%[\da-f]{2})|[!\$&'\(\)\*\+,;=]|:)*@)?(((\d|[1-9]\d|1\d\d|2[0-4]\d|25[0-5])\.(\d|[1-9]\d|1\d\d|2[0-4]\d|25[0-5])\.(\d|[1-9]\d|1\d\d|2[0-4]\d|25[0-5])\.(\d|[1-9]\d|1\d\d|2[0-4]\d|25[0-5]))|((([a-z]|\d|[\u00A0-\uD7FF\uF900-\uFDCF\uFDF0-\uFFEF])|(([a-z]|\d|[\u00A0-\uD7FF\uF900-\uFDCF\uFDF0-\uFFEF])([a-z]|\d|-|\.|_|~|[\u00A0-\uD7FF\uF900-\uFDCF\uFDF0-\uFFEF])*([a-z]|\d|[\u00A0-\uD7FF\uF900-\uFDCF\uFDF0-\uFFEF])))\.)+(([a-z]|[\u00A0-\uD7FF\uF900-\uFDCF\uFDF0-\uFFEF])|(([a-z]|[\u00A0-\uD7FF\uF900-\uFDCF\uFDF0-\uFFEF])([a-z]|\d|-|\.|_|~|[\u00A0-\uD7FF\uF900-\uFDCF\uFDF0-\uFFEF])*([a-z]|[\u00A0-\uD7FF\uF900-\uFDCF\uFDF0-\uFFEF])))\.?)(:\d*)?)(\/((([a-z]|\d|-|\.|_|~|[\u00A0-\uD7FF\uF900-\uFDCF\uFDF0-\uFFEF])|(%[\da-f]{2})|[!\$&'\(\)\*\+,;=]|:|@)+(\/(([a-z]|\d|-|\.|_|~|[\u00A0-\uD7FF\uF900-\uFDCF\uFDF0-\uFFEF])|(%[\da-f]{2})|[!\$&'\(\)\*\+,;=]|:|@)*)*)?)?(\?((([a-z]|\d|-|\.|_|~|[\u00A0-\uD7FF\uF900-\uFDCF\uFDF0-\uFFEF])|(%[\da-f]{2})|[!\$&'\(\)\*\+,;=]|:|@)|[\uE000-\uF8FF]|\/|\?)*)?(\#((([a-z]|\d|-|\.|_|~|[\u00A0-\uD7FF\uF900-\uFDCF\uFDF0-\uFFEF])|(%[\da-f]{2})|[!\$&'\(\)\*\+,;=]|:|@)|\/|\?)*)?$/i;
//eslint-disable-next-line
const REGEX_TWITTER_URL = /http(?:s)?:\/\/(?:www\.)?twitter\.com\/([a-zA-Z0-9_]+)/;
const MAIN_CATEOGRY_TYPES = [
  "GENERAL",
  "MEME",
  "NFT",
  "DEX",
  "MANAGEMENT",
  "POOL",
  "ETC"
];
const SUB_CATEOGRY_TYPES = [
  "GENERAL",
  "DEFI",
  "GAME",
  "FINANCE",
  "ENERGY",
  "GAMBLING",
  "COMMERCE",
  "IOT",
  "AMM",
  "MEDIA",
  "HEALTH",
  "LENDING",
  "INDEX",
  "FARMING",
  "ETC"
];

export function EditToken({ props }) {
  const suhbeader = useSubheader();

  const {
    auth: { authToken }
  } = props.auth;

  const tokenMint = props.match.params.mint;
  const [isLoading, setIsLoading] = useState(false);
  const [isWalletLoading, setIsWalletLoading] = useState(true);
  const [tokenInfo, setTokenInfo] = useState(null);
  const [tokenWallets, setTokenWallets] = useState([]);
  const [
    tokenAccountInfoForMintAuthority,
    setTokenAccountInfoForMintAuthority
  ] = useState(null);
  const [isFixedSupplyToken, setIsFixedSupplyToken] = useState(false);

  useEffect(() => {
    reloadTokenInfo();
    getTokenWallets();
  }, []); // eslint-disable-line react-hooks/exhaustive-deps

  async function getTokenAccountInfo() {
    const accountInfo = await tokenAccountInfo(tokenMint);
    if (accountInfo === undefined) {
      console.log(`Not found token info.`);
    }

    if (accountInfo) {
      setTokenAccountInfoForMintAuthority(accountInfo.mintAuthority);
      setIsFixedSupplyToken(accountInfo.isFixedSupplyToken);
    }
  }

  async function reloadTokenInfo() {
    getTokenAccountInfo();
    getTokenInfo();
  }

  async function getTokenWallets() {
    try {
      setIsWalletLoading(true);
      const response = await fetchMySPLWalletsAndTokenMint({
        authToken,
        mint: tokenMint
      });
      if (response) {
        setTokenWallets(response);
      }
      setIsWalletLoading(false);
    } catch (e) {
      toast.error(`Failed to load token wallet information.`, {
        position: "top-right",
        autoClose: 3000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true
      });
      setIsWalletLoading(false);
    }
  }

  async function getTokenInfo() {
    try {
      const response = await fetchMyToken({ authToken, mint: tokenMint });
      if (response) {
        setTokenInfo(response);
        suhbeader.setTitle(response.nameEn);
      } else {
        window.alert(`Unable to verify token information.`);
        window.location.replace(`/management/token/list`);
      }
      setIsLoading(false);
    } catch (e) {
      if (e.response) {
        if (e.response.status === 401) {
          toast.error(`The session has expired. please log in again`, {
            position: "top-right",
            autoClose: 3000,
            hideProgressBar: false,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true
          });
          window.location.reload();
        } else {
          window.alert(e.response.data.errorMessage);
          window.location.replace(`/management/token/list`);
        }
      }
      setIsLoading(false);
    }
  }

  if (isLoading || !tokenInfo) {
    return (
      <div style={{ textAlign: "center", marginTop: "60px" }}>
        <Spinner animation="grow" />
      </div>
    );
  }

  return (
    <InnerEditPage
      authToken={authToken}
      tokenMint={tokenMint}
      tokenInfo={tokenInfo}
      tokenWallets={tokenWallets}
      isLoading={isLoading}
      isWalletLoading={isWalletLoading}
      tokenAccountInfoForMintAuthority={tokenAccountInfoForMintAuthority}
      isFixedSupplyToken={isFixedSupplyToken}
      setIsFixedSupplyToken={setIsFixedSupplyToken}
      reloadTokenInfo={reloadTokenInfo}
      getTokenWallets={getTokenWallets}
      setIsLoading={setIsLoading}
    />
  );
}

function InnerEditPage({
  authToken,
  tokenMint,
  tokenInfo,
  tokenWallets,
  getTokenWallets,
  reloadTokenInfo,
  isLoading,
  isWalletLoading,
  tokenAccountInfoForMintAuthority,
  isFixedSupplyToken,
  setIsFixedSupplyToken,
  setIsLoading
}) {
  const [
    emailTwoFactoreConfirmModalShow,
    setEmailTwoFactoreConfirmModalShow
  ] = useState(false);
  const [baseRequestBody, setBaseRequestBody] = useState(null);

  const [burnTokenModalShow, setBurnTokenModalShow] = useState(false);
  const [supplyTokenModalShow, setSupplyTokenModalShow] = useState(false);
  const [tokenEditConfirm, setTokenEditConfirm] = useState(false);
  const [tokenFixedSupplyConfirm, setTokenFixedSupplyConfirm] = useState(false);
  const [editTokenValues, setEditTokenValues] = useState(null);

  const [tokenInactiveConfirm, setTokenInactiveConfirm] = useState(false);
  const [isDisableTokenLoading, setIsDisableTokenLoading] = useState(false);

  const [changeTokenOwnerConfirm, setChangeTokenOwnerConfirm] = useState(false);
  const [isChangeOwnerTokenLoading, setIsChangeOwnerTokenLoading] = useState(
    false
  );
  const [isFixedSupplyLoading, setIsFixedSupplyLoading] = useState(false);
  const [inputNewAuthority, setInputNewAuthority] = useState(null);
  const [confirmChangeOwner, setConfirmChangeOwner] = useState(false);

  const homepageUrl =
    tokenInfo.community.find(f => f.name === "HOMEPAGE")?.url ?? "";
  const twitterUrl =
    tokenInfo.community.find(f => f.name === "TWITTER")?.url ?? "";
  const initialValues = {
    ...tokenInfo,
    homepageUrl,
    twitterUrl
  };

  const Schema = Yup.object().shape({
    nameEn: Yup.string().required("Project Name required"),
    mainCategoryType: Yup.string().required("Main Category select is required"),
    subCategoryType: Yup.string().required("Sub Category select is required"),
    symbolIconUrl: Yup.string().matches(REGEX_URL, "Enter a valid url"),
    homepageUrl: Yup.string().matches(REGEX_URL, "Enter a valid url"),
    twitterUrl: Yup.string()
      .matches(REGEX_URL, "Enter a valid url")
      .matches(REGEX_TWITTER_URL, "Enter a valid twitter url")
  });
  const getInputClasses = fieldname => {
    if (formik.touched[fieldname] && formik.errors[fieldname]) {
      return "is-invalid";
    }

    if (formik.touched[fieldname] && !formik.errors[fieldname]) {
      return "is-valid";
    }

    return "";
  };
  const formik = useFormik({
    initialValues,
    validationSchema: Schema,
    onSubmit: (values, { setStatus, setSubmitting }) => {
      const community = [];

      const inputOtherLinks = values.community.filter(
        f => f.name !== "HOMEPAGE" && f.name !== "TWITTER"
      );

      if (!_.isEmpty(inputOtherLinks)) {
        values.community.forEach(({ url, name }) => {
          if (!_.isEmpty(name) && !_.isEmpty(url)) {
            community.push({
              url: url.toLowerCase(),
              name
            });
          }
        });
      }

      if (values.homepageUrl) {
        community.push({
          url: values.homepageUrl.toLowerCase(),
          name: "HOMEPAGE"
        });
      }

      if (values.twitterUrl) {
        community.push({
          url: values.twitterUrl.toLowerCase(),
          name: "TWITTER"
        });
      }

      let symbolIcon = values.symbolIconUrl;
      if (!values.symbolIconUrl || _.isEmpty(values.symbolIconUrl)) {
        symbolIcon = DEFAULT_SYMBOL_URL;
      }
      values = {
        ...values,
        ownerPublicKey: values.mintAuthority,
        symbol: values.symbol.toUpperCase(),
        symbolIconUrl: symbolIcon.replace(
          "{{symbol}}",
          values.symbol.toLowerCase()
        ),
        community
      };

      setEditTokenValues(values);
      setTokenEditConfirm(true);
      setSubmitting(false);
    },
    onReset: (values, { resetForm }) => {
      resetForm();
    }
  });

  async function fixedSupplyToken() {
    try {
      const response = await requestFixedSupplyToken({
        authToken,
        mint: tokenMint
      });
      if (response) {
        // reloadTokenInfo();
        setIsFixedSupplyToken(true);
        toast.info(`Update Success!`, {
          position: "top-right",
          autoClose: 3000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true
        });
        handleFixedSupplyCancelConfirm();
      } else {
        toast.error(`Fail update.`, {
          position: "top-right",
          autoClose: 3000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true
        });
      }
      setIsFixedSupplyLoading(false);
    } catch (e) {
      if (e.response) {
        if (e.response.status === 401) {
          toast.error(`The session has expired. please log in again`, {
            position: "top-right",
            autoClose: 3000,
            hideProgressBar: false,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true
          });
          window.location.reload();
        } else {
          toast.error(e.response.data.errorMessage, {
            position: "top-right",
            autoClose: 3000,
            hideProgressBar: false,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true
          });
        }
      }
      setIsFixedSupplyLoading(false);
    }
  }

  async function updateToken() {
    try {
      const response = await updateTokenInfo({
        authToken,
        mint: tokenMint,
        body: editTokenValues
      });
      if (response) {
        reloadTokenInfo();
        toast.info(`The information has been corrected.`, {
          position: "top-right",
          autoClose: 3000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true
        });
      } else {
        toast.error(`Fail update.`, {
          position: "top-right",
          autoClose: 3000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true
        });
      }
    } catch (e) {
      if (e.response) {
        if (e.response.status === 401) {
          toast.error(`The session has expired. please log in again`, {
            position: "top-right",
            autoClose: 3000,
            hideProgressBar: false,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true
          });
          window.location.reload();
        } else {
          toast.error(e.response.data.errorMessage, {
            position: "top-right",
            autoClose: 3000,
            hideProgressBar: false,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true
          });
        }
      }
      setIsLoading(false);
    }
  }

  const getUserPic = url => {
    if (!url) {
      return "none";
    }
    return `url(${url})`;
  };

  const handleFixedSupply = () => {
    setIsFixedSupplyLoading(true);
    fixedSupplyToken();
  };

  const handleFixedSupplyCancelConfirm = () => {
    setTokenFixedSupplyConfirm(false);
  };

  const handleConfirmEditToken = () => {
    setTokenEditConfirm(false);
    setIsLoading(true);
    updateToken();
  };

  const handleCancelConfirm = () => {
    setTokenEditConfirm(false);
    if (isLoading) {
      setIsLoading(false);
    }
  };

  const handleCancelChangeTokenOwnerConfirm = () => {
    setChangeTokenOwnerConfirm(false);
  };

  const handleCancelTokenInactiveConfirm = () => {
    setTokenInactiveConfirm(false);
  };

  async function tokenInactive() {
    try {
      setIsDisableTokenLoading(true);
      await disableToken({ authToken, mint: tokenMint });
      window.location.replace(`/management/token/list`);
    } catch (e) {
      setIsDisableTokenLoading(false);
      toast.error(e.response.data.errorMessage, {
        position: "top-right",
        autoClose: 3000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true
      });
    }
  }

  async function tokenChangeOwner(authCode) {
    try {
      setIsChangeOwnerTokenLoading(true);
      await changeTokenAuthority(
        {
          authToken,
          mint: tokenMint,
          newAuthority: inputNewAuthority
        },
        authCode
      );
      window.location.replace(`/management/token/list`);
    } catch (e) {
      setIsChangeOwnerTokenLoading(false);
      toast.error(e.response.data.errorMessage, {
        position: "top-right",
        autoClose: 3000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true
      });
    }
  }

  // 소유자 변경
  const tokenOwnerChangeEmailAuthConfirm = authCode => {
    tokenOwnerChangeEmailAuthCancel();
    tokenChangeOwner(authCode);
  };

  const tokenOwnerChangeEmailAuthCancel = () => {
    setEmailTwoFactoreConfirmModalShow(false);
  };

  return (
    <>
      <div>
        <form
          className="card card-custom card-stretch"
          onSubmit={formik.handleSubmit}
        >
          <ModalProgressBar variant="info" />
          <div className="card-header py-3">
            <div className="card-title align-items-start flex-column">
              <h3 className="card-label font-weight-bolder text-dark">
                Edit Token{" "}
                {isFixedSupplyToken && (
                  <Badge pill variant="primary">
                    Fixed Supply Token
                  </Badge>
                )}
              </h3>
              <span className="text-muted font-weight-bold font-size-sm mt-1">
                You can edit token information.
              </span>
            </div>
            <div className="card-toolbar">
              <button
                type="submit"
                className="btn btn-info mr-2"
                disabled={
                  formik.isSubmitting || (formik.touched && !formik.isValid)
                }
              >
                Save
                {formik.isSubmitting}
              </button>
              {!isFixedSupplyToken && (
                <button
                  disabled={isWalletLoading || isFixedSupplyToken}
                  type="button"
                  className={`btn btn-${
                    isFixedSupplyToken ? "info" : "warning"
                  } mr-2`}
                  onClick={() => {
                    if (_.isEmpty(tokenWallets)) {
                      window.alert(`Create a token wallet first.`);
                    } else {
                      setSupplyTokenModalShow(true);
                    }
                  }}
                >
                  {isFixedSupplyToken ? "Fixed Supply Token" : "Token Issuance"}{" "}
                  {isWalletLoading && <Spinner animation="grow" size="sm" />}
                </button>
              )}
              <Link
                to={`/management/token/${tokenMint}/detail`}
                className="btn btn-secondary"
              >
                Cancel
              </Link>
            </div>
          </div>
          <div className="form">
            <div className="card-body">
              <div className="row">
                <label className="col-xl-3"></label>
                <div className="col-lg-9 col-xl-6">
                  <h5 className="font-weight-bold mt-10 mb-6">Project Info</h5>
                </div>
              </div>
              <div className="form-group row">
                <label className="col-xl-3 col-lg-3 col-form-label">
                  <span style={{ color: "red" }}>* </span>Symbol Image
                </label>
                <div className="col-lg-9 col-xl-6">
                  <div
                    className="image-input image-input-outline"
                    id="kt_profile_avatar"
                    style={{
                      backgroundImage: `url("https://github.com/dexlab-project/assets/blob/master/tokens/solana/dxl/symbol.png?raw=true")`
                    }}
                  >
                    <div
                      className="image-input-wrapper"
                      style={{
                        backgroundImage: `${getUserPic(
                          formik.values.symbolIconUrl
                        )}`
                      }}
                    />
                    <span
                      className="btn btn-xs btn-icon btn-circle btn-white btn-hover-text-primary btn-shadow"
                      data-action="cancel"
                      data-toggle="tooltip"
                      title=""
                      data-original-title="Cancel avatar"
                    >
                      <i className="ki ki-bold-close icon-xs text-muted"></i>
                    </span>
                  </div>
                  <input
                    type="url"
                    placeholder="https://github.com/dexlab-project/assets/blob/master/tokens/solana/{{symbol}}/symbol.png?raw=true"
                    className={`form-control form-control-lg form-control-solid ${getInputClasses(
                      "symbolIconUrl"
                    )}`}
                    name="symbolIconUrl"
                    {...formik.getFieldProps("symbolIconUrl")}
                  />
                  {formik.touched.symbolIconUrl &&
                  formik.errors.symbolIconUrl ? (
                    <div className="invalid-feedback">
                      {formik.errors.symbolIconUrl}
                    </div>
                  ) : null}
                  <span className="form-text text-muted">
                    If you have a symbol icon URL, enter it.
                    {/* <br />
                    If not, do not change the default and register the icon
                    through{" "}
                    <a
                      href="https://github.com/dexlab-project/assets"
                      target="_blank"
                      rel="noopener noreferrer"
                    >
                      "here"
                    </a> */}
                  </span>
                </div>
              </div>
              <div className="form-group row">
                <label className="col-xl-3 col-lg-3 col-form-label">
                  <span style={{ color: "red" }}>* </span>Project Name
                </label>
                <div className="col-lg-9 col-xl-6">
                  <input
                    required
                    type="text"
                    placeholder="input project name(English Only)"
                    className={`form-control form-control-lg form-control-solid ${getInputClasses(
                      "nameEn"
                    )}`}
                    name="nameEn"
                    {...formik.getFieldProps("nameEn")}
                  />
                  {formik.touched.nameEn && formik.errors.nameEn ? (
                    <div className="invalid-feedback">
                      {formik.errors.nameEn}
                    </div>
                  ) : null}
                </div>
              </div>

              <div className="form-group row">
                <label className="col-xl-3 col-lg-3 col-form-label">
                  <span style={{ color: "red" }}>* </span>Main Category
                </label>
                <div className="col-lg-9 col-xl-6">
                  <select
                    className={`form-control form-control-lg form-control-solid ${getInputClasses(
                      "mainCategoryType"
                    )}`}
                    name="mainCategoryType"
                    {...formik.getFieldProps("mainCategoryType")}
                  >
                    <option>Select Category</option>
                    {MAIN_CATEOGRY_TYPES.map(category => {
                      return (
                        <option key={category} value={category}>
                          {category}
                        </option>
                      );
                    })}
                  </select>
                  {formik.touched.mainCategoryType &&
                  formik.errors.mainCategoryType ? (
                    <div className="invalid-feedback">
                      {formik.errors.mainCategoryType}
                    </div>
                  ) : null}
                </div>
              </div>

              <div className="form-group row">
                <label className="col-xl-3 col-lg-3 col-form-label">
                  <span style={{ color: "red" }}>* </span>Sub Category
                </label>
                <div className="col-lg-9 col-xl-6">
                  <select
                    className={`form-control form-control-lg form-control-solid ${getInputClasses(
                      "subCategoryType"
                    )}`}
                    name="subCategoryType"
                    {...formik.getFieldProps("subCategoryType")}
                  >
                    <option>Select Category</option>
                    {SUB_CATEOGRY_TYPES.map(category => {
                      return (
                        <option key={category} value={category}>
                          {category}
                        </option>
                      );
                    })}
                  </select>
                  {formik.touched.subCategoryType &&
                  formik.errors.subCategoryType ? (
                    <div className="invalid-feedback">
                      {formik.errors.subCategoryType}
                    </div>
                  ) : null}
                </div>
              </div>

              <div className="form-group row">
                <label className="col-xl-3 col-lg-3 col-form-label">
                  Description
                </label>
                <div className="col-lg-9 col-xl-6">
                  <input
                    type="textarea"
                    placeholder="input description(English Only)"
                    className={`form-control form-control-lg form-control-solid ${getInputClasses(
                      "descriptionEn"
                    )}`}
                    name="descriptionEn"
                    {...formik.getFieldProps("descriptionEn")}
                  />
                  {formik.touched.descriptionEn &&
                  formik.errors.descriptionEn ? (
                    <div className="invalid-feedback">
                      {formik.errors.descriptionEn}
                    </div>
                  ) : null}
                </div>
              </div>

              <div className="form-group row">
                <label className="col-xl-3 col-lg-3 col-form-label">
                  Homepage URL
                </label>
                <div className="col-lg-9 col-xl-6">
                  <div className="input-group input-group-lg input-group-solid">
                    <div className="input-group-prepend">
                      <span className="input-group-text">
                        <i className="fa fa-link"></i>
                      </span>
                    </div>
                    <input
                      type="url"
                      placeholder="input project hompage url"
                      className={`form-control form-control-lg form-control-solid ${getInputClasses(
                        "homepageUrl"
                      )}`}
                      name="homepageUrl"
                      {...formik.getFieldProps("homepageUrl")}
                    />
                  </div>
                  {formik.touched.homepageUrl && formik.errors.homepageUrl ? (
                    <div className="invalid-feedback display-block">
                      {formik.errors.homepageUrl}
                    </div>
                  ) : null}
                </div>
              </div>
              <div className="form-group row">
                <label className="col-xl-3 col-lg-3 col-form-label">
                  Twitter URL
                </label>
                <div className="col-lg-9 col-xl-6">
                  <div className="input-group input-group-lg input-group-solid">
                    <div className="input-group-prepend">
                      <span className="input-group-text">
                        <i className="fa fa-link"></i>
                      </span>
                    </div>
                    <input
                      type="url"
                      placeholder="input project twitter url"
                      className={`form-control form-control-lg form-control-solid ${getInputClasses(
                        "twitterUrl"
                      )}`}
                      name="twitterUrl"
                      {...formik.getFieldProps("twitterUrl")}
                    />
                  </div>
                  {formik.touched.twitterUrl && formik.errors.twitterUrl ? (
                    <div className="invalid-feedback display-block">
                      {formik.errors.twitterUrl}
                    </div>
                  ) : null}
                </div>
              </div>
              <div className="row">
                <label className="col-xl-3"></label>
                <div className="col-lg-9 col-xl-6">
                  <h5 className="font-weight-bold mt-10 mb-6">
                    Other language
                  </h5>
                </div>
              </div>
              <div className="form-group row">
                <label className="col-xl-3 col-lg-3 col-form-label">
                  Project Name(Korean)
                </label>
                <div className="col-lg-9 col-xl-6">
                  <input
                    type="text"
                    placeholder="input description(Korean Only)"
                    className={`form-control form-control-lg form-control-solid ${getInputClasses(
                      "nameKr"
                    )}`}
                    name="nameKr"
                    {...formik.getFieldProps("nameKr")}
                  />
                  {formik.touched.nameKr && formik.errors.nameKr ? (
                    <div className="invalid-feedback">
                      {formik.errors.nameKr}
                    </div>
                  ) : null}
                </div>
              </div>
              <div className="form-group row">
                <label className="col-xl-3 col-lg-3 col-form-label">
                  Project Name(Chinese)
                </label>
                <div className="col-lg-9 col-xl-6">
                  <input
                    type="text"
                    placeholder="input description(Chinese Only)"
                    className={`form-control form-control-lg form-control-solid ${getInputClasses(
                      "nameCn"
                    )}`}
                    name="nameCn"
                    {...formik.getFieldProps("nameCn")}
                  />
                  {formik.touched.nameCn && formik.errors.nameCn ? (
                    <div className="invalid-feedback">
                      {formik.errors.nameCn}
                    </div>
                  ) : null}
                </div>
              </div>
              <div className="form-group row">
                <label className="col-xl-3 col-lg-3 col-form-label">
                  Description(Korean)
                </label>
                <div className="col-lg-9 col-xl-6">
                  <input
                    type="textarea"
                    placeholder="input description(Korean Only)"
                    className={`form-control form-control-lg form-control-solid ${getInputClasses(
                      "descriptionKr"
                    )}`}
                    name="descriptionKr"
                    {...formik.getFieldProps("descriptionKr")}
                  />
                  {formik.touched.descriptionKr &&
                  formik.errors.descriptionKr ? (
                    <div className="invalid-feedback">
                      {formik.errors.descriptionKr}
                    </div>
                  ) : null}
                </div>
              </div>
              <div className="form-group row">
                <label className="col-xl-3 col-lg-3 col-form-label">
                  Description(Chinese)
                </label>
                <div className="col-lg-9 col-xl-6">
                  <input
                    type="textarea"
                    placeholder="input description(Chinese Only)"
                    className={`form-control form-control-lg form-control-solid ${getInputClasses(
                      "descriptionCn"
                    )}`}
                    name="descriptionCn"
                    {...formik.getFieldProps("descriptionCn")}
                  />
                  {formik.touched.descriptionCn &&
                  formik.errors.descriptionCn ? (
                    <div className="invalid-feedback">
                      {formik.errors.descriptionCn}
                    </div>
                  ) : null}
                </div>
              </div>
              <UrlMultiInput
                formik={formik}
                initValues={formik.values.community}
              />
            </div>
          </div>
        </form>
        <div style={{ marginTop: "20px" }} className="card-body">
          <div className="row">
            <div className="col-lg-12 col-xl-12">
              <h5 className="font-weight-bold mt-10 mb-6">Token Wallets</h5>
            </div>
          </div>
          <div className="form-group row">
            <div className="col-lg-12 col-xl-12">
              {tokenWallets && !_.isEmpty(tokenWallets) ? (
                <>
                  <TokenWalletList
                    token={tokenInfo}
                    tokenWallets={tokenWallets}
                  />
                </>
              ) : isWalletLoading ? (
                <Spinner animation="grow" />
              ) : (
                "No Wallets"
              )}
            </div>
          </div>
        </div>
        <div
          style={{ textAlign: "center", fontSize: "30px", marginTop: "30px" }}
        >
          Danger Zone
        </div>
        <div style={{ textAlign: "center" }}>
          Token Issuance, Permissions Are Controlled, So Be Careful
        </div>
        <div
          style={{ height: "200px", marginTop: "15px", textAlign: "center" }}
        >
          {!isFixedSupplyToken && tokenAccountInfoForMintAuthority && (
            <Button
              style={{ marginRight: "3px" }}
              variant="danger"
              onClick={() => {
                setTokenFixedSupplyConfirm(true);
              }}
            >
              Fixed Supply
            </Button>
          )}
          <Button
            disabled={isWalletLoading}
            style={{ marginRight: "3px" }}
            variant="danger"
            onClick={() => {
              if (_.isEmpty(tokenWallets)) {
                window.alert(`Create a token wallet first.`);
              } else {
                setBurnTokenModalShow(true);
              }
            }}
          >
            Token burning{" "}
            {isWalletLoading && <Spinner animation="grow" size="sm" />}
          </Button>
          <Button
            style={{ marginRight: "3px" }}
            variant="danger"
            onClick={() => {
              setInputNewAuthority(null);
              setConfirmChangeOwner(false);
              setChangeTokenOwnerConfirm(true);
            }}
          >
            Change token owner
          </Button>
          <Button
            style={{ marginRight: "3px" }}
            variant="danger"
            onClick={() => {
              setTokenInactiveConfirm(true);
            }}
          >
            Token inactive
          </Button>
        </div>
      </div>

      {emailTwoFactoreConfirmModalShow && baseRequestBody && (
        <EmailTwoFactoreConfirmModal
          title={`Two-factor Authentication`}
          message={
            <>
              Email secondary authentication is required for security.
              <br />
              Please enter the security code <b>sent to your email</b>
            </>
          }
          authToken={authToken}
          authType={`CHANGE_TOKEN_PERMISSIONS`}
          requestBody={baseRequestBody}
          confirmAction={tokenOwnerChangeEmailAuthConfirm}
          cancelAction={tokenOwnerChangeEmailAuthCancel}
        />
      )}

      {!_.isEmpty(tokenWallets) && tokenInfo && burnTokenModalShow && (
        <TokenBurnModal
          authToken={authToken}
          tokenInfo={tokenInfo}
          tokenWallets={tokenWallets}
          setBurnTokenModalShow={setBurnTokenModalShow}
          getTokenWallets={getTokenWallets}
        />
      )}

      {!_.isEmpty(tokenWallets) && tokenInfo && supplyTokenModalShow && (
        <TokenAddSupplyModal
          authToken={authToken}
          tokenInfo={tokenInfo}
          tokenWallets={tokenWallets}
          setSupplyTokenModalShow={setSupplyTokenModalShow}
          getTokenWallets={getTokenWallets}
        />
      )}

      <ConfirmModal
        showConfirm={editTokenValues && tokenEditConfirm}
        title={"Confirm Edit Token"}
        message={"Do you want to update token information?"}
        confirmAction={handleConfirmEditToken}
        cancelAction={handleCancelConfirm}
      />

      <ConfirmModal
        showConfirm={tokenFixedSupplyConfirm}
        isLoading={isFixedSupplyLoading}
        title={"Confirm Token Fixed Supply"}
        message={
          <>
            This operation removes the MINT authority of the token so that it
            can no longer be issued by fixing the issuance amount of the token.
            <br />
            <span style={{ color: "red", fontWeight: "bold" }}>
              This means relinquishing the right to own tokens.
            </span>
            <br />
            <br />
            Shall we proceed?
          </>
        }
        confirmAction={() => {
          const confirm = window.confirm(`Last confirmation. Execution?`);
          if (confirm) {
            handleFixedSupply();
          }
        }}
        cancelAction={handleFixedSupplyCancelConfirm}
      />

      <ConfirmModal
        showConfirm={changeTokenOwnerConfirm}
        isLoading={isChangeOwnerTokenLoading}
        title={"Confirm Change token owner"}
        message={
          <>
            <div
              style={{ fontSize: "15px", color: "red", marginBottom: "10px" }}
            >
              Warning! This action is irreversible.
            </div>
            <p>
              This action will change the token owning wallet.
              <br />
              <em>
                Changing the owner does not transfer the token issuance wallet.
              </em>
            </p>
            <br />
            <div className="form-group row">
              <label
                style={{ fontWeight: "bold" }}
                className="col-xl-12 col-lg-12 col-form-label"
              >
                New Authority Wallet Address
              </label>
              <div className="col-lg-12 col-xl-12">
                <input
                  type="text"
                  placeholder="Enter New Authority Wallet Address."
                  className={`form-control form-control-lg form-control-solid`}
                  name="inputNewAuthority"
                  onChange={e => {
                    setInputNewAuthority(e.target.value);
                  }}
                />
              </div>
            </div>
            <div className="checkbox-inline">
              <label className="checkbox m-0">
                <input
                  type="checkbox"
                  onChange={e => {
                    setConfirmChangeOwner(e.target.checked);
                  }}
                />
                <span></span>I have double checked new authority wallet address.
              </label>
            </div>
          </>
        }
        confirmAction={() => {
          if (_.isEmpty(inputNewAuthority)) {
            toast.error(
              "Enter the wallet address to which the authority will be delegated",
              {
                position: "top-right",
                autoClose: 3000,
                hideProgressBar: false,
                closeOnClick: true,
                pauseOnHover: true,
                draggable: true
              }
            );
            return;
          }
          if (!confirmChangeOwner) {
            toast.error("Please agree to the changes", {
              position: "top-right",
              autoClose: 3000,
              hideProgressBar: false,
              closeOnClick: true,
              pauseOnHover: true,
              draggable: true
            });
            return;
          }
          setBaseRequestBody({
            from: tokenInfo.mintAuthority,
            to: inputNewAuthority,
            tokenMint
          });
          setEmailTwoFactoreConfirmModalShow(true);
        }}
        cancelAction={handleCancelChangeTokenOwnerConfirm}
      />

      <ConfirmModal
        showConfirm={tokenInactiveConfirm}
        isLoading={isDisableTokenLoading}
        title={"Confirm Token inactive"}
        message={
          <>
            <div
              style={{ fontSize: "15px", color: "red", marginBottom: "10px" }}
            >
              Warning! This action is irreversible.
            </div>
            <p>
              Disable this token.
              <br />
              Inactive tokens are excluded from the list.
              <br />
              <br />
              If you no longer manage or have transferred ownership, this
              feature allows you to hide your impressions.
              <br />
              (However, market listings are separate)
              <br />
              Deactivate tokens?
            </p>
          </>
        }
        confirmAction={() => {
          tokenInactive();
        }}
        cancelAction={handleCancelTokenInactiveConfirm}
      />
    </>
  );
}
