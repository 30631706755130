import axios from "axios";
import { getNetworkClusterName } from "../app/utils/util";
import projectConfig from "../application";

const axiosClient = ({ authToken, body }) => {
  // console.log(`REQUEST NETWORK: ${getNetworkClusterName()}`);
  return axios.create({
    baseURL: `${projectConfig.API_BASE_URL}`,
    data: body ?? {},
    timeout: 60000,
    headers: { Authorization: `Bearer ${authToken}` }
  });
};

// 토큰주소 유효성 검사
export async function validTokenAddress({ authToken, mint }) {
  return (
    await axiosClient({ authToken }).get(
      `/v1/management/tokens/${mint}/valid?cluster=${getNetworkClusterName()}`
    )
  ).data;
}

// 토큰에 생성된 모든 세럼마켓 조회
export async function fetchTokenMarkets({ authToken, mint }) {
  return (
    await axiosClient({ authToken }).get(
      `/v1/management/tokens/${mint}/markets?cluster=${getNetworkClusterName()}`
    )
  ).data;
}

// 토큰 트랜잭션 조회
export async function fetchTokenTransactions({ authToken, mint }) {
  return (
    await axiosClient({ authToken }).get(
      `/v1/management/tokens/${mint}/transactions?cluster=${getNetworkClusterName()}`
    )
  ).data;
}

export async function fetchMyTokens({ authToken }) {
  return (
    await axiosClient({ authToken }).get(
      `/v1/management/tokens?cluster=${getNetworkClusterName()}`
    )
  ).data;
}

export async function fetchMyToken({ authToken, mint }) {
  return (
    await axiosClient({ authToken }).get(
      `/v1/management/tokens/${mint}?cluster=${getNetworkClusterName()}`
    )
  ).data;
}

export async function fetchTokenHistory({ authToken, mint }) {
  return (
    await axiosClient({ authToken }).get(
      `/v1/management/tokens/${mint}/history?cluster=${getNetworkClusterName()}`
    )
  ).data;
}

export async function createToken({ authToken, body }) {
  return (
    await axiosClient({ authToken }).post(
      `/v1/management/tokens?cluster=${getNetworkClusterName()}`,
      body
    )
  ).data;
}

export async function updateTokenInfo({ authToken, mint, body }) {
  return (
    await axiosClient({ authToken }).put(
      `/v1/management/tokens/${mint}?cluster=${getNetworkClusterName()}`,
      body
    )
  ).data;
}

/**
 * 토큰 추가발행
 * @param {supplyTokenAccount, amount}
 * @returns
 */
export async function addSupplyToken({
  authToken,
  mint,
  supplyTokenAccount,
  amount
}) {
  return (
    await axiosClient({ authToken }).put(
      `/v1/management/tokens/${mint}/supply?cluster=${getNetworkClusterName()}`,
      {
        supplyTokenAccount,
        amount
      }
    )
  ).data;
}

/**
 * 토큰 발행량 고정
 * @returns
 */
export async function fixedSupplyToken({ authToken, mint }) {
  return (
    await axiosClient({ authToken }).put(
      `/v1/management/tokens/${mint}/fixed-supply?cluster=${getNetworkClusterName()}`,
      {}
    )
  ).data;
}

/**
 * 토큰 소각
 * @param {tokenAccountAddress, burnAmount}
 * @returns
 */
export async function burnToken({ authToken, mint, body }) {
  return (
    await axiosClient({ authToken }).post(
      `/v1/management/tokens/${mint}/burn?cluster=${getNetworkClusterName()}`,
      body
    )
  ).data;
}

/**
 * 토큰 비활성
 * @param { mint } param0  비활성 토큰주소
 * @returns
 */
export async function disableToken({ authToken, mint }) {
  return (
    await axiosClient({ authToken }).delete(
      `/v1/management/tokens/${mint}/disable?cluster=${getNetworkClusterName()}`,
      {}
    )
  ).data;
}

/**
 * 토큰 소유지갑 변경
 * @param { newAuthority } body
 * @returns
 */
export async function changeTokenAuthority(
  { authToken, mint, newAuthority },
  authCode
) {
  return (
    await axiosClient({ authToken }).post(
      `/v1/management/tokens/${mint}/change-authority?cluster=${getNetworkClusterName()}`,
      {
        newAuthority,
        authCode
      }
    )
  ).data;
}
