import React, { useEffect, useState } from "react";
import _ from "lodash";
import { useSubheader } from "../../../_dexlab/layout";
import { fetchApplyApplications } from "../../../client/GovernanceManagerClient";
import ApplyApplicationList from "./component/ApplyApplicationList";
import { Spinner } from "react-bootstrap";

export function GovernanceApplyManager({ props }) {
  const suhbeader = useSubheader();
  const {
    auth: { authToken, user }
  } = props.auth;

  const [isLoading, setIsLoading] = useState(true);
  const [applyApplications, setApplyApplications] = useState([]);

  if (user && user.isGovernanceVoter) {
    suhbeader.setTitle("Governance Manager");
  }

  useEffect(() => {
    if (user && user.isGovernanceVoter) {
      getApplyApplicatons();
    }
  }, [user]); // eslint-disable-line react-hooks/exhaustive-deps

  async function getApplyApplicatons() {
    try {
      setIsLoading(true);
      const response = await fetchApplyApplications({ authToken });
      if (response) {
        setApplyApplications(response);
      }
      setIsLoading(false);
    } catch (e) {
      setIsLoading(false);
    }
  }

  if (user && user.isGovernanceVoter && isLoading) {
    return (
      <div style={{ textAlign: "center", marginTop: "60px" }}>
        <Spinner animation="grow" />
      </div>
    );
  }

  return (
    <>
      {user && user.isGovernanceVoter && !_.isEmpty(applyApplications) ? (
        <>
          <ApplyApplicationList
            authToken={authToken}
            applyApplications={applyApplications}
            getApplyApplicatons={getApplyApplicatons}
          />
        </>
      ) : (
        "-"
      )}
    </>
  );
}
