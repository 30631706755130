import React from "react";
import { Alert, Button } from "react-bootstrap";
import SolanaTokenList from "./component/SolanaTokenList";

export function SolanaTokens({ props }) {
  return (
    <div>
      <Alert show={true} variant="primary">
        <Alert.Heading>Solana Tokens</Alert.Heading>
        <div style={{ marginBottom: "20px" }}>
          Tokens registered in Solana token-list.
          <br />
          In order for token icons and images to be exposed on Solana-based
          explorers and wallet services, they must be registered.
        </div>
        <div className="d-flex justify-content-end">
          <Button
            style={{ color: "#FFF", marginRight: "6px" }}
            onClick={() => {
              window.open(
                `https://help.dexlab.space/hc/en-us/articles/4410688321945-How-can-I-expose-token-icon-name-in-Solana-Explorer-or-Solscan-`
              );
            }}
            variant="info"
          >
            Guide
          </Button>
          <Button
            style={{
              color: "#FFF",
              borderColor: "#FFF",
              marginLeft: "6px"
            }}
            onClick={() => {
              window.open(`https://github.com/solana-labs/token-list`);
            }}
            variant="success"
          >
            Request
          </Button>
        </div>
      </Alert>
      <div>
        <SolanaTokenList />
      </div>
    </div>
  );
}
