import React from "react";
import { Modal, Button, Spinner } from "react-bootstrap";

export default function ConfirmModal({
  title,
  message,
  showConfirm,
  isLoading,
  confirmAction,
  cancelAction
}) {
  return (
    <Modal
      show={showConfirm}
      aria-labelledby="contained-modal-title-vcenter"
      centered
    >
      <Modal.Header closeButton>
        <Modal.Title id="contained-modal-title-vcenter">{title}</Modal.Title>
      </Modal.Header>
      <Modal.Body>{message}</Modal.Body>
      <Modal.Footer>
        <Button
          variant="primary"
          onClick={() => {
            confirmAction();
          }}
        >
          {isLoading ? <Spinner animation="grow" /> : "Confirm"}
        </Button>
        <Button
          variant="secondary"
          onClick={() => {
            cancelAction();
          }}
        >
          Close
        </Button>
      </Modal.Footer>
    </Modal>
  );
}
