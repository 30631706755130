import React from "react";
import { Alert } from "react-bootstrap";
// import _ from "lodash";
// import { useSubheader } from "../../../_dexlab/layout";

export function TokenVesting({ props }) {
  //   const suhbeader = useSubheader();
  //   const {
  //     auth: { authToken }
  //   } = props.auth;

  return (
    <Alert show={true} variant="primary">
      <Alert.Heading>Coming Soon</Alert.Heading>
      <div style={{ marginBottom: "20px" }}>
        This feature will become available soon.
        <br />
        Please check back for updates and news from the Dexlab team.
        <br />
        <br />
        Thank you.
        <br />
      </div>
    </Alert>
  );
}
