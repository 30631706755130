import React from "react";
import { connect } from "react-redux";
import { MyDepositWallet } from "../../_dexlab/_partials";

const MyDepositWalletPage = props => {
  return <MyDepositWallet props={props} />;
};

const mapStateToProps = auth => ({ auth: auth });
export default connect(mapStateToProps)(MyDepositWalletPage);
