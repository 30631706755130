import React from "react";
import { Button, Modal } from "react-bootstrap";

export default function TokenManagerGuideModal({
  title,
  message,
  setTokenManagerGuideModalShow
}) {
  return (
    <Modal
      size="lg"
      show={true}
      aria-labelledby="contained-modal-title-vcenter"
      onHide={() => {
        setTokenManagerGuideModalShow(false);
      }}
      centered
    >
      <Modal.Header closeButton>
        <Modal.Title id="contained-modal-title-vcenter">{title}</Modal.Title>
      </Modal.Header>
      <Modal.Body>{message}</Modal.Body>
      <Modal.Footer>
        <Button
          onClick={() => {
            setTokenManagerGuideModalShow(false);
          }}
        >
          Close
        </Button>
      </Modal.Footer>
    </Modal>
  );
}
