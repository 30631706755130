import React, { useEffect, useState } from "react";
import _ from "lodash";
import {
  Button,
  Modal,
  Jumbotron,
  Tabs,
  Tab,
  Dropdown,
  Form,
  Row,
  Col,
  Spinner
} from "react-bootstrap";
import {
  abbreviateAddress,
  convertNetworkNameToExplorerPath,
  encryptWithAES256
} from "../../../../app/utils/util";
import { toast } from "react-toastify";
import { validTokenAddress } from "../../../../client/TokenManagerClient";
import {
  createSPLWallet,
  importSPLWallet
} from "../../../../client/WalletManagerClient";

export default function NewMySPLWalletModal({
  authToken,
  user,
  mySplWallets,
  createWalletModalShow,
  handleCreateWalletModal,
  getSplWallets
}) {
  const [isLoading, setIsLoading] = useState(false);
  const [selectTab, setSelectTab] = useState("createWallet");
  const [selectOwnerWallet, setSelectOwnerWallet] = useState(undefined);
  const [selectMyToken, setSelectMyToken] = useState(undefined);
  const [inputTokenName, setInputTokenName] = useState(undefined);
  const [inputTokenMemo, setInputTokenMemo] = useState(undefined);
  const [inputWalletPrivateKey, setInputWalletPrivateKey] = useState(undefined);
  const [inputTokenMint, setInputTokenMint] = useState(undefined);
  const [inputTokenSymbol, setInputTokenSymbol] = useState(undefined);

  const currentSolWallets = _.uniqBy(mySplWallets, "ownerPublicKey");
  const myTokens = [];
  mySplWallets.forEach(wallet => {
    if (!_.isEmpty(wallet.tokens)) {
      myTokens.push(...wallet.tokens);
    }
  });
  const mySelectTokenOptions = _.uniqBy(myTokens, "tokenMint");

  function resetForm() {
    setIsLoading(false);
    setSelectTab("createWallet");
    setSelectOwnerWallet(undefined);
    setInputTokenName(undefined);
    setInputTokenMemo(undefined);
    setInputTokenMint(undefined);
    setInputTokenSymbol(undefined);
    setInputWalletPrivateKey(undefined);
  }

  useEffect(() => {
    if (createWalletModalShow) {
      resetForm();
    }
  }, [createWalletModalShow]);

  const handleRequestTokenWallet = async () => {
    if (!selectOwnerWallet) {
      toast.error(`Choose a wallet to add tokens to`, {
        position: "top-right",
        autoClose: 3000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true
      });
      return;
    }

    if (_.isEmpty(inputTokenName)) {
      toast.error(`Enter token name`, {
        position: "top-right",
        autoClose: 3000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true
      });
      return;
    }

    if (_.isEmpty(inputTokenMint)) {
      toast.error(`Enter token mint`, {
        position: "top-right",
        autoClose: 3000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true
      });
      return;
    }

    if (_.isEmpty(inputTokenSymbol)) {
      toast.error(`Enter token symbol`, {
        position: "top-right",
        autoClose: 3000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true
      });
      return;
    }

    // token mint 유효성 검증
    const validToken = await validTokenAddress({
      authToken,
      mint: inputTokenMint.trim()
    });
    if (!validToken) {
      toast.error(`The token address is incorrect.`, {
        position: "top-right",
        autoClose: 3000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true
      });
      return;
    }
    // 요청
    requestWallet({
      name: inputTokenName,
      memo: inputTokenMemo,
      symbol: inputTokenSymbol.toUpperCase(),
      ownerPublicKey: selectOwnerWallet.ownerPublicKey,
      tokenMint: inputTokenMint
    });
  };

  const handleNewWallet = () => {
    if (_.isEmpty(inputTokenName)) {
      toast.error(`Enter token name`, {
        position: "top-right",
        autoClose: 3000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true
      });
      return;
    }

    // 요청
    requestWallet({
      name: inputTokenName,
      memo: inputTokenMemo
    });
  };

  const handleImportWallet = async () => {
    if (_.isEmpty(inputTokenName)) {
      toast.error(`Enter Wallet name`, {
        position: "top-right",
        autoClose: 3000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true
      });
      return;
    }

    if (_.isEmpty(inputWalletPrivateKey)) {
      toast.error(`Enter Wallet PrivateKey`, {
        position: "top-right",
        autoClose: 3000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true
      });
      return;
    }

    setIsLoading(true);
    try {
      const response = await importSPLWallet({
        authToken,
        body: {
          name: inputTokenName,
          memo: inputTokenMemo,
          privateKey: encryptWithAES256(inputWalletPrivateKey, user.id)
        }
      });
      if (response) {
        toast.info(`Your wallet has been import.`, {
          position: "top-right",
          autoClose: 2000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true
        });
        getSplWallets();
        handleCreateWalletModal();
      } else {
        toast.error(`Failed to import wallet. please try again`, {
          position: "top-right",
          autoClose: 3000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true
        });
        setIsLoading(false);
      }
    } catch (e) {
      toast.error(
        e.response
          ? e.response.data.errorMessage
          : `Failed to import wallet. please try again`,
        {
          position: "top-right",
          autoClose: 3000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true
        }
      );
      setIsLoading(false);
    }
  };

  const requestWallet = async body => {
    setIsLoading(true);
    try {
      const response = await createSPLWallet({ authToken, body });
      if (response) {
        toast.info(`Your wallet has been created.`, {
          position: "top-right",
          autoClose: 2000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true
        });
        // 재호출
        getSplWallets();
        handleCreateWalletModal();
      } else {
        toast.error(`Failed to create wallet. please try again`, {
          position: "top-right",
          autoClose: 3000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true
        });
        setIsLoading(false);
      }
    } catch (e) {
      toast.error(
        e.response
          ? e.response.data.errorMessage
          : `Failed to create wallet. please try again`,
        {
          position: "top-right",
          autoClose: 3000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true
        }
      );
      setIsLoading(false);
    }
  };

  return (
    <Modal
      show={createWalletModalShow}
      size="lg"
      aria-labelledby="contained-modal-title-vcenter"
      onHide={handleCreateWalletModal}
      centered
    >
      <Modal.Header closeButton>
        <Modal.Title id="contained-modal-title-vcenter">
          Create Wallet or Add Token
        </Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <Tabs
          defaultActiveKey="createWallet"
          id="addToken"
          onSelect={e => {
            setSelectTab(e);
          }}
        >
          <Tab eventKey="createWallet" title="Create Wallet">
            <Jumbotron style={{ marginTop: "px" }}>
              <h1>Create Wallet</h1>
              <p>
                Create a Solana network wallet address.
                <br />
                You can add and manage multiple tokens to this wallet.
              </p>
            </Jumbotron>
            <Form>
              <Form.Group as={Row} controlId="formWalletName">
                <Form.Label column sm="2">
                  <span style={{ color: "red" }}>*</span> Name
                </Form.Label>
                <Col sm="10">
                  <Form.Control
                    required
                    type="text"
                    placeholder="Input wallet name"
                    onChange={e => {
                      setInputTokenName(e.target.value);
                    }}
                  />
                </Col>
              </Form.Group>
              <Form.Group as={Row} controlId="formWalletMemo">
                <Form.Label column sm="2">
                  Memo(Opt)
                </Form.Label>
                <Col sm="10">
                  <Form.Control
                    type="text"
                    placeholder="Input wallet memo"
                    onChange={e => {
                      setInputTokenMemo(e.target.value);
                    }}
                  />
                </Col>
              </Form.Group>
            </Form>
          </Tab>
          <Tab eventKey="addToken" title="Add Token">
            <Jumbotron style={{ marginTop: "px" }}>
              <h1>Add Token Wallet</h1>
              <p>
                Add SPL tokens to your existing Solana wallet.
                <br />
                <br />
                You can add it directly as a token contract address.
                <br />
                If the token is already managed by MintingLab, you can register
                it with just a click.
                <br />
                <b>This will cost 0.002039 SOL.</b>
              </p>
              <div>
                <Dropdown>
                  <Dropdown.Toggle
                    disabled={_.isEmpty(mySplWallets)}
                    variant="primary"
                    id="select-base-wallet"
                  >
                    {selectOwnerWallet
                      ? `${selectOwnerWallet.name}(${abbreviateAddress(
                          selectOwnerWallet.ownerPublicKey
                        )})`
                      : _.isEmpty(mySplWallets)
                      ? "Create a wallet first"
                      : "Choose your wallet"}
                  </Dropdown.Toggle>
                  <Dropdown.Menu>
                    {currentSolWallets.map((ownerWallet, idx) => {
                      return (
                        <Dropdown.Item
                          key={idx}
                          onClick={() => {
                            setSelectOwnerWallet(ownerWallet);
                          }}
                        >
                          {ownerWallet.name}({ownerWallet.ownerPublicKey})
                        </Dropdown.Item>
                      );
                    })}
                  </Dropdown.Menu>
                </Dropdown>
              </div>
            </Jumbotron>
            {selectOwnerWallet ? (
              <div>
                <Form>
                  <Form.Group as={Row} controlId="formOwnerPublicKey">
                    <Form.Label column sm="2">
                      Select Wallet
                    </Form.Label>
                    <Col sm="10">
                      <a
                        href={`https://explorer.solana.com/address/${
                          selectOwnerWallet.ownerPublicKey
                        }${convertNetworkNameToExplorerPath()}`}
                        target="_blank"
                        rel="noopener noreferrer"
                      >
                        <div>
                          <div>{selectOwnerWallet.name}</div>
                          {selectOwnerWallet.ownerPublicKey}
                        </div>
                      </a>
                    </Col>
                  </Form.Group>

                  <Form.Group as={Row} controlId="formTokenName">
                    <Form.Label column sm="2">
                      <span style={{ color: "red" }}>*</span> Name
                    </Form.Label>
                    <Col sm="10">
                      <Form.Control
                        type="text"
                        placeholder="Input wallet name"
                        onChange={e => {
                          setInputTokenName(e.target.value);
                        }}
                      />
                    </Col>
                  </Form.Group>

                  <Form.Group as={Row} controlId="formTokenMint">
                    <Form.Label column sm="2">
                      <span style={{ color: "red" }}>*</span> Token Mint
                    </Form.Label>
                    <Col sm="10">
                      <Form.Control
                        type="text"
                        placeholder="Input token mint"
                        value={inputTokenMint ?? ""}
                        onChange={e => {
                          setInputTokenMint(e.target.value);
                          if (
                            selectMyToken &&
                            e.target.value !== selectMyToken.tokenMint
                          ) {
                            setSelectMyToken(null);
                            setInputTokenSymbol(null);
                          }
                        }}
                      />
                      {!_.isEmpty(mySelectTokenOptions) && (
                        <div style={{ textAlign: "right" }}>
                          <Dropdown>
                            <Dropdown.Toggle
                              style={{ marginTop: "3px" }}
                              disabled={_.isEmpty(mySelectTokenOptions)}
                              variant="outline-secondary"
                              id="select-base-wallet"
                            >
                              {selectMyToken
                                ? `${selectMyToken.symbol}(${abbreviateAddress(
                                    selectMyToken.tokenMint
                                  )})`
                                : " Choose my token"}
                            </Dropdown.Toggle>
                            <Dropdown.Menu>
                              {mySelectTokenOptions.map((token, idx) => {
                                return (
                                  <Dropdown.Item
                                    key={idx}
                                    onClick={() => {
                                      setSelectMyToken(token);
                                      setInputTokenMint(token.tokenMint);
                                      setInputTokenSymbol(token.symbol);
                                    }}
                                  >
                                    {token.symbol}({token.tokenMint})
                                  </Dropdown.Item>
                                );
                              })}
                            </Dropdown.Menu>
                          </Dropdown>
                        </div>
                      )}
                    </Col>
                  </Form.Group>

                  <Form.Group as={Row} controlId="formSymbol">
                    <Form.Label column sm="2">
                      <span style={{ color: "red" }}>*</span> Symbol
                    </Form.Label>
                    <Col sm="10">
                      <Form.Control
                        type="text"
                        placeholder="Input token symbol"
                        value={inputTokenSymbol ?? ""}
                        onChange={e => {
                          setInputTokenSymbol(e.target.value);
                          if (
                            selectMyToken &&
                            e.target.value !== selectMyToken.symbol
                          ) {
                            setSelectMyToken(null);
                            setInputTokenMint(null);
                          }
                        }}
                      />
                    </Col>
                  </Form.Group>

                  <Form.Group as={Row} controlId="formTokenMemo">
                    <Form.Label column sm="2">
                      Memo(Opt)
                    </Form.Label>
                    <Col sm="10">
                      <Form.Control
                        type="text"
                        placeholder="Input wallet memo"
                        onChange={e => {
                          setInputTokenMemo(e.target.value);
                        }}
                      />
                    </Col>
                  </Form.Group>
                </Form>
              </div>
            ) : (
              <div style={{ textAlign: "center" }}>
                Please select a wallet to add tokens to.
              </div>
            )}
          </Tab>
          <Tab eventKey="importWallet" title="Import Wallet">
            <Jumbotron style={{ marginTop: "px" }}>
              <h1>Import Solana wallet</h1>
              <p>
                No sub-token wallets are added. Add them separately if necessary
                <br />
              </p>
            </Jumbotron>
            <Form>
              <Form.Group as={Row} controlId="formWalletName">
                <Form.Label column sm="2">
                  <span style={{ color: "red" }}>*</span> Name
                </Form.Label>
                <Col sm="10">
                  <Form.Control
                    required
                    type="text"
                    placeholder="Please enter name"
                    onChange={e => {
                      setInputTokenName(e.target.value);
                    }}
                  />
                </Col>
              </Form.Group>
              <Form.Group as={Row} controlId="formWalletMemo">
                <Form.Label column sm="2">
                  <span style={{ color: "red" }}>*</span> Private Key
                </Form.Label>
                <Col sm="10">
                  <Form.Control
                    type="text"
                    placeholder="Please enter your private key"
                    onChange={e => {
                      setInputWalletPrivateKey(e.target.value);
                    }}
                  />
                </Col>
              </Form.Group>
              <Form.Group as={Row} controlId="formWalletMemo">
                <Form.Label column sm="2">
                  Memo(Opt)
                </Form.Label>
                <Col sm="10">
                  <Form.Control
                    type="text"
                    placeholder="Enter memo"
                    onChange={e => {
                      setInputTokenMemo(e.target.value);
                    }}
                  />
                </Col>
              </Form.Group>
            </Form>
          </Tab>
        </Tabs>
      </Modal.Body>
      <Modal.Footer>
        {selectTab === "addToken" && (
          <Button
            disabled={isLoading}
            variant="primary"
            onClick={handleRequestTokenWallet}
          >
            {!isLoading ? "Add Token" : <Spinner animation="border" />}
          </Button>
        )}

        {selectTab === "createWallet" && (
          <Button variant="primary" onClick={handleNewWallet}>
            {!isLoading ? "Create Wallet" : <Spinner animation="border" />}
          </Button>
        )}

        {selectTab === "importWallet" && (
          <Button variant="primary" onClick={handleImportWallet}>
            {!isLoading ? "Import Wallet" : <Spinner animation="border" />}
          </Button>
        )}

        <Button
          disabled={isLoading}
          variant="secondary"
          onClick={handleCreateWalletModal}
        >
          Close
        </Button>
      </Modal.Footer>
    </Modal>
  );
}
