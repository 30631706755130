import React, { Suspense, lazy } from "react";
import { Redirect, Switch, Route } from "react-router-dom";
import { LayoutSplashScreen, ContentRoute } from "../_dexlab/layout";
import { EmptyPage } from "./pages/EmptyPage";
import DashboardPage from "./pages/DashboardPage";
// import AnalyticsPage from "./pages/AnalyticsPage";
import MyWalletPage from "./pages/MyWalletPage";
import MyDepositWalletPage from "./pages/MyDepositWalletPage";
import GovernanceApplyManagerPage from "./pages/GovernanceApplyManagerPage";

const UserProfilepage = lazy(() =>
  import("./modules/UserProfile/UserProfilePage")
);

const TokenManagerpage = lazy(() =>
  import("./modules/TokenManager/TokenManagerPage")
);

const MarketListingpage = lazy(() =>
  import("./modules/MarketManager/MarketListingPage")
);

const AdminManagerpage = lazy(() =>
  import("./modules/AdminManager/AdminManagerPage")
);

export default function BasePage() {
  // useEffect(() => {
  //   console.log('Base page');
  // }, []) // [] - is required if you need only one call
  // https://reactjs.org/docs/hooks-reference.html#useeffect

  return (
    <Suspense fallback={<LayoutSplashScreen />}>
      <Switch>
        {
          /* Redirect from root URL to /dashboard. */
          <Redirect exact from="/" to="/dashboard" />
        }
        <ContentRoute path="/dashboard" component={DashboardPage} />
        <ContentRoute path="/analytics" component={EmptyPage} />
        <ContentRoute
          path="/management/deposit-wallets"
          component={MyDepositWalletPage}
        />
        <ContentRoute path="/management/spl-wallets" component={MyWalletPage} />
        <ContentRoute path="/applications/ido" component={EmptyPage} />
        <ContentRoute
          path="/applications/ido/white-list"
          component={EmptyPage}
        />
        <ContentRoute path="/applications/kyc" component={EmptyPage} />
        <ContentRoute
          path="/governance/apply/list"
          component={GovernanceApplyManagerPage}
        />
        <Route path="/user-profile" component={UserProfilepage} />
        <Route path="/management/token" component={TokenManagerpage} />
        <Route path="/applications/market" component={MarketListingpage} />

        <Route path="/admin" component={AdminManagerpage} />
        <Redirect to="error/error-404" />
      </Switch>
    </Suspense>
  );
}
