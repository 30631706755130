import React, { useState } from "react";
import _ from "lodash";
import { toast } from "react-toastify";
import { Button, Modal, Spinner } from "react-bootstrap";
import copy from "clipboard-copy";
import {
  modifySPLWalletNameAndMemo,
  modifySPLWalletStatus
} from "../../../../client/WalletManagerClient";

export default function MySPLWalletDetailModal({
  authToken,
  selectSplWallet,
  getSplWallets,
  setMySPLWalletDetailModalShow
}) {
  const [isUpdateLoadiang, setIsUpdateLoadiang] = useState(false);
  const [isEdit, setIsEdit] = useState(false);
  const [walletName, setWalletName] = useState(selectSplWallet.name);
  const [walletMemo, setWalletMemo] = useState(selectSplWallet.memo);
  const [tokenSymbol, setTokenSymbol] = useState(selectSplWallet.symbol);
  const [walletStatus, setWalletStatus] = useState(selectSplWallet.enable);

  const copyCode = text => {
    copy(text).then(() => {
      toast.info(`Copy complete`, {
        position: "top-right",
        autoClose: 1500,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true
      });
    });
  };

  const handleSaveInfo = async () => {
    if (_.isEmpty(walletName)) {
      toast.error(`Enter wallet name.`, {
        position: "top-right",
        autoClose: 2000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true
      });
      return;
    }

    if (_.isEmpty(tokenSymbol)) {
      toast.error(`Enter token symbol.`, {
        position: "top-right",
        autoClose: 2000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true
      });
      return;
    }

    updateWalletInfo();
  };

  const handleUpdateWalletStatus = async () => {
    const confirm = window.confirm(
      walletStatus
        ? `Do you want to deactivate the wallet?\nIt is not exposed on the list.`
        : "Should I activate my wallet? exposed on the list."
    );
    if (!confirm) {
      return;
    }

    try {
      setIsUpdateLoadiang(true);
      const response = await modifySPLWalletStatus({
        authToken,
        walletAddress: selectSplWallet.walletAddress
      });
      if (response) {
        setWalletStatus(response.enable);
        toast.info(`Update success`, {
          position: "top-right",
          autoClose: 3000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true
        });
        getSplWallets(false);
      }
      setIsUpdateLoadiang(false);
    } catch (e) {
      toast.error(
        e.response
          ? e.response.data.errorMessage
          : `A temporary network error has occurred.`,
        {
          position: "top-right",
          autoClose: 3000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true
        }
      );
      setIsUpdateLoadiang(false);
    }
  };

  async function updateWalletInfo() {
    try {
      setIsUpdateLoadiang(true);
      const response = await modifySPLWalletNameAndMemo({
        authToken,
        walletAddress: selectSplWallet.walletAddress,
        name: walletName,
        memo: walletMemo,
        symbol: tokenSymbol.toUpperCase()
      });
      if (response) {
        setIsEdit(false);
        toast.info(`Update success`, {
          position: "top-right",
          autoClose: 3000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true
        });
        getSplWallets(false);
      }
      setIsUpdateLoadiang(false);
    } catch (e) {
      toast.error(
        e.response
          ? e.response.data.errorMessage
          : `A temporary network error has occurred.`,
        {
          position: "top-right",
          autoClose: 3000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true
        }
      );
      setIsUpdateLoadiang(false);
    }
  }

  return (
    <Modal
      show={true}
      size="lg"
      aria-labelledby="contained-modal-title-vcenter"
      onHide={() => {
        setMySPLWalletDetailModalShow(false);
      }}
      centered
    >
      <Modal.Header closeButton>
        <Modal.Title id="contained-modal-title-vcenter">
          {selectSplWallet.name}
        </Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <h4>
          {selectSplWallet.symbol}{" "}
          <span style={{ fontSize: "15px", color: "#646464" }}>
            Wallet Address
          </span>
        </h4>
        <p>
          <span
            className={`form-control form-control-solid h-auto py-5 px-6`}
            style={{ cursor: "pointer", color: "blue" }}
            onClick={() => {
              copyCode(selectSplWallet.walletAddress);
            }}
          >
            {selectSplWallet.walletAddress}
          </span>
        </p>
        <h3 style={{ marginTop: "30px" }}>Wallet Infomation</h3>
        <div className="form-group row">
          <label className="col-xl-3 col-lg-3 col-form-label">
            {isEdit && <span style={{ color: "red" }}>* </span>}Wallet Name
          </label>
          <div className="col-lg-9 col-xl-9">
            <input
              disabled={!isEdit}
              style={{ background: isEdit ? "" : "#00000000" }}
              required
              type="text"
              placeholder="Enter wallet name"
              className={`form-control form-control-lg form-control-solid`}
              value={walletName}
              name="walletName"
              onChange={e => {
                setWalletName(e.target.value);
              }}
            />
          </div>
        </div>
        <div className="form-group row">
          <label className="col-xl-3 col-lg-3 col-form-label">
            {isEdit && <span style={{ color: "red" }}>* </span>}Token Symbol
          </label>
          <div className="col-lg-9 col-xl-9">
            <input
              disabled={!isEdit}
              style={{ background: isEdit ? "" : "#00000000" }}
              type="text"
              placeholder="Enter token symbol"
              className={`form-control form-control-lg form-control-solid`}
              value={tokenSymbol}
              name="tokenSymbol"
              onChange={e => {
                setTokenSymbol(e.target.value);
              }}
            />
          </div>
        </div>
        <div className="form-group row">
          <label className="col-xl-3 col-lg-3 col-form-label">Memo</label>
          <div className="col-lg-9 col-xl-9">
            <input
              disabled={!isEdit}
              style={{ background: isEdit ? "" : "#00000000" }}
              type="text"
              placeholder="Enter wallet memo"
              className={`form-control form-control-lg form-control-solid`}
              value={walletMemo}
              name="walletMemo"
              onChange={e => {
                setWalletMemo(e.target.value);
              }}
            />
          </div>
        </div>
        <div className="form-group row">
          <label className="col-xl-3 col-lg-3 col-form-label">Token Mint</label>
          <div className="col-lg-9 col-xl-9">
            <span
              className={`form-control form-control-solid h-auto`}
              style={{ cursor: "pointer", background: "#00000000" }}
              onClick={() => {
                copyCode(selectSplWallet.walletTokenAddress);
              }}
            >
              {selectSplWallet.walletTokenAddress}
            </span>
          </div>
        </div>
        <div className="form-group row">
          <label className="col-xl-3 col-lg-3 col-form-label">Create</label>
          <div className="col-lg-9 col-xl-9">
            <span
              style={{ background: "#00000000" }}
              className={`form-control form-control-solid h-auto`}
            >
              {selectSplWallet.createdAt}
            </span>
          </div>
        </div>
      </Modal.Body>
      <Modal.Footer>
        <Button
          disabled={isUpdateLoadiang}
          onClick={() => {
            if (!isEdit) {
              setIsEdit(true);
            } else {
              handleSaveInfo();
            }
          }}
        >
          {isUpdateLoadiang ? (
            <Spinner animation="grow" />
          ) : !isEdit ? (
            "Edit"
          ) : (
            "Save"
          )}
        </Button>
        {isEdit && (
          <Button
            disabled={isUpdateLoadiang}
            variant="outline-danger"
            onClick={() => {
              setIsEdit(false);
            }}
          >
            Edit Cancel
          </Button>
        )}
        {!isEdit && (
          <Button
            disabled={isUpdateLoadiang}
            variant={walletStatus ? "danger" : "success"}
            onClick={() => {
              handleUpdateWalletStatus();
            }}
          >
            {walletStatus ? "Disable Wallet" : "Enable Wallet"}
          </Button>
        )}
        {!isEdit && (
          <Button
            disabled={isUpdateLoadiang}
            variant="outline-primary"
            onClick={() => {
              setMySPLWalletDetailModalShow(false);
            }}
          >
            Close
          </Button>
        )}
      </Modal.Footer>
    </Modal>
  );
}
