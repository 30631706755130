import React, { useEffect, useState } from "react";
import _ from "lodash";
import { Spinner, Alert, Button } from "react-bootstrap";
import Switch from "@material-ui/core/Switch";
import { toast } from "react-toastify";
import { fetchMySPLWallets } from "../../../client/WalletManagerClient";
import MySPLWalletFAQ from "./component/MySPLWalletFAQ";
import MySPLWalletList from "./component/MySPLWalletList";
import NewMySPLWalletModal from "./component/NewMySPLWalletModal";
import NetworkError from "../common/NetworkError";
import { showErrorToastMessage } from "../../../app/utils/util";

export function MySplWallet({ props }) {
  const {
    auth: { authToken, user }
  } = props.auth;

  const [isError, setIsError] = useState(false);
  const [isBalanceError, setIsBalanceError] = useState(false);
  const [isLoading, setIsLoading] = useState(true);
  const [isWalletBalanceLoading, setIsWalletBalanceLoading] = useState(false);
  const [mySplWallets, setMySplWallets] = useState([]);
  const [createWalletModalShow, setCreateWalletModalShow] = useState(false);
  const [viewDisableWallet, setViewDisableWallet] = useState(false);
  const [viewSubWallet, setViewSubWallet] = useState(false);

  useEffect(() => {
    getSplWalletsPreLoad();
  }, [viewDisableWallet]); // eslint-disable-line react-hooks/exhaustive-deps

  useEffect(() => {
    getSplWalletsPreLoad();
  }, [viewSubWallet]); // eslint-disable-line react-hooks/exhaustive-deps

  // 잔액없이 지갑리스트 조회(잔액 조회 속도때문에 사전조회)
  async function getSplWalletsPreLoad(isLoadingFlag = true) {
    try {
      if (!isLoading && isLoadingFlag) {
        setIsLoading(true);
      }
      const response = await fetchMySPLWallets({
        authToken,
        viewDisableWallet: viewDisableWallet,
        viewSubWallet: viewSubWallet,
        preLoad: true
      });
      setMySplWallets(response);
      getSplWallets();
      setIsLoading(false);
      if (isError) {
        setIsError(false);
      }
    } catch (e) {
      console.log(e);
      showErrorToastMessage(e);

      setIsError(true);
      setIsLoading(false);
    }
  }

  // 잔액없이 지갑리스트 조회(잔액 조회 속도때문에 사전조회)
  async function getSplWallets() {
    try {
      if (!isWalletBalanceLoading) {
        setIsWalletBalanceLoading(true);
      }
      const response = await fetchMySPLWallets({
        authToken,
        viewDisableWallet: viewDisableWallet,
        viewSubWallet: viewSubWallet,
        preLoad: false
      });
      setMySplWallets(response);
      setIsWalletBalanceLoading(false);
      if (isError) {
        setIsBalanceError(false);
      }
    } catch (e) {
      console.log(e);
      showErrorToastMessage(e, `Balance inquiry failed, please try again`);

      setIsBalanceError(true);
      setIsWalletBalanceLoading(false);
    }
  }

  const handleCreateWalletModal = () => {
    setCreateWalletModalShow(!createWalletModalShow);
  };

  const handleViewSubWallet = () => {
    setViewSubWallet(!viewSubWallet);
  };

  if (isLoading) {
    return (
      <div style={{ textAlign: "center", marginTop: "60px" }}>
        <div>
          <Spinner animation="grow" />
        </div>
        <div style={{ color: "#646464" }}>Inquiring balance information...</div>
      </div>
    );
  }

  if (isError) {
    return <NetworkError retry={getSplWalletsPreLoad} />;
  }

  const handleViewDisableWallet = checked => {
    setViewDisableWallet(checked);
  };

  return (
    <>
      {!isError && !isLoading && !_.isEmpty(mySplWallets) ? (
        <>
          <Alert show={true} variant="secondary">
            <h3>Minting Lab V2 released!</h3>
            <div>
              Minting Lab V1 token minting function will be deprecated according
              to the future schedule.
              <br />
              Please change the ownership of the tokens managed by Minting Lab
              V1 to your wallet and withdraw all tokens.
              <br />
              <a
                href="https://www.dexlab.space/mintinglab/spl-token"
                target="_blank"
                rel="noopener noreferrer"
              >
                Go to Minting Lab V2
              </a>
              <br />
              <a
                href="https://docs.dexlab.space/products/minting-lab/minting-v1-greater-than-minting-v2-migration"
                target="_blank"
                rel="noopener noreferrer"
              >
                View Migration Guide
              </a>
            </div>
          </Alert>
          <Alert show={true} variant="primary">
            <Alert.Heading>SPL Wallet Management</Alert.Heading>
            <p>
              Create and manage wallets for tokens that you mint.
              <br />
              Please note that once the wallet is created, it cannot be
              modified.
            </p>
            <hr />
            <div>
              <Switch
                checked={viewDisableWallet}
                onChange={e => {
                  if (!isLoading && !isWalletBalanceLoading) {
                    handleViewDisableWallet(e.target.checked);
                  } else {
                    toast.error("We are still loading wallet information...", {
                      position: "top-right",
                      autoClose: 3000,
                      hideProgressBar: false,
                      closeOnClick: true,
                      pauseOnHover: true,
                      draggable: true
                    });
                  }
                }}
              />{" "}
              <span style={{ paddingRight: "10px" }}>
                Show Inactive Wallets
              </span>
              <Switch
                checked={viewSubWallet}
                onChange={e => {
                  if (!isLoading && !isWalletBalanceLoading) {
                    handleViewSubWallet(e.target.checked);
                  } else {
                    toast.error("We are still loading wallet information...", {
                      position: "top-right",
                      autoClose: 3000,
                      hideProgressBar: false,
                      closeOnClick: true,
                      pauseOnHover: true,
                      draggable: true
                    });
                  }
                }}
              />{" "}
              <span>Show Sub Wallets</span>
            </div>
            <div className="d-flex justify-content-end">
              {/* <Button
                style={{ color: "#FFF", marginRight: "6px" }}
                onClick={handleCreateWalletModal}
                variant="success"
              >
                Create Wallet
              </Button> */}
              <Button
                style={{
                  color: "#FFF",
                  borderColor: "#FFF",
                  marginLeft: "6px"
                }}
                onClick={() => {
                  getSplWalletsPreLoad();
                }}
                variant="outline-info"
              >
                Refresh
              </Button>
            </div>
          </Alert>
          <MySPLWalletList
            authToken={authToken}
            mySplWallets={mySplWallets}
            isBalanceError={isBalanceError}
            isWalletBalanceLoading={isWalletBalanceLoading}
            getSplWallets={getSplWalletsPreLoad}
          />
        </>
      ) : (
        <>
          <MySPLWalletFAQ handleCreateWalletModal={handleCreateWalletModal} />
          <div style={{ textAlign: "center", marginTop: "60px" }}>
            No wallet has been created.
          </div>
        </>
      )}

      <NewMySPLWalletModal
        authToken={authToken}
        user={user}
        mySplWallets={mySplWallets}
        createWalletModalShow={createWalletModalShow}
        getSplWallets={getSplWalletsPreLoad}
        handleCreateWalletModal={handleCreateWalletModal}
      />
    </>
  );
}
