import React from "react";
import { connect } from "react-redux";
import { Dashboard } from "../../_dexlab/_partials";

const DashboardPage = props => {
  return <Dashboard props={props} />;
};

const mapStateToProps = auth => ({ auth: auth });
export default connect(mapStateToProps)(DashboardPage);
