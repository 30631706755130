/* eslint-disable jsx-a11y/anchor-is-valid */
import React from "react";
import { Badge, Alert } from "react-bootstrap";
import projectConfig from "../../../../application";
import { Link, Switch, Redirect } from "react-router-dom";
import { toAbsoluteUrl } from "../../../../_dexlab/_helpers";
import { ContentRoute } from "../../../../_dexlab/layout";
import Login from "./Login";
import Registration from "./Registration";
import ForgotPassword from "./ForgotPassword";
import "../../../../_dexlab/_assets/sass/pages/login/classic/login-1.scss";

export function AuthPage() {
  return (
    <>
      <div className="d-flex flex-column flex-root">
        {/*begin::Login*/}
        <div
          className="login login-1 login-signin-on d-flex flex-column flex-lg-row flex-column-fluid bg-white"
          id="kt_login"
        >
          {/*begin::Aside*/}
          <div
            className="login-aside d-flex flex-row-auto bgi-size-cover bgi-no-repeat p-10 p-lg-10"
            style={{
              backgroundImage: `url(${toAbsoluteUrl("/media/bg/bg-4.jpg")})`
            }}
          >
            {/*begin: Aside Container*/}
            <div className="d-flex flex-row-fluid flex-column justify-content-between">
              {/* start:: Aside header */}
              <Link to="/" className="flex-column-auto mt-5 pb-lg-0 pb-10">
                <img
                  alt="Logo"
                  className="max-h-70px"
                  src={toAbsoluteUrl("/media/logos/dexlab-square-white.png")}
                />
              </Link>
              {/* end:: Aside header */}

              {/* start:: Aside content */}
              <Badge
                style={{
                  height: "25px",
                  width: "90px",
                  marginTop: "20px",
                  paddingTop: "7px"
                }}
                pill
                variant="danger"
              >
                {projectConfig.APP_VERSION}
              </Badge>
              <div className="flex-column-fluid d-flex flex-column justify-content-center">
                <h3 className="font-size-h1 mb-5 text-white">
                  Welcome to MintingLab
                </h3>
                <p className="font-weight-lighter text-white opacity-80">
                  To begin the minting process, please sign in.
                  <br />
                  If you do not have an account, sign up now. In order to use
                  the MintingLab, you must have an account.
                </p>
              </div>
              {/* end:: Aside content */}

              {/* start:: Aside footer for desktop */}
              <div className="d-none flex-column-auto d-lg-flex justify-content-between mt-10">
                <div className="opacity-70 font-weight-bold	text-white">
                  &copy; 2021 Dexlab
                </div>
                <div className="d-flex">
                  <a
                    style={{ color: "#FFF" }}
                    href="https://docs.dexlab.space"
                    target="_blank"
                    rel="noopener noreferrer"
                    className="nav-link pr-3 pl-0"
                  >
                    About
                  </a>
                  <a
                    style={{ color: "#FFF" }}
                    href="https://docs.dexlab.space/tokenomics/dxl-token"
                    target="_blank"
                    rel="noopener noreferrer"
                    className="nav-link px-5"
                  >
                    DXL Token
                  </a>
                  <a
                    style={{ color: "#FFF" }}
                    href="https://discord.gg/XcQDV7z2Hz"
                    target="_blank"
                    rel="noopener noreferrer"
                    className="nav-link pl-3 pr-0"
                  >
                    Contact
                  </a>
                </div>
              </div>
              {/* end:: Aside footer for desktop */}
            </div>
            {/*end: Aside Container*/}
          </div>
          {/*begin::Aside*/}

          {/*begin::Content*/}
          <div className="d-flex flex-column flex-row-fluid position-relative p-7 overflow-hidden">
            {/*begin::Content header*/}
            {/* <div className="position-absolute top-0 right-0 text-right mt-5 mb-15 mb-lg-0 flex-column-auto justify-content-center py-5 px-10">
              <span className="font-weight-bold text-dark-50">
                Don't have an account yet?
              </span>
              <Link
                to="/auth/registration"
                className="font-weight-bold ml-2"
                id="kt_login_signup"
              >
                Sign Up!
              </Link>
            </div> */}
            {/*end::Content header*/}
            <Alert show={true} variant="secondary">
              <h3>Minting Lab V2 released!</h3>
              <div>
                Minting Lab V1 token minting function will be deprecated
                according to the future schedule.
                <br />
                Please change the ownership of the tokens managed by Minting Lab
                V1 to your wallet and withdraw all tokens.
                <br />
                <a
                  href="https://www.dexlab.space/mintinglab/spl-token"
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  Go to Minting Lab V2
                </a>
                <br />
                <a
                  href="https://docs.dexlab.space/products/minting-lab/minting-v1-greater-than-minting-v2-migration"
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  View Migration Guide
                </a>
              </div>
            </Alert>

            {/* begin::Content body */}
            <div className="d-flex flex-column-fluid flex-center mt-30 mt-lg-0">
              <Switch>
                <ContentRoute path="/auth/login" component={Login} />
                <ContentRoute
                  path="/auth/registration"
                  component={Registration}
                />
                <ContentRoute
                  path="/auth/forgot-password"
                  component={ForgotPassword}
                />
                <Redirect from="/auth" exact={true} to="/auth/login" />
                <Redirect to="/auth/login" />
              </Switch>
            </div>
            {/*end::Content body*/}

            {/* begin::Mobile footer */}
            <div className="d-flex d-lg-none flex-column-auto flex-column flex-sm-row justify-content-between align-items-center mt-5 p-5">
              <div className="text-dark-50 font-weight-bold order-2 order-sm-1 my-2">
                &copy; 2021 Dexlab
              </div>
              <div className="d-flex order-1 order-sm-2 my-2">
                <a
                  style={{ color: "#484848" }}
                  href="https://docs.dexlab.space"
                  target="_blank"
                  rel="noopener noreferrer"
                  className="nav-link pr-3 pl-0"
                >
                  About
                </a>
                <a
                  style={{ color: "#484848" }}
                  href="https://docs.dexlab.space/tokenomics/dxl-token"
                  target="_blank"
                  rel="noopener noreferrer"
                  className="nav-link px-3"
                >
                  DXL Token
                </a>
                <a
                  style={{ color: "#484848" }}
                  href="https://discord.gg/XcQDV7z2Hz"
                  target="_blank"
                  rel="noopener noreferrer"
                  className="nav-link pl-3 pr-0"
                >
                  Contact
                </a>
              </div>
            </div>
            {/* end::Mobile footer */}
          </div>
          {/*end::Content*/}
        </div>
        {/*end::Login*/}
      </div>
    </>
  );
}
