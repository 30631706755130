import axios from "axios";
import { getNetworkClusterName } from "../app/utils/util";
import projectConfig from "../application";

const axiosClient = ({ authToken, body }) => {
  return axios.create({
    baseURL: `${projectConfig.API_BASE_URL}`,
    data: body ?? {},
    timeout: 60000,
    headers: { Authorization: `Bearer ${authToken}` }
  });
};

// 이메일 인증코드 전송
export async function requestEmailTwoFactoreAuthCode({ authToken, body }) {
  return (
    await axiosClient({ authToken }).post(
      `/v1/tow-factor/transaction/request-email-auth?cluster=${getNetworkClusterName()}`,
      body
    )
  ).data;
}
