/* eslint-disable no-script-url,jsx-a11y/anchor-is-valid */
import React from "react";
import clsx from "clsx";
import { Dropdown } from "react-bootstrap";
import { OverlayTrigger, Tooltip } from "react-bootstrap";
import { useNetwork, setNetwork } from "../../../../i18n";
import { DropdownTopbarItemToggler } from "../../../../_partials/dropdowns";

const netwokrs = [
  {
    network: "MAIN_NET",
    name: "Mainnet"
  },
  {
    network: "DEV_NET",
    name: "Devnet"
  },
  {
    network: "TEST_NET",
    name: "Testnet"
  }
];

export function NetworkSelectorDropdown() {
  const network = useNetwork();
  const currentNetwork =
    netwokrs.find(x => x.network === network.selectedNetWork) ?? netwokrs[1];
  return (
    <>
      <Dropdown
        style={{ marginRight: "15px", marginLeft: "20px" }}
        drop="down"
        alignRight
      >
        <Dropdown.Toggle
          as={DropdownTopbarItemToggler}
          id="dropdown-toggle-my-cart"
        >
          <OverlayTrigger
            placement="bottom"
            overlay={
              <Tooltip id="language-panel-tooltip">Select Network</Tooltip>
            }
          >
            <div
              style={{ width: "65px" }}
              className="btn btn-icon btn-clean btn-dropdown btn-lg mr-1"
            >
              <span style={{ color: "#767676", fontWeight: "bold" }}>
                {currentNetwork.name}
              </span>
            </div>
          </OverlayTrigger>
        </Dropdown.Toggle>
        <Dropdown.Menu className="p-0 m-0 dropdown-menu-right dropdown-menu-anim dropdown-menu-top-unround">
          <ul className="navi navi-hover py-4">
            {netwokrs.map(net => (
              <li
                key={net.network}
                className={clsx("navi-item", {
                  active: net.network === currentNetwork.network
                })}
              >
                <a
                  href="#"
                  onClick={() => {
                    setNetwork(net.network);
                  }}
                  className="navi-link"
                >
                  <span className="symbol symbol-20 mr-3">
                    <span>{net.name}</span>
                  </span>
                </a>
              </li>
            ))}
          </ul>
        </Dropdown.Menu>
      </Dropdown>
    </>
  );
}
