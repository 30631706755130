import axios from "axios";
import { getNetworkClusterName } from "../app/utils/util";
import projectConfig from "../application";

const axiosClient = ({ authToken, body }) => {
  // console.log(`REQUEST NETWORK: ${getNetworkClusterName()}`);
  return axios.create({
    baseURL: `${projectConfig.API_BASE_URL}`,
    data: body ?? {},
    timeout: 120000,
    headers: { Authorization: `Bearer ${authToken}` }
  });
};

export async function healthCheck() {
  return await axios
    .create({
      baseURL: `${projectConfig.API_BASE_URL}`,
      timeout: 1000
    })
    .get(`/health`);
}

// 테스트용 SOL 에어드랍 요청
export async function fetchTestSolAirdropByDepositWallet({
  authToken,
  walletAddress
}) {
  return (
    await axiosClient({ authToken }).get(
      `/v1/management/wallets/request-sol-airdrop?walletAddress=${walletAddress}&cluster=${getNetworkClusterName()}`
    )
  ).data;
}

// 월렛주소 유효성 검사
export async function validWalletAddress({ authToken, address, checkMint }) {
  return (
    await axiosClient({ authToken }).get(
      `/v1/management/wallets/${address}/valid?cluster=${getNetworkClusterName()}${
        checkMint ? `&checkMint=${checkMint}` : ""
      }`
    )
  ).data;
}

// 내 SPL 월렛 조회
export async function fetchMySPLWallets({
  authToken,
  viewDisableWallet = false,
  viewSubWallet = false,
  preLoad = false
}) {
  return (
    await axiosClient({ authToken }).get(
      `/v1/management/wallets?preLoad=${preLoad}&viewDisableWallet=${viewDisableWallet}&viewSubWallets=${viewSubWallet}&cluster=${getNetworkClusterName()}`
    )
  ).data;
}

// 특정 토큰 SPL 월렛 조회
export async function fetchMySPLWalletsAndTokenMint({ authToken, mint }) {
  return (
    await axiosClient({ authToken }).get(
      `/v1/management/wallets/${mint}/token?cluster=${getNetworkClusterName()}`
    )
  ).data;
}

// SPL 월렛 생성
export async function createSPLWallet({ authToken, body }) {
  return (
    await axiosClient({ authToken }).post(
      `/v1/management/wallets?cluster=${getNetworkClusterName()}`,
      body
    )
  ).data;
}

// 부모 SOL지갑 하위에 존재하는 모든 토큰지갑 조회
export async function fetchMyChildTokenWallets({ authToken, ownerPublicKey }) {
  return (
    await axiosClient({ authToken }).get(
      `/v1/management/wallets/${ownerPublicKey}/child-token-wallets?cluster=${getNetworkClusterName()}`
    )
  ).data;
}

// SPL 월렛 Import
export async function importSPLWallet({ authToken, body }) {
  return (
    await axiosClient({ authToken }).post(
      `/v1/management/wallets/import?cluster=${getNetworkClusterName()}`,
      body
    )
  ).data;
}

// 지갑 하위토큰 추가
export async function importChildTokenWallet({
  authToken,
  ownerPublicKey,
  body
}) {
  return (
    await axiosClient({ authToken }).post(
      `/v1/management/wallets/${ownerPublicKey}/import?cluster=${getNetworkClusterName()}`,
      body
    )
  ).data;
}

// 내 예치금 월렛 조회
export async function fetchMyDpositWallets({ authToken }) {
  return (
    await axiosClient({ authToken }).get(
      `/v1/management/wallets/deposit?cluster=${getNetworkClusterName()}`
    )
  ).data;
}

// 예치금 월렛 생성
export async function createDepositWallet({ authToken }) {
  return (
    await axiosClient({ authToken }).post(
      `/v1/management/wallets/deposit?cluster=${getNetworkClusterName()}`,
      {}
    )
  ).data;
}

// 토큰전송
export async function transferToken({ authToken, body, emailAuthCode }) {
  return (
    await axiosClient({ authToken }).post(
      `/v1/management/wallets/transfer?cluster=${getNetworkClusterName()}`,
      {
        ...body,
        emailAuthCode
      }
    )
  ).data;
}

// 예치금 출금
export async function withdrawDeposit({ authToken, body, emailAuthCode }) {
  return (
    await axiosClient({ authToken }).post(
      `/v1/management/wallets/withdraw-deposit?cluster=${getNetworkClusterName()}`,
      {
        ...body,
        emailAuthCode
      }
    )
  ).data;
}

// SPL 월렛 이름/메모 수정
export async function modifySPLWalletNameAndMemo({
  authToken,
  walletAddress,
  name,
  memo,
  symbol
}) {
  return (
    await axiosClient({ authToken }).put(
      `/v1/management/wallets/${walletAddress}?cluster=${getNetworkClusterName()}`,
      {
        walletName: name,
        walletMemo: memo,
        tokenSymbol: symbol
      }
    )
  ).data;
}

export async function modifySPLWalletStatus({ authToken, walletAddress }) {
  return (
    await axiosClient({ authToken }).put(
      `/v1/management/wallets/${walletAddress}/status?cluster=${getNetworkClusterName()}`,
      {}
    )
  ).data;
}
