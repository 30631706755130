import React from "react";
import { Button, Modal } from "react-bootstrap";
import copy from "clipboard-copy";
import { toast } from "react-toastify";
import { convertNetworkNameToExplorerPath } from "../../../../app/utils/util";

export default function MySPLWalletReceiveTokenModal({
  receiveWalletInfo,
  receiveAddressInfoModalShow,
  setReceiveAddressInfoModalShow
}) {
  const copyCode = text => {
    copy(text).then(() => {
      toast.info(`Copy complete`, {
        position: "top-right",
        autoClose: 1500,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true
      });
    });
  };

  return (
    <Modal
      show={receiveAddressInfoModalShow}
      size="lg"
      aria-labelledby="contained-modal-title-vcenter"
      onHide={() => {
        setReceiveAddressInfoModalShow(false);
      }}
      centered
    >
      <Modal.Header closeButton>
        <Modal.Title id="contained-modal-title-vcenter">
          Receive {receiveWalletInfo.name}
        </Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <div style={{ marginBottom: "16px" }}>
          Deposit tokens to the address below.
          <br />
          <span style={{ fontSize: "11px" }}>
            <em>Only SPL tokens can be deposited.</em>
          </span>
        </div>
        <h4>{receiveWalletInfo.symbol} Receive Address</h4>
        <p>
          <span
            className={`form-control form-control-solid h-auto py-5 px-6`}
            style={{ cursor: "pointer", color: "blue" }}
            onClick={() => {
              copyCode(receiveWalletInfo.walletAddress);
            }}
          >
            {receiveWalletInfo.walletAddress}
          </span>
        </p>
        <div style={{ textAlign: "right" }}>
          <a
            href={`https://explorer.solana.com/address/${
              receiveWalletInfo.walletAddress
            }${convertNetworkNameToExplorerPath()}`}
            target="_blank"
            rel="noopener noreferrer"
          >
            View on Explorer
          </a>
        </div>
      </Modal.Body>
      <Modal.Footer>
        <Button
          onClick={() => {
            setReceiveAddressInfoModalShow(false);
          }}
        >
          Close
        </Button>
      </Modal.Footer>
    </Modal>
  );
}
