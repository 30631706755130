import React from "react";
import { makeStyles, withStyles } from "@material-ui/core/styles";
import {
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
  Paper,
  Avatar,
  Button
} from "@material-ui/core";
import {
  convertNetworkNameToExplorerPath,
  SOL_MINT,
  numberWithCommasNormal
} from "../../../../app/utils/util";

const StyledTableCell = withStyles(theme => ({
  head: {
    backgroundColor: "#8950FC",
    color: theme.palette.common.white
  },
  body: {
    fontSize: 14
  }
}))(TableCell);

const useStyles = makeStyles(theme => ({
  root: {
    width: "100%",
    marginTop: theme.spacing(3),
    overflowX: "auto"
  },
  table: {
    minWidth: 650
  },
  avatar: {
    margin: 10
  },
  bigAvatar: {
    width: 60,
    height: 60
  }
}));
function createData(name, mint, balance, symbol, walletAddress) {
  return { name, mint, balance, symbol, walletAddress };
}

export default function DepostWalletList({ depositWallets }) {
  const classes = useStyles();

  const solBalance = depositWallets[0].solBalance;
  const dxlBalance = depositWallets[0].depositWalletBalance;

  // eslint-disable-next-line no-sparse-arrays
  const rows = [
    createData(
      "Solana(SOL)",
      SOL_MINT,
      solBalance,
      "SOL",
      depositWallets[0].ownerPublicKey
    ),
    createData(
      "Dexlab(DXL)",
      depositWallets[0].depositTokenAddress ?? "Inactive",
      dxlBalance,
      "DXL",
      depositWallets[0].depositWalletAddress ?? "Inactive"
    ),
    ,
  ];

  return (
    <Paper className={classes.root}>
      <Table className={classes.table}>
        <TableHead>
          <TableRow>
            <StyledTableCell>Token</StyledTableCell>
            <StyledTableCell align="right">Mint</StyledTableCell>
            <StyledTableCell align="right">Wallet Address</StyledTableCell>
            <StyledTableCell align="right">Balance</StyledTableCell>
          </TableRow>
        </TableHead>
        <TableBody>
          {rows.map(row => (
            <TableRow key={row.name}>
              <StyledTableCell component="th" scope="row">
                <Avatar
                  style={{ display: "inline-block" }}
                  alt={row.name}
                  src={`https://github.com/dexlab-project/assets/blob/master/tokens/solana/${row.symbol.toLowerCase()}/symbol.png?raw=true`}
                  className={classes.avatar}
                />
              </StyledTableCell>
              <StyledTableCell align="right">
                {row.walletAddress === "Inactive" ? (
                  row.name
                ) : (
                  <a
                    href={`https://explorer.solana.com/address/${
                      row.mint
                    }${convertNetworkNameToExplorerPath()}`}
                    target="_blank"
                    rel="noopener noreferrer"
                  >
                    {row.name}
                  </a>
                )}
              </StyledTableCell>
              <StyledTableCell align="right">
                <div>{row.walletAddress}</div>
                <Button
                  disabled={row.walletAddress === "Inactive"}
                  variant="outlined"
                  onClick={() => {
                    window.open(
                      `https://explorer.solana.com/address/${
                        row.walletAddress
                      }${convertNetworkNameToExplorerPath()}`
                    );
                  }}
                >
                  View Explorer
                </Button>
              </StyledTableCell>
              <StyledTableCell align="right">
                <span style={{ fontSize: "20px", fontWeight: "bold" }}>
                  {numberWithCommasNormal(parseFloat(row.balance))}
                </span>{" "}
                <span style={{ fontSize: "10px" }}>{row.symbol}</span>
              </StyledTableCell>
            </TableRow>
          ))}
        </TableBody>
      </Table>
    </Paper>
  );
}
