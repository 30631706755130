/* eslint-disable no-script-url,jsx-a11y/anchor-is-valid */
import React from "react";
import _ from "lodash";
import {
  abbreviateAddress,
  convertNetworkNameToExplorerPath
} from "../../../../app/utils/util";

export function MintingLabTxWidget({ selectToken, tokenHistory, className }) {
  return (
    <>
      <div className={`card card-custom  ${className}`}>
        {/* Header */}
        <div className="card-header align-items-center border-0 mt-4">
          <h3 className="card-title align-items-start flex-column">
            <span className="font-weight-bolder text-dark">
              Transfer transaction history
            </span>
          </h3>
        </div>
        {/* Body */}
        <div className="card-body pt-4">
          {selectToken && !_.isEmpty(tokenHistory) ? (
            tokenHistory.map((history, idx) => {
              return (
                <div
                  style={{ marginBottom: "7px" }}
                  className="timeline-item align-items-start"
                  key={idx}
                >
                  <div className="timeline-label font-weight-bolder text-dark-75 font-size-lg">
                    {history.txId ? (
                      <a
                        href={`https://explorer.solana.com/tx/${
                          history.txId
                        }${convertNetworkNameToExplorerPath()}`}
                        target="_blank"
                        rel="noopener noreferrer"
                      >
                        {abbreviateAddress(history.txId, 10)}
                      </a>
                    ) : (
                      "-"
                    )}{" "}
                    <span> -{history.amount}</span>
                    <span style={{ fontSize: "12px", color: "#646464" }}>
                      {" "}
                      {selectToken.symbol}
                    </span>
                  </div>
                </div>
              );
            })
          ) : (
            <div style={{ textAlign: "center" }}>No history.</div>
          )}
        </div>
      </div>
    </>
  );
}
