import React, { useEffect, useState } from "react";
import _ from "lodash";
import { Link } from "react-router-dom";
import { Spinner, Badge } from "react-bootstrap";
import { makeStyles } from "@material-ui/core/styles";
import { deepPurple } from "@material-ui/core/colors";
import { Avatar, Grid } from "@material-ui/core";
import { ModalProgressBar } from "../../../_dexlab/_partials/controls";
import { useSubheader } from "../../../_dexlab/layout";
import {
  fetchMyToken,
  fetchTokenHistory,
  fetchTokenMarkets
} from "../../../client/TokenManagerClient";
import { fetchMySPLWalletsAndTokenMint } from "../../../client/WalletManagerClient";
import {
  convertNetworkNameToExplorerPath,
  numberWithCommasNormal,
  showErrorToastMessage
} from "../../../app/utils/util";
import TokenTransferHistoryList from "./component/TokenTransferHistoryList";
import TokenBurnHistoryList from "./component/TokenBurnHistoryList";
import TokenDexMarketList from "./component/TokenDexMarketList";
import TokenWalletList from "./component/TokenWalletList";
import { tokenAccountInfo } from "../../../util/solanaApi";

const useStyles = makeStyles(theme => ({
  purpleAvatar: {
    margin: 10,
    color: "#fff",
    backgroundColor: deepPurple[500]
  }
}));

export function TokenDetail({ props }) {
  const suhbeader = useSubheader();
  const {
    auth: { authToken }
  } = props.auth;

  const classes = useStyles();
  const tokenMint = props.match.params.mint;
  const [isLoading, setIsLoading] = useState(true);
  const [tokenInfo, setTokenInfo] = useState(null);
  const [tokenHistory, setTokenHistory] = useState(null);
  const [tokenMarkets, setTokenMarkets] = useState([]);
  const [tokenWallets, setTokenWallets] = useState([]);
  const [isWalletLoading, setIsWalletLoading] = useState(true);
  const [
    tokenAccountInfoForDecimals,
    setTokenAccountInfoForDecimals
  ] = useState(null);
  const [tokenAccountInfoForSupply, setTokenAccountInfoForSupply] = useState(
    null
  );
  const [
    tokenAccountInfoForMintAuthority,
    setTokenAccountInfoForMintAuthority
  ] = useState(null);
  const [isFixedSupplyToken, setIsFixedSupplyToken] = useState(null);

  useEffect(() => {
    getTokenAccountInfo();
    getTokenInfo();
    getTokenTransferAndBurnHistory();
    getTokenMarkets();
    getTokenWallets();
  }, []); // eslint-disable-line react-hooks/exhaustive-deps

  async function getTokenAccountInfo() {
    const accountInfo = await tokenAccountInfo(tokenMint);
    if (accountInfo === undefined) {
      console.log(`Not found token info.`);
    }

    if (accountInfo) {
      setTokenAccountInfoForDecimals(accountInfo.decimals);
      setTokenAccountInfoForSupply(accountInfo.supply);
      setTokenAccountInfoForMintAuthority(accountInfo.mintAuthority);
      setIsFixedSupplyToken(accountInfo.isFixedSupplyToken);
    }
  }

  async function getTokenWallets() {
    try {
      setIsWalletLoading(true);
      const response = await fetchMySPLWalletsAndTokenMint({
        authToken,
        mint: tokenMint
      });
      if (response) {
        setTokenWallets(response);
      }
      setIsWalletLoading(false);
    } catch (e) {
      setIsWalletLoading(false);
    }
  }

  async function getTokenMarkets() {
    try {
      const response = await fetchTokenMarkets({ authToken, mint: tokenMint });
      if (response) {
        setTokenMarkets(response);
      }
    } catch (e) {
      showErrorToastMessage(e);
    }
  }

  async function getTokenTransferAndBurnHistory() {
    try {
      const response = await fetchTokenHistory({ authToken, mint: tokenMint });
      if (response) {
        setTokenHistory(response);
      }
    } catch (e) {
      showErrorToastMessage(e);
    }
  }

  async function getTokenInfo() {
    try {
      const response = await fetchMyToken({ authToken, mint: tokenMint });
      if (response) {
        setTokenInfo(response);
        suhbeader.setTitle(response.nameEn);
      }
      setIsLoading(false);
    } catch (e) {
      showErrorToastMessage(e);
      setIsLoading(false);
    }
  }

  if (isLoading || !tokenInfo) {
    return (
      <div style={{ textAlign: "center", marginTop: "60px" }}>
        <Spinner animation="grow" />
      </div>
    );
  }

  const homepageUrl = tokenInfo.community.find(f => f.name === "HOMEPAGE")?.url;
  const twitterUrl = tokenInfo.community.find(f => f.name === "TWITTER")?.url;

  const communityOtherLinks =
    tokenInfo.community &&
    tokenInfo.community.filter(
      f => f.name !== "HOMEPAGE" && f.name !== "TWITTER"
    );
  return (
    tokenInfo && (
      <>
        <div>
          <div className="card card-custom card-stretch">
            <ModalProgressBar />
            <div className="card-header py-3">
              <div className="card-title align-items-start flex-column">
                <h3 className="card-label font-weight-bolder text-dark">
                  <Grid container justify="center" alignItems="center">
                    <Avatar
                      alt="Remy Sharp"
                      src={tokenInfo.symbolIconUrl}
                      className={classes.purpleAvatar}
                    />
                    {tokenInfo.nameEn}{" "}
                    {isFixedSupplyToken && (
                      <Badge
                        style={{ marginLeft: "6px" }}
                        pill
                        variant="primary"
                      >
                        Fixed Supply Token
                      </Badge>
                    )}
                  </Grid>
                </h3>
                <span className="text-muted font-weight-bold font-size-sm mt-1">
                  Issued token information.
                </span>
              </div>
              <div className="card-toolbar">
                <Link
                  to={`/management/token/${tokenMint}/edit`}
                  type="submit"
                  className="btn btn-success mr-2"
                >
                  Edit Token
                </Link>
                <Link to="/management/token/list" className="btn btn-secondary">
                  Token List
                </Link>
              </div>
            </div>
            <div className="form">
              <div className="card-body">
                <div className="row">
                  <label className="col-xl-3"></label>
                  <div className="col-lg-9 col-xl-6">
                    <h5 className="font-weight-bold mt-10 mb-6">
                      Project Info
                    </h5>
                  </div>
                </div>
                <div className="form-group row">
                  <label className="col-xl-3 col-lg-3 col-form-label">
                    Main Category:
                  </label>
                  <div className="col-lg-9 col-xl-6">
                    {tokenInfo.mainCategoryType}
                  </div>
                </div>

                <div className="form-group row">
                  <label className="col-xl-3 col-lg-3 col-form-label">
                    Sub Category:
                  </label>
                  <div className="col-lg-9 col-xl-6">
                    {tokenInfo.subCategoryType}
                  </div>
                </div>

                <div className="form-group row">
                  <label className="col-xl-3 col-lg-3 col-form-label">
                    Description:
                  </label>
                  <div className="col-lg-9 col-xl-6">
                    {!_.isEmpty(tokenInfo.descriptionEn) ? (
                      tokenInfo.descriptionEn
                    ) : (
                      <em>no input</em>
                    )}
                  </div>
                </div>

                <div className="form-group row">
                  <label className="col-xl-3 col-lg-3 col-form-label">
                    Homepage URL:
                  </label>
                  <div className="col-lg-9 col-xl-6">
                    {homepageUrl ? (
                      <a
                        href={homepageUrl}
                        target="_blank"
                        rel="noopener noreferrer"
                      >
                        {homepageUrl}
                      </a>
                    ) : (
                      <em>no input</em>
                    )}
                  </div>
                </div>
                <div className="form-group row">
                  <label className="col-xl-3 col-lg-3 col-form-label">
                    Twitter URL:
                  </label>
                  <div className="col-lg-9 col-xl-6">
                    {twitterUrl ? (
                      <a
                        href={twitterUrl}
                        target="_blank"
                        rel="noopener noreferrer"
                      >
                        {twitterUrl}
                      </a>
                    ) : (
                      <em>no input</em>
                    )}
                  </div>
                </div>
                <div className="row">
                  <label className="col-xl-3"></label>
                  <div className="col-lg-9 col-xl-6">
                    <h5 className="font-weight-bold mt-10 mb-6">
                      Token Issuance Information
                    </h5>
                  </div>
                </div>
                <div className="form-group row">
                  <label className="col-xl-3 col-lg-3 col-form-label">
                    Symbol:
                  </label>
                  <div className="col-lg-9 col-xl-6">{tokenInfo.symbol}</div>
                </div>
                <div className="form-group row">
                  <label className="col-xl-3 col-lg-3 col-form-label">
                    Decimals:
                  </label>
                  <div className="col-lg-9 col-xl-6">
                    {tokenAccountInfoForDecimals
                      ? tokenAccountInfoForDecimals
                      : "-"}
                  </div>
                </div>
                <div className="form-group row">
                  <label className="col-xl-3 col-lg-3 col-form-label">
                    Supply:
                  </label>
                  <div
                    style={{ fontWeight: "bold" }}
                    className="col-lg-9 col-xl-6"
                  >
                    {tokenAccountInfoForSupply
                      ? numberWithCommasNormal(tokenAccountInfoForSupply)
                      : "-"}{" "}
                    <span style={{ fontSize: "9px", color: "#646464" }}>
                      {tokenInfo.symbol}
                    </span>
                  </div>
                </div>
                <div className="form-group row">
                  <label className="col-xl-3 col-lg-3 col-form-label">
                    Mint:
                  </label>
                  <div className="col-lg-9 col-xl-6">
                    <a
                      href={`https://explorer.solana.com/address/${
                        tokenInfo.tokenMint
                      }${convertNetworkNameToExplorerPath(tokenInfo.network)}`}
                      target="_blank"
                      rel="noopener noreferrer"
                      variant="primary"
                    >
                      {tokenInfo.tokenMint}
                    </a>
                  </div>
                </div>
                <div className="form-group row">
                  <label className="col-xl-3 col-lg-3 col-form-label">
                    Authority:
                  </label>
                  <div className="col-lg-9 col-xl-6">
                    {isFixedSupplyToken ? (
                      <span style={{ fontWeight: "bold" }}>
                        {tokenAccountInfoForMintAuthority
                          ? tokenAccountInfoForMintAuthority
                          : "-"}
                      </span>
                    ) : tokenAccountInfoForMintAuthority ? (
                      <a
                        href={`https://explorer.solana.com/address/${tokenAccountInfoForMintAuthority}${convertNetworkNameToExplorerPath(
                          tokenInfo.network
                        )}`}
                        target="_blank"
                        rel="noopener noreferrer"
                        variant="primary"
                      >
                        {tokenAccountInfoForMintAuthority
                          ? tokenAccountInfoForMintAuthority
                          : "-"}
                      </a>
                    ) : (
                      "-"
                    )}
                  </div>
                </div>
                <div className="row">
                  <label className="col-xl-3"></label>
                  <div className="col-lg-9 col-xl-6">
                    <h5 className="font-weight-bold mt-10 mb-6">
                      Other language
                    </h5>
                  </div>
                </div>
                <div className="form-group row">
                  <label className="col-xl-3 col-lg-3 col-form-label">
                    Project Name(Korean):
                  </label>
                  <div className="col-lg-9 col-xl-6">
                    {!_.isEmpty(tokenInfo.nameKr) ? (
                      tokenInfo.nameKr
                    ) : (
                      <em>no input</em>
                    )}
                  </div>
                </div>
                <div className="form-group row">
                  <label className="col-xl-3 col-lg-3 col-form-label">
                    Project Name(Chinese):
                  </label>
                  <div className="col-lg-9 col-xl-6">
                    {!_.isEmpty(tokenInfo.nameCn) ? (
                      tokenInfo.nameCn
                    ) : (
                      <em>no input</em>
                    )}
                  </div>
                </div>
                <div className="form-group row">
                  <label className="col-xl-3 col-lg-3 col-form-label">
                    Description(Korean):
                  </label>
                  <div className="col-lg-9 col-xl-6">
                    {!_.isEmpty(tokenInfo.descriptionKr) ? (
                      tokenInfo.descriptionKr
                    ) : (
                      <em>no input</em>
                    )}
                  </div>
                </div>
                <div className="form-group row">
                  <label className="col-xl-3 col-lg-3 col-form-label">
                    Description(Chinese):
                  </label>
                  <div className="col-lg-9 col-xl-6">
                    {!_.isEmpty(tokenInfo.descriptionCn) ? (
                      tokenInfo.descriptionCn
                    ) : (
                      <em>no input</em>
                    )}
                  </div>
                </div>
                {!_.isEmpty(communityOtherLinks) && (
                  <div className="row">
                    <label className="col-xl-3"></label>
                    <div className="col-lg-9 col-xl-6">
                      <h5 className="font-weight-bold mt-10 mb-6">
                        Other Links
                      </h5>
                    </div>
                  </div>
                )}
                {communityOtherLinks.map(({ name, url }, idx) => {
                  return (
                    <div className="form-group row" key={idx}>
                      <label className="col-xl-3 col-lg-3 col-form-label">
                        {name}
                      </label>
                      <div className="col-lg-9 col-xl-6">
                        <a
                          href={url}
                          target="_blank"
                          rel="noopener noreferrer"
                          variant="primary"
                        >
                          {url}
                        </a>
                      </div>
                    </div>
                  );
                })}
                <div className="row">
                  <div className="col-lg-12 col-xl-12">
                    <h5 className="font-weight-bold mt-10 mb-6">
                      Token Wallets
                    </h5>
                  </div>
                </div>
                <div className="form-group row">
                  <div className="col-lg-12 col-xl-12">
                    {tokenWallets && !_.isEmpty(tokenWallets) ? (
                      <>
                        <TokenWalletList
                          token={tokenInfo}
                          tokenWallets={tokenWallets}
                        />
                      </>
                    ) : isWalletLoading ? (
                      <Spinner animation="grow" />
                    ) : (
                      "No Wallets"
                    )}
                  </div>
                </div>
                {tokenMarkets && !_.isEmpty(tokenMarkets) && (
                  <>
                    <div className="row">
                      <div className="col-lg-12 col-xl-12">
                        <h5 className="font-weight-bold mt-10 mb-6">
                          DEX Markets
                        </h5>
                      </div>
                    </div>
                    <div className="form-group row">
                      <div className="col-lg-12 col-xl-12">
                        <TokenDexMarketList
                          token={tokenInfo}
                          markets={tokenMarkets}
                        />
                      </div>
                    </div>
                  </>
                )}

                {tokenHistory && !_.isEmpty(tokenHistory.transferHistory) && (
                  <>
                    <div className="row">
                      <div className="col-lg-12 col-xl-12">
                        <h5 className="font-weight-bold mt-10 mb-6">
                          Recent Transfer History
                        </h5>
                      </div>
                    </div>
                    <div className="form-group row">
                      <div className="col-lg-12 col-xl-12">
                        <TokenTransferHistoryList
                          token={tokenInfo}
                          transferHistory={tokenHistory.transferHistory}
                        />
                      </div>
                    </div>
                  </>
                )}

                {tokenHistory && !_.isEmpty(tokenHistory.burnHistory) && (
                  <>
                    <div className="row">
                      <div className="col-lg-12 col-xl-12">
                        <h5 className="font-weight-bold mt-10 mb-6">
                          Burn History
                        </h5>
                      </div>
                    </div>
                    <div className="form-group row">
                      <div className="col-lg-12 col-xl-12">
                        <TokenBurnHistoryList
                          token={tokenInfo}
                          burnHistory={tokenHistory.burnHistory}
                        />
                      </div>
                    </div>
                  </>
                )}
              </div>
            </div>
          </div>
        </div>
      </>
    )
  );
}
