import React from "react";
import { Alert } from "react-bootstrap";
// import { Link } from "react-router-dom";

export default function MyTokenFAQ({
  isDepostWallet,
  isDepostWalletLoading,
  setTokenManagerGuideModalShow
}) {
  return (
    <Alert show={true} variant="info">
      <Alert.Heading>Token Management</Alert.Heading>
      <p>
        Instantly issue and manage SPL tokens.
        <br />
        Issue SPL tokens in a few clicks.
        <br />
      </p>
      <hr />
      <div className="d-flex justify-content-end">
        {/* <Link
          to={
            !isDepostWalletLoading && isDepostWallet
              ? "/management/token/new"
              : "/management/token/list"
          }
          style={{ color: "#FFF", borderColor: "#FFF" }}
          variant="outline-success"
          onClick={() => {
            if (!isDepostWalletLoading && !isDepostWallet) {
              setTokenManagerGuideModalShow(true);
            }
          }}
        >
          <b>Create First Token</b>
        </Link> */}
        <a
          style={{ color: "#FFF", borderColor: "#FFF" }}
          href="https://www.dexlab.space/mintinglab/spl-token"
          target="_blank"
          rel="noopener noreferrer"
        >
          Create First Token
        </a>
      </div>
    </Alert>
  );
}
