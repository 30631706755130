import axios from "axios";
// import { getNetworkClusterName } from "../app/utils/util";
import projectConfig from "../application";

const axiosClient = ({ authToken, body }) => {
  // console.log(`REQUEST NETWORK: ${getNetworkClusterName()}`);
  return axios.create({
    baseURL: `${projectConfig.API_BASE_URL}`,
    data: body ?? {},
    timeout: 60000,
    headers: { Authorization: `Bearer ${authToken}` }
  });
};

// 모든 신청된 어플리케이션 조회
export async function fetchApplyApplications({ authToken }) {
  return (await axiosClient({ authToken }).get(`/v1/admin/governance`)).data;
}

// 모든 신청된 어플리케이션 조회
export async function fetchApplyApplication({ authToken, id }) {
  return (await axiosClient({ authToken }).get(`/v1/admin/governance/${id}`))
    .data;
}

// 투표
export async function votingApplicatoon({ authToken, id, body }) {
  return (
    await axiosClient({ authToken }).post(
      `/v1/admin/governance/${id}/voting`,
      body
    )
  ).data;
}
