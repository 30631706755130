import React from "react";
import { makeStyles, withStyles } from "@material-ui/core/styles";
import {
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
  Paper
} from "@material-ui/core";
import { deepOrange, deepPurple } from "@material-ui/core/colors";
import {
  numberWithCommasNormal,
  convertNetworkNameToExplorerPath
} from "../../../../app/utils/util";

const StyledTableCell = withStyles(theme => ({
  head: {
    backgroundColor: "#8950FC",
    color: theme.palette.common.white
  },
  body: {
    fontSize: 14
  }
}))(TableCell);

const useStyles = makeStyles(theme => ({
  root: {
    width: "100%",
    marginTop: theme.spacing(3),
    overflowX: "auto"
  },
  table: {
    minWidth: 650
  },
  avatar: {
    margin: 10
  },
  orangeAvatar: {
    margin: 10,
    color: "#fff",
    backgroundColor: deepOrange[500]
  },
  purpleAvatar: {
    margin: 10,
    color: "#fff",
    backgroundColor: deepPurple[500]
  },
  button: {
    margin: theme.spacing(1)
  },
  input: {
    display: "none"
  }
}));

export default function TokenWalletList({ token, tokenWallets }) {
  const classes = useStyles();

  return (
    <Paper className={classes.root}>
      <Table className={classes.table}>
        <TableHead>
          <TableRow>
            <StyledTableCell style={{ wwordBreak: "break-all" }}>
              Name
            </StyledTableCell>
            <StyledTableCell style={{ wwordBreak: "break-all" }}>
              Wallet Address
            </StyledTableCell>
            <StyledTableCell style={{ wwordBreak: "break-all" }}>
              Balance
            </StyledTableCell>
            <StyledTableCell style={{ wwordBreak: "break-all" }}>
              Memo
            </StyledTableCell>
            <StyledTableCell style={{ wwordBreak: "break-all" }}>
              CreatedAt
            </StyledTableCell>
          </TableRow>
        </TableHead>
        <TableBody>
          {tokenWallets.map((wallet, idx) => (
            <TableRow key={idx}>
              <StyledTableCell component="th" scope="row">
                {wallet.name}
              </StyledTableCell>
              <StyledTableCell component="th" scope="row">
                <a
                  href={`https://explorer.solana.com/address/${
                    wallet.walletAddress
                  }${convertNetworkNameToExplorerPath()}`}
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  {wallet.walletAddress}
                </a>
              </StyledTableCell>
              <StyledTableCell component="th" scope="row">
                {numberWithCommasNormal(wallet.walletBalance)}{" "}
                <span style={{ fontSize: "10px", color: "#646464" }}>
                  {token.symbol}
                </span>
              </StyledTableCell>
              <StyledTableCell component="th" scope="row">
                {wallet.memo}
              </StyledTableCell>
              <StyledTableCell component="th" scope="row">
                {wallet.createdAt}
              </StyledTableCell>
            </TableRow>
          ))}
        </TableBody>
      </Table>
    </Paper>
  );
}
