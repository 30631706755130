import React from "react";
import { makeStyles, withStyles } from "@material-ui/core/styles";
import {
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
  Paper
} from "@material-ui/core";
import { deepOrange, deepPurple } from "@material-ui/core/colors";
import {
  numberWithCommasNormal,
  convertNetworkNameToExplorerPath
} from "../../../../app/utils/util";

const StyledTableCell = withStyles(theme => ({
  head: {
    backgroundColor: "#8950FC",
    color: theme.palette.common.white
  },
  body: {
    fontSize: 14
  }
}))(TableCell);

const useStyles = makeStyles(theme => ({
  root: {
    width: "100%",
    marginTop: theme.spacing(3),
    overflowX: "auto"
  },
  table: {
    minWidth: 650
  },
  avatar: {
    margin: 10
  },
  orangeAvatar: {
    margin: 10,
    color: "#fff",
    backgroundColor: deepOrange[500]
  },
  purpleAvatar: {
    margin: 10,
    color: "#fff",
    backgroundColor: deepPurple[500]
  },
  button: {
    margin: theme.spacing(1)
  },
  input: {
    display: "none"
  }
}));

export default function TokenDexMarketList({ token, markets }) {
  const classes = useStyles();

  return (
    <Paper className={classes.root}>
      <Table className={classes.table}>
        <TableHead>
          <TableRow>
            <StyledTableCell style={{ wwordBreak: "break-all" }}>
              Market Address
            </StyledTableCell>
            <StyledTableCell style={{ wwordBreak: "break-all" }}>
              Pair
            </StyledTableCell>
            <StyledTableCell style={{ wwordBreak: "break-all" }}>
              24h Volume
            </StyledTableCell>
            {/* <StyledTableCell style={{ wwordBreak: "break-all" }}>
              Dexlab Add
            </StyledTableCell> */}
          </TableRow>
        </TableHead>
        <TableBody>
          {markets.map((market, idx) => (
            <TableRow key={idx}>
              <StyledTableCell component="th" scope="row">
                <a
                  href={`https://explorer.solana.com/address/${
                    market.tokenMint
                  }${convertNetworkNameToExplorerPath()}`}
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  {market.tokenMint}
                </a>
              </StyledTableCell>
              <StyledTableCell component="th" scope="row">
                {token.base}-{market.quoteTicker}
              </StyledTableCell>
              <StyledTableCell component="th" scope="row">
                {numberWithCommasNormal(market.volume24)}
              </StyledTableCell>
              {/* <StyledTableCell component="th" scope="row">
                <Button
                  disabled={market.isAdded}
                  onClick={() => {
                    window.alert(`Wait`);
                  }}
                >
                  {market.isAdded ? "Added" : "Add"}
                </Button>
              </StyledTableCell> */}
            </TableRow>
          ))}
        </TableBody>
      </Table>
    </Paper>
  );
}
