/* eslint-disable no-script-url,jsx-a11y/anchor-is-valid */
import React from "react";
import { useLocation } from "react-router";
import { NavLink } from "react-router-dom";
import { checkIsActive } from "../../../../_helpers";

export function HeaderMenu({ layoutProps }) {
  const location = useLocation();
  const getMenuItemActive = url => {
    return checkIsActive(location, url) ? "menu-item-active" : "";
  };

  return (
    <div
      id="kt_header_menu"
      className={`header-menu header-menu-mobile ${layoutProps.ktMenuClasses}`}
      {...layoutProps.headerMenuAttributes}
    >
      {/*begin::Header Nav*/}
      <ul className={`menu-nav ${layoutProps.ulClasses}`}>
        <li
          className={`menu-item menu-item-rel ${getMenuItemActive(
            "/dashboard"
          )}`}
        >
          <NavLink className="menu-link" to="/dashboard">
            <span className="menu-text">Dashboard</span>
            {layoutProps.rootArrowEnabled && <i className="menu-arrow" />}
          </NavLink>
        </li>
        <li
          className={`menu-item menu-item-rel ${getMenuItemActive(
            "/management/token"
          )}`}
        >
          <NavLink className="menu-link" to="/management/token">
            <span className="menu-text">Token</span>
            {layoutProps.rootArrowEnabled && <i className="menu-arrow" />}
          </NavLink>
        </li>
        <li
          className={`menu-item menu-item-rel ${getMenuItemActive(
            "/management/spl-wallets"
          )}`}
        >
          <NavLink className="menu-link" to="/management/spl-wallets">
            <span className="menu-text">Wallets</span>
            {layoutProps.rootArrowEnabled && <i className="menu-arrow" />}
          </NavLink>
        </li>
        <li
          className={`menu-item menu-item-rel ${getMenuItemActive(
            "/management/token/solana"
          )}`}
        >
          <NavLink className="menu-link" to="/management/token/solana">
            <span className="menu-text">Solana Tokens</span>
            {layoutProps.rootArrowEnabled && <i className="menu-arrow" />}
          </NavLink>
        </li>
        <li className={`menu-item menu-item-rel`}>
          <a
            className="menu-link"
            href="https://help.dexlab.space"
            target="_blank"
            rel="noopener noreferrer"
          >
            <span className="menu-text">Help</span>
            {layoutProps.rootArrowEnabled && <i className="menu-arrow" />}
          </a>
        </li>
      </ul>
      {/*end::Header Nav*/}
    </div>
  );
}
