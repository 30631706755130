import React from "react";
import { MintingLabV1Dashboard } from "./MintingLabV1Dashboard";

export function Dashboard({ props }) {
  const {
    auth: { authToken }
  } = props.auth;

  return (
    <>
      <MintingLabV1Dashboard authToken={authToken} />
    </>
  );
}
