import React, { useEffect, useState } from "react";
import { useFormik } from "formik";
import { Spinner, Button, Modal, Form } from "react-bootstrap";
import { toast } from "react-toastify";
import {
  withdrawDeposit,
  validWalletAddress
} from "../../../../client/WalletManagerClient";
import {
  SOL_MINT,
  convertNetworkNameToExplorerPath,
  getNetworkClusterName
} from "../../../../app/utils/util";
import EmailTwoFactoreConfirmModal from "../../common/EmailTwoFactoreConfirmModal";
import ConfirmModal from "../../common/ConfirmModal";

export default function DepositWithdrawModal({
  authToken,
  depositWallets,
  withdrawAddressInfoModalShow,
  getDepositWallets,
  setWithdrawAddressInfoModalShow
}) {
  const [isWithdrawLoading, setIsWithdrawLoading] = useState(false);
  const [
    emailTwoFactoreConfirmModalShow,
    setEmailTwoFactoreConfirmModalShow
  ] = useState(false);
  const [baseRequestBody, setBaseRequestBody] = useState(null);
  const [
    addressVerificationConfirmModalShow,
    setAddressVerificationConfirmModalShow
  ] = useState(false);

  const solAddress = depositWallets[0].ownerPublicKey;
  const dxlAddress = depositWallets[0].depositWalletAddress;
  const dxlTokenAddress = depositWallets[0].depositTokenAddress;
  const solAmount = depositWallets[0].solBalance;
  const dxlAmont = depositWallets[0].depositWalletBalance;

  async function withdrawToken({
    ownerPublicKey,
    from,
    to,
    tokenAddress,
    amount,
    authCode
  }) {
    try {
      const response = await withdrawDeposit({
        authToken,
        emailAuthCode: authCode,
        body: {
          ownerPublicKey,
          from,
          to,
          tokenAddress,
          amount: parseFloat(amount)
        }
      });
      if (response) {
        toast.info(
          <>
            {" "}
            Withdrawal success.
            <br />
            <a
              style={{ color: "#FFFFFF", fontWeight: "bold" }}
              href={`https://explorer.solana.com/tx/${
                response.txId
              }${convertNetworkNameToExplorerPath(getNetworkClusterName())}`}
              target="_blank"
              rel="noopener noreferrer"
            >
              View Transaction
            </a>
          </>,
          {
            position: "top-right",
            autoClose: 3500,
            hideProgressBar: false,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true
          }
        );
        setWithdrawAddressInfoModalShow(false);
        getDepositWallets();
      }
      setIsWithdrawLoading(false);
    } catch (e) {
      toast.error(
        e.response
          ? e.response.data.errorMessage
          : `Withdrawal failed  If the problem persists, please contact Dexlab.`,
        {
          position: "top-right",
          autoClose: 3000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true
        }
      );
      setIsWithdrawLoading(false);
    }
  }

  useEffect(() => {
    if (baseRequestBody) {
      handleTokenSendSubmit(true);
    }
  }, [baseRequestBody]); // eslint-disable-line react-hooks/exhaustive-deps

  const handleTokenSendSubmit = async (valid = true) => {
    if (valid) {
      // 입금지갑 검증
      const validResult = await validWalletAddress({
        authToken,
        address: baseRequestBody.to,
        checkMint: baseRequestBody.tokenAddress
      });
      if (!validResult) {
        setAddressVerificationConfirmModalShow(true);
        return;
      }
    }
    setEmailTwoFactoreConfirmModalShow(true);
  };

  const formik = useFormik({
    initialValues: {
      withdrawAddress: undefined,
      withdrawAmount: 0,
      addressChecbox: false,
      selectTokenWallet: solAddress,
      selectTokenMint: SOL_MINT,
      selectTokenName: "SOL"
    },
    onSubmit: (values, { setSubmitting }) => {
      if (!values.addressChecbox) {
        toast.error(`Did you check your wallet address?`, {
          position: "top-right",
          autoClose: 2000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true
        });
        return;
      }
      if (!values.withdrawAddress) {
        toast.error(`Enter the wallet address to deposit`, {
          position: "top-right",
          autoClose: 2000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true
        });
        return;
      }
      if (parseFloat(values.withdrawAmount) === 0) {
        toast.error(`Enter the withdrawal amount`, {
          position: "top-right",
          autoClose: 2000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true
        });
        return;
      }

      setBaseRequestBody({
        ownerPublicKey: solAddress,
        from: values.selectTokenWallet,
        to: values.withdrawAddress,
        tokenAddress: values.selectTokenMint,
        amount: values.withdrawAmount
      });
      setSubmitting(false);
    }
  });

  // 토큰전송
  const emailAuthConfirm = authCode => {
    setIsWithdrawLoading(true);
    emailAuthCancel();

    withdrawToken({
      ...baseRequestBody,
      authCode
    });
  };

  const emailAuthCancel = () => {
    setEmailTwoFactoreConfirmModalShow(false);
  };

  return (
    <>
      <Modal
        show={withdrawAddressInfoModalShow}
        size="lg"
        aria-labelledby="contained-modal-title-vcenter"
        onHide={() => {
          setWithdrawAddressInfoModalShow(false);
        }}
        centered
      >
        <Modal.Header closeButton>
          <Modal.Title id="contained-modal-title-vcenter">Withdraw</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <Form onSubmit={formik.handleSubmit}>
            <Form.Group controlId="formSelectWithdrawToken">
              <Form.Label>
                <b>Select token wallet</b>
              </Form.Label>
              <div>
                <Form.Check
                  style={{ marginBottom: "10px" }}
                  type="radio"
                  label={`SOL(${solAddress}): ${solAmount} SOL`}
                  name="selectWithdrawToken"
                  id="selectWithdrawTokenGroup"
                  defaultChecked
                  onChange={() => {
                    formik.setFieldValue("selectTokenName", "SOL");
                    formik.setFieldValue("selectTokenWallet", solAddress);
                    formik.setFieldValue("selectTokenMint", SOL_MINT);
                  }}
                />
                {dxlAddress && (
                  <Form.Check
                    type="radio"
                    label={`DXL(${dxlAddress}): ${dxlAmont} DXL`}
                    name="selectWithdrawToken"
                    id="selectWithdrawTokenGroup"
                    onChange={() => {
                      formik.setFieldValue("selectTokenName", "DXL");
                      formik.setFieldValue("selectTokenWallet", dxlAddress);
                      formik.setFieldValue("selectTokenMint", dxlTokenAddress);
                    }}
                  />
                )}
              </div>
            </Form.Group>
            <Form.Group controlId="formWithdrawAddress">
              <Form.Label>
                <span style={{ color: "red" }}>* </span>
                <b>{formik.values.selectTokenName}</b> wallet address to receive
                deposit
              </Form.Label>
              <Form.Control
                name="formWithdrawAddress"
                type="text"
                placeholder="input withdraw address"
                onChange={e => {
                  formik.setFieldValue("withdrawAddress", e.target.value);
                }}
              />
            </Form.Group>
            <Form.Group controlId="formWithdrawAmount">
              <Form.Label>
                <span style={{ color: "red" }}>* </span>Amount(
                {formik.values.selectTokenName})
              </Form.Label>
              <Form.Control
                name="formWithdrawAmount"
                type="number"
                step={0.000000001}
                placeholder="input token amount"
                onChange={e => {
                  // 수량검증
                  if (formik.values.selectTokenWallet === solAddress) {
                    if (
                      parseFloat(depositWallets[0].solBalance) <
                      parseFloat(e.target.value)
                    ) {
                      toast.error(
                        `Insufficient withdrawal amount (maximum: ${depositWallets[0].solBalance})`,
                        {
                          position: "top-right",
                          autoClose: 2000,
                          hideProgressBar: false,
                          closeOnClick: true,
                          pauseOnHover: true,
                          draggable: true
                        }
                      );
                      return;
                    }
                  } else if (formik.values.selectTokenWallet === dxlAddress) {
                    if (
                      depositWallets[0].depositWalletBalance &&
                      parseFloat(depositWallets[0].depositWalletBalance) <
                        parseFloat(e.target.value)
                    ) {
                      toast.error(
                        `Insufficient withdrawal amount (maximum: ${depositWallets[0].depositWalletBalance})`,
                        {
                          position: "top-right",
                          autoClose: 2000,
                          hideProgressBar: false,
                          closeOnClick: true,
                          pauseOnHover: true,
                          draggable: true
                        }
                      );
                      return;
                    }
                  }

                  formik.setFieldValue("withdrawAmount", e.target.value);
                }}
              />
            </Form.Group>
            <Form.Group controlId="formBasicChecbox">
              <Form.Check
                name="formBasicChecbox"
                type="checkbox"
                label="I have double checked my wallet address."
                onChange={e => {
                  formik.setFieldValue("addressChecbox", e.target.checked);
                }}
              />
            </Form.Group>
            <Button
              disabled={isWithdrawLoading}
              style={{ marginRight: "6px" }}
              variant="primary"
              type="submit"
            >
              {!isWithdrawLoading ? (
                "Request Withdraw"
              ) : (
                <Spinner animation="border" />
              )}
            </Button>
            <Button
              disabled={isWithdrawLoading}
              variant="secondary"
              onClick={() => {
                setWithdrawAddressInfoModalShow(false);
              }}
            >
              Close
            </Button>
          </Form>
          {isWithdrawLoading && (
            <div style={{ marginTop: "10px" }}>
              <span style={{ color: "#646464" }}>
                Transaction is being confirmed. Please wait...
              </span>
            </div>
          )}
        </Modal.Body>
      </Modal>

      {emailTwoFactoreConfirmModalShow && baseRequestBody && (
        <EmailTwoFactoreConfirmModal
          title={`Two-factor Authentication`}
          message={
            <>
              Email secondary authentication is required for security.
              <br />
              Please enter the security code <b>sent to your email</b>
            </>
          }
          authToken={authToken}
          authType={`DEPOSIT_TRANSFER_TRANSACTION`}
          requestBody={baseRequestBody}
          confirmAction={emailAuthConfirm}
          cancelAction={emailAuthCancel}
        />
      )}
      {addressVerificationConfirmModalShow && baseRequestBody && (
        <ConfirmModal
          showConfirm={addressVerificationConfirmModalShow}
          title={"Confirm Address"}
          message={"This address has no funds. Are you sure it's correct?"}
          confirmAction={() => {
            handleTokenSendSubmit(false);
            setAddressVerificationConfirmModalShow(false);
          }}
          cancelAction={() => {
            setAddressVerificationConfirmModalShow(false);
          }}
        />
      )}
    </>
  );
}
