import React, { useState, useEffect } from "react";
import _ from "lodash";
import * as Yup from "yup";
import { useFormik } from "formik";
import { Spinner, Button, Modal, Form } from "react-bootstrap";
import { toast } from "react-toastify";
import ConfirmModal from "../../common/ConfirmModal";
import { addSupplyToken as requestAddSupplyTokenToken } from "../../../../client/TokenManagerClient";
import { numberWithCommasNormal } from "../../../../app/utils/util";
import { tokenAccountInfo } from "../../../../util/solanaApi";

export default function TokenAddSupplyModal({
  authToken,
  tokenInfo,
  tokenWallets,
  setSupplyTokenModalShow,
  getTokenWallets
}) {
  const [isTokenSupplyLoading, setIsTokenSupplyLoading] = useState(false);
  const [supplyTokenConfirm, setSupplyTokenConfirm] = useState(false);
  const [supplyValues, setSupplyValues] = useState(null);
  const [
    tokenAccountInfoForMintAuthority,
    setTokenAccountInfoForMintAuthority
  ] = useState(null);

  useEffect(() => {
    if (tokenInfo) {
      getTokenAccountInfo();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [tokenWallets, tokenInfo]);

  async function getTokenAccountInfo() {
    const accountInfo = await tokenAccountInfo(tokenInfo.tokenMint);
    if (accountInfo === undefined) {
      console.log(`Not found token info.`);
    }
    if (accountInfo) {
      const ownerWallet = tokenWallets.find(
        f => f.ownerPublicKey === accountInfo.mintAuthority
      );
      if (ownerWallet) {
        formik.setFieldValue("tokenAccountAddress", ownerWallet.walletAddress);
        setTokenAccountInfoForMintAuthority(ownerWallet);
      } else {
        setTokenAccountInfoForMintAuthority(undefined);
      }
    }
  }

  async function supplyToken(body) {
    try {
      setIsTokenSupplyLoading(true);
      const response = await requestAddSupplyTokenToken({
        authToken,
        mint: tokenInfo.tokenMint,
        supplyTokenAccount: body.tokenAccountAddress,
        amount: body.supplyAmount
      });
      if (response) {
        toast.info(
          `${numberWithCommasNormal(body.supplyAmount)}${
            tokenInfo.symbol
          } Additional tokens have been issued.`,
          {
            position: "top-right",
            autoClose: 3500,
            hideProgressBar: false,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true
          }
        );
        getTokenWallets();
        setIsTokenSupplyLoading(false);
        setSupplyTokenModalShow(false);
      } else {
        toast.error(`There is a temporary error. please try again`, {
          position: "top-right",
          autoClose: 5000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true
        });
        setIsTokenSupplyLoading(false);
      }
    } catch (e) {
      if (e.response) {
        if (e.response.status === 401) {
          toast.error(`The session has expired. please login again`, {
            position: "top-right",
            autoClose: 3000,
            hideProgressBar: false,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true
          });
          window.location.reload();
        } else {
          toast.error(e.response.data.errorMessage, {
            position: "top-right",
            autoClose: 3000,
            hideProgressBar: false,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true
          });
        }
      }
      setIsTokenSupplyLoading(false);
    }
  }

  const Schema = Yup.object().shape({
    tokenAccountAddress: Yup.string().required(
      "Select Token wallet is required"
    ),
    supplyAmount: Yup.string()
      .required("Supply Amount is required")
      .min(1, "Please enter between 1 ~")
  });

  const getInputClasses = fieldname => {
    if (formik.touched[fieldname] && formik.errors[fieldname]) {
      return "is-invalid";
    }

    if (formik.touched[fieldname] && !formik.errors[fieldname]) {
      return "is-valid";
    }

    return "";
  };

  const formik = useFormik({
    initialValues: {
      tokenAccountAddress: undefined,
      supplyAmount: 0
    },
    validationSchema: Schema,
    onSubmit: (values, { setSubmitting }) => {
      if (!values.tokenAccountAddress) {
        toast.error(`Select wallet address`, {
          position: "top-right",
          autoClose: 2000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true
        });
        return;
      }
      if (parseFloat(values.supplyAmount) <= 0) {
        toast.error(`Enter the supply amount`, {
          position: "top-right",
          autoClose: 2000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true
        });
        return;
      }

      if (!values.supplyChecbox) {
        toast.error(`Have you checked all the information?`, {
          position: "top-right",
          autoClose: 2000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true
        });
        return;
      }

      setTimeout(() => {
        setSubmitting(false);
        setSupplyValues(values);
        handleSupplyTokenConfirm();
      }, 500);
    }
  });

  function getSelectWallet() {
    if (formik.values.tokenAccountAddress) {
      const findSelectWallet = tokenWallets.find(
        f => f.walletAddress === formik.values.tokenAccountAddress
      );
      return findSelectWallet;
    }
    return undefined;
  }

  const handleSupplyTokenConfirm = () => {
    setSupplyTokenConfirm(!supplyTokenConfirm);
  };

  return (
    <>
      <Modal
        show={true}
        backdrop="static"
        keyboard={false}
        size="lg"
        aria-labelledby="contained-modal-title-vcenter"
        onHide={() => {
          setSupplyTokenModalShow(false);
        }}
        centered
      >
        <Modal.Header closeButton>
          <Modal.Title id="contained-modal-title-vcenter">
            Token Supply
          </Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <Form onSubmit={formik.handleSubmit}>
            <Form.Group controlId="formSelectSupplyTokenWallet">
              <Form.Label>
                <b>Select token wallet</b>
              </Form.Label>
              <div>
                <select
                  disabled
                  required
                  className={`form-control form-control-lg form-control-solid ${getInputClasses(
                    "tokenAccountAddress"
                  )}`}
                  name="tokenAccountAddress"
                  {...formik.getFieldProps("tokenAccountAddress")}
                >
                  {!_.isEmpty(tokenWallets) ? (
                    <>
                      <option>
                        {tokenAccountInfoForMintAuthority === null
                          ? "Loading..."
                          : tokenAccountInfoForMintAuthority === undefined
                          ? "Token ownership rights not found"
                          : "Token Owner Wallet"}
                      </option>
                      {tokenWallets.map(wallet => {
                        return (
                          <option
                            key={wallet.walletAddress}
                            value={wallet.walletAddress}
                          >
                            {wallet.name}({wallet.walletAddress})
                          </option>
                        );
                      })}
                    </>
                  ) : (
                    <option value={null}>
                      Token ownership rights not found.
                    </option>
                  )}
                </select>
                {formik.touched.tokenAccountAddress &&
                formik.errors.tokenAccountAddress ? (
                  <div className="invalid-feedback">
                    {formik.errors.tokenAccountAddress}
                  </div>
                ) : null}
                {/* <div style={{ textAlign: "right", color: "#646464" }}>
                  <span
                    style={{ cursor: "pointer" }}
                    onClick={() => {
                      getTokenWallets();
                    }}
                  >
                    Reload
                  </span>
                </div> */}
              </div>
            </Form.Group>
            <Form.Group controlId="formSupplyAmount">
              <Form.Label>
                <span style={{ color: "red" }}>* </span>Amount(
                {tokenInfo.symbol})
              </Form.Label>
              <Form.Control
                required
                disabled={!formik.values.tokenAccountAddress}
                name="formSupplyAmount"
                type="number"
                step={0.000000001}
                placeholder="input supply amount"
                onChange={e => {
                  const wallet = getSelectWallet();
                  // 소각 가능수량검증
                  if (wallet.walletBalance && parseFloat(e.target.value) < 1) {
                    toast.error(`Please enter 1 or more`, {
                      position: "top-right",
                      autoClose: 3000,
                      hideProgressBar: false,
                      closeOnClick: true,
                      pauseOnHover: true,
                      draggable: true
                    });
                    return;
                  }
                  formik.setFieldValue("supplyAmount", e.target.value);
                }}
              />
              {formik.values.tokenAccountAddress && getSelectWallet() && (
                <div style={{ textAlign: "right" }}>
                  {`Current Balance: ${numberWithCommasNormal(
                    getSelectWallet().walletBalance
                  )}`}{" "}
                  <span style={{ fontSize: "9px", color: "#646464" }}>
                    {tokenInfo.symbol}
                  </span>
                </div>
              )}
            </Form.Group>
            <Form.Group controlId="formBasicChecbox">
              <Form.Check
                name="formBasicChecbox"
                type="checkbox"
                label="I have double checked token supply."
                s
                onChange={e => {
                  formik.setFieldValue("supplyChecbox", e.target.checked);
                }}
              />
            </Form.Group>
            <Button
              disabled={isTokenSupplyLoading}
              style={{ marginRight: "6px" }}
              variant="primary"
              type="submit"
            >
              {!isTokenSupplyLoading ? (
                "Request Supply"
              ) : (
                <Spinner animation="border" />
              )}
            </Button>
            <Button
              disabled={isTokenSupplyLoading}
              variant="secondary"
              onClick={() => {
                setSupplyTokenModalShow(false);
              }}
            >
              Close
            </Button>
          </Form>
          {isTokenSupplyLoading && (
            <div style={{ marginTop: "10px" }}>
              <span style={{ color: "#646464" }}>
                Transaction is being confirmed. Please wait...
              </span>
            </div>
          )}
        </Modal.Body>
      </Modal>

      <ConfirmModal
        showConfirm={supplyValues && supplyTokenConfirm}
        title={"Confirm Supply Token"}
        message={
          <>
            <div
              style={{ fontSize: "15px", color: "red", marginBottom: "10px" }}
            >
              Warning! This action is irreversible.
            </div>
            <p>
              Issuance of additional tokens
              <br />
              Additional issued tokens are non irreversible. <br />
              Shall issue tokens?
            </p>
          </>
        }
        confirmAction={() => {
          handleSupplyTokenConfirm();
          supplyToken(supplyValues);
        }}
        cancelAction={handleSupplyTokenConfirm}
      />
    </>
  );
}
