import React, { useEffect, useState } from "react";
import _ from "lodash";
import { Spinner, Alert, Button } from "react-bootstrap";
import { fetchMyTokens } from "../../../client/TokenManagerClient";
import MyTokenList from "./component/MyTokenList";
import NetworkError from "../common/NetworkError";
import MyTokenFAQ from "./component/MyTokenFAQ";
import { Link } from "react-router-dom";
import { fetchMyDpositWallets } from "../../../client/WalletManagerClient";
import TokenManagerGuideModal from "./component/TokenManagerGuideModal";
import { showErrorToastMessage } from "../../../app/utils/util";

export const MIN_DEPOSIT_BALANCE_COST = 0.01;
export function MyToken({ props }) {
  const {
    auth: { authToken }
  } = props.auth;

  const [isError, setIsError] = useState(false);
  const [isLoading, setIsLoading] = useState(true);
  const [myAllTokens, setMyAllTokens] = useState([]);
  const [isDepostWallet, setIsDepostWallet] = useState(false);
  const [isDepostWalletLoading, setIsDepostWalletLoading] = useState(true);
  const [tokenManagerGuideModalShow, setTokenManagerGuideModalShow] = useState(
    false
  );

  useEffect(() => {
    getMyTokens();
    getMyDepositTokens();
  }, []); // eslint-disable-line react-hooks/exhaustive-deps

  async function getMyDepositTokens() {
    try {
      const response = await fetchMyDpositWallets({ authToken });
      if (response) {
        // SOL 잔액확인( DXL이 사용되면 depositWalletBalance 잔액을 체크해야 됨 )
        if (
          !_.isEmpty(response) &&
          response[0].solBalance >= MIN_DEPOSIT_BALANCE_COST
        ) {
          setIsDepostWallet(true);
        } else {
          setIsDepostWallet(false);
        }
        setIsDepostWalletLoading(false);
      }
    } catch (e) {
      showErrorToastMessage(
        e,
        `Deposit wallet inquiry failed. please try again`
      );
      setIsDepostWalletLoading(false);
    }
  }

  async function getMyTokens() {
    try {
      if (!isLoading) {
        setIsLoading(true);
      }

      const response = await fetchMyTokens({ authToken });
      setMyAllTokens(response);
      setIsLoading(false);
    } catch (e) {
      showErrorToastMessage(e, `Failed to create wallet. please try again`);
      setIsError(true);
      setIsLoading(false);
    }
  }

  if (isLoading) {
    return (
      <div style={{ textAlign: "center", marginTop: "60px" }}>
        <Spinner animation="grow" />
      </div>
    );
  }

  if (isError) {
    return <NetworkError retry={getMyTokens} />;
  }

  return (
    <>
      {!isError && !isLoading && !_.isEmpty(myAllTokens) ? (
        <>
          <Alert show={true} variant="secondary">
            <h3>Minting Lab V2 released!</h3>
            <div>
              Minting Lab V1 token minting function will be deprecated according
              to the future schedule.
              <br />
              Please change the ownership of the tokens managed by Minting Lab
              V1 to your wallet and withdraw all tokens.
              <br />
              <a
                href="https://www.dexlab.space/mintinglab/spl-token"
                target="_blank"
                rel="noopener noreferrer"
              >
                Go to Minting Lab V2
              </a>
              <br />
              <a
                href="https://docs.dexlab.space/products/minting-lab/minting-v1-greater-than-minting-v2-migration"
                target="_blank"
                rel="noopener noreferrer"
              >
                View Migration Guide
              </a>
            </div>
          </Alert>
          <Alert show={true} variant="primary">
            <Alert.Heading>SPL Token Management</Alert.Heading>
            <p>
              Issue and manage Solana-based tokens.
              <br />
              Offered through the SPL Program, see{" "}
              <a
                style={{ color: "#FFFFFF", fontWeight: "bold" }}
                href="https://spl.solana.com/token"
                target="_blank"
                rel="noopener noreferrer"
              >
                "here"
              </a>{" "}
              for more information.
            </p>
            <hr />
            <div className="d-flex justify-content-end">
              {/* <Link
                to={
                  !isDepostWalletLoading && isDepostWallet
                    ? "/management/token/new"
                    : "/management/token/list"
                }
                style={{ color: "#FFF", marginRight: "6px" }}
                className={`btn btn-success font-weight-bold mr-2`}
                onClick={() => {
                  if (!isDepostWalletLoading && !isDepostWallet) {
                    setTokenManagerGuideModalShow(true);
                  }
                }}
              >
                Create Token
              </Link> */}
              <a
                style={{ color: "#FFF", marginRight: "6px" }}
                className={`btn btn-success font-weight-bold mr-2`}
                href="https://www.dexlab.space/mintinglab/spl-token"
                target="_blank"
                rel="noopener noreferrer"
              >
                Create Token
              </a>
              <Button
                style={{
                  color: "#FFF",
                  borderColor: "#FFF",
                  marginLeft: "6px"
                }}
                onClick={() => {
                  getMyTokens();
                }}
                variant="outline-info"
              >
                Refresh
              </Button>
            </div>
          </Alert>
          <MyTokenList myTokens={myAllTokens} />
        </>
      ) : (
        <>
          <MyTokenFAQ
            isDepostWallet={isDepostWallet}
            isDepostWalletLoading={isDepostWalletLoading}
            setTokenManagerGuideModalShow={setTokenManagerGuideModalShow}
          />
          <div style={{ textAlign: "center", marginTop: "60px" }}>
            No Token has been created.
          </div>
        </>
      )}
      {tokenManagerGuideModalShow && (
        <TokenManagerGuideModal
          title={`Insufficient transaction fees.`}
          message={
            <>
              <p>
                <h3>Error:</h3>A minimum deposit of{" "}
                <b>[{MIN_DEPOSIT_BALANCE_COST} SOL]</b> is required to create
                tokens.
                <br />
                <br />
              </p>
              <div style={{ marginTop: "20px" }}>
                <h5>What is a deposit?</h5>
                All fees of Minting Lab use the deposit wallet.
                <br />
                Create a deposit wallet and deposit tokens to be used as fees.
                <br />
                The deposit wallet is used for fees for all subsequent
                transactions.
                <Link
                  to={"/management/deposit-wallets"}
                  style={{ color: "#3699FF", borderColor: "#3699FF" }}
                  variant="outline-success"
                >
                  <b> Go to Depost Wallet</b>
                </Link>
              </div>
              <div style={{ marginTop: "10px" }}>
                Need more information?{" "}
                <a
                  style={{ color: "#3699FF", borderColor: "#3699FF" }}
                  href="https://docs.dexlab.space/products/minting-lab/mintinglab-tutorial/what-is-a-deposit-wallet"
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  Deposit Wallet Guide
                </a>
              </div>
            </>
          }
          setTokenManagerGuideModalShow={setTokenManagerGuideModalShow}
        />
      )}
    </>
  );
}
