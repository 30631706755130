/* eslint-disable no-script-url,jsx-a11y/anchor-is-valid */
import React, { useState } from "react";
import _ from "lodash";
import { Button } from "react-bootstrap";

export default function UrlMultiInput({ formik, initValues }) {
  const initValue =
    initValues &&
    initValues.filter(f => f.name !== "HOMEPAGE" && f.name !== "TWITTER");
  const [inputList, setInputList] = useState(
    !_.isEmpty(initValue) ? initValue : []
  );

  // handle input change
  const handleInputChange = (e, index) => {
    const { name, value } = e.target;
    const list = [...inputList];
    list[index][name] = value;

    formik.setFieldValue("community", list);
    setInputList(list);
  };

  // handle click event of the Remove button
  const handleRemoveClick = index => {
    const list = [...inputList];
    list.splice(index, 1);

    formik.setFieldValue("community", list);
    setInputList(list);
  };

  // handle click event of the Add button
  const handleAddClick = () => {
    setInputList([...inputList, { name: "", url: "" }]);
  };

  return (
    <>
      <div className="row">
        <label className="col-xl-3"></label>
        <div className="col-lg-9 col-xl-6">
          <h5 className="font-weight-bold mt-10 mb-6">Other Links</h5>
        </div>
      </div>
      {inputList.map((x, i) => {
        return (
          <div className="form-group row" key={i}>
            <label className="col-xl-3 col-lg-3 col-form-label">New URL</label>
            <div className="col-lg-9 col-xl-6">
              <input
                className={`form-control form-control-lg form-control-solid`}
                style={{ marginBottom: "3px" }}
                name="name"
                placeholder="Enter Name"
                value={x.name}
                onChange={e => handleInputChange(e, i)}
              />
              <div className="input-group input-group-lg input-group-solid">
                <div className="input-group-prepend">
                  <span className="input-group-text">
                    <i className="fa fa-link"></i>
                  </span>
                </div>
                <input
                  className={`form-control form-control-lg form-control-solid`}
                  name="url"
                  placeholder="Enter Url"
                  type="url"
                  value={x.url}
                  onChange={e => handleInputChange(e, i)}
                />
              </div>
              <div
                className="btn-box"
                style={{ textAlign: "right", marginTop: "3px" }}
              >
                {inputList.length !== 0 && (
                  <Button
                    variant="outline-danger"
                    onClick={() => handleRemoveClick(i)}
                  >
                    Remove
                  </Button>
                )}
              </div>
            </div>
          </div>
        );
      })}
      <div className="row">
        <div className="col-lg-12 col-xl-12" style={{ textAlign: "center" }}>
          <Button
            variant="outline-primary"
            style={{ marginLeft: "3px", width: "150px" }}
            onClick={handleAddClick}
          >
            Add New Link
          </Button>
        </div>
      </div>
    </>
  );
}
