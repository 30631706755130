import React, { useState, useEffect } from "react";
import { useFormik } from "formik";
import { Spinner, Button, Modal, Form } from "react-bootstrap";
import { toast } from "react-toastify";
import {
  getNetworkClusterName,
  convertNetworkNameToExplorerPath
} from "../../../../app/utils/util";
import {
  transferToken,
  validWalletAddress
} from "../../../../client/WalletManagerClient";
import EmailTwoFactoreConfirmModal from "../../common/EmailTwoFactoreConfirmModal";
import ConfirmModal from "../../common/ConfirmModal";

export default function MySPLWalletSendTokenModal({
  authToken,
  sendTokenWallet,
  sendTokenModalShow,
  getSplWallets,
  setSendTokenModalShow
}) {
  const [isSendLoading, setIsSendLoading] = useState(false);
  const [
    emailTwoFactoreConfirmModalShow,
    setEmailTwoFactoreConfirmModalShow
  ] = useState(false);
  const [baseRequestBody, setBaseRequestBody] = useState(null);
  const [
    addressVerificationConfirmModalShow,
    setAddressVerificationConfirmModalShow
  ] = useState(false);

  async function sendToken({
    ownerPublicKey,
    from,
    to,
    tokenAddress,
    amount,
    authCode
  }) {
    try {
      const response = await transferToken({
        authToken,
        emailAuthCode: authCode,
        body: {
          ownerPublicKey,
          from,
          to,
          tokenAddress,
          amount: parseFloat(amount)
        }
      });
      if (response) {
        toast.info(
          <>
            {" "}
            Sending success.
            <br />
            <a
              style={{ color: "#FFFFFF", fontWeight: "bold" }}
              href={`https://explorer.solana.com/tx/${
                response.txId
              }${convertNetworkNameToExplorerPath(getNetworkClusterName())}`}
              target="_blank"
              rel="noopener noreferrer"
            >
              View Transaction
            </a>
          </>,
          {
            position: "top-right",
            autoClose: 2000,
            hideProgressBar: false,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true
          }
        );
        setSendTokenModalShow(false);
        setTimeout(() => {
          getSplWallets(false);
        }, 3000);
      }
      setIsSendLoading(false);
    } catch (e) {
      toast.error(
        e.response
          ? e.response.data.errorMessage
          : `Sending failed  If the problem persists, please contact Dexlab.`,
        {
          position: "top-right",
          autoClose: 3000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true
        }
      );
      setIsSendLoading(false);
    }
  }

  useEffect(() => {
    if (baseRequestBody) {
      handleTokenSendSubmit(true);
    }
  }, [baseRequestBody]); // eslint-disable-line react-hooks/exhaustive-deps

  const handleTokenSendSubmit = async (valid = true) => {
    if (valid) {
      // 입금지갑 검증
      const validResult = await validWalletAddress({
        authToken,
        address: baseRequestBody.to,
        checkMint: baseRequestBody.tokenAddress
      });
      if (!validResult) {
        setAddressVerificationConfirmModalShow(true);
        return;
      }
    }
    setEmailTwoFactoreConfirmModalShow(true);
  };

  const formik = useFormik({
    initialValues: {
      receiveAddress: undefined, // 받을 지갑주소
      sendAmount: 0, // 받을 수량
      addressChecbox: false,
      selectTokenWallet: sendTokenWallet.walletAddress,
      selectTokenMint: sendTokenWallet.walletTokenAddress,
      selectTokenName: sendTokenWallet.symbol
    },
    onSubmit: (values, { setSubmitting }) => {
      if (!values.addressChecbox) {
        toast.error(`Did you check your wallet address?`, {
          position: "top-right",
          autoClose: 2000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true
        });
        return;
      }
      if (!values.receiveAddress) {
        toast.error(`Enter the wallet address to deposit`, {
          position: "top-right",
          autoClose: 2000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true
        });
        return;
      }
      if (parseFloat(values.sendAmount) === 0) {
        toast.error(`Enter amount`, {
          position: "top-right",
          autoClose: 2000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true
        });
        return;
      }

      setBaseRequestBody({
        ownerPublicKey: sendTokenWallet.ownerPublicKey,
        from: values.selectTokenWallet,
        to: values.receiveAddress,
        tokenAddress: values.selectTokenMint,
        amount: values.sendAmount
      });
      setSubmitting(false);
    }
  });

  // 토큰전송
  const emailAuthConfirm = authCode => {
    setIsSendLoading(true);
    emailAuthCancel();

    sendToken({
      ...baseRequestBody,
      authCode
    });
  };

  const emailAuthCancel = () => {
    setEmailTwoFactoreConfirmModalShow(false);
  };

  return (
    <>
      <Modal
        show={sendTokenModalShow}
        size="lg"
        aria-labelledby="contained-modal-title-vcenter"
        onHide={() => {
          setSendTokenModalShow(false);
        }}
        centered
      >
        <Modal.Header closeButton>
          <Modal.Title id="contained-modal-title-vcenter">
            Send <b>{sendTokenWallet.symbol}</b>
            <span style={{ fontSize: "12px" }}>
              ({sendTokenWallet.walletAddress})
            </span>
          </Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <Form onSubmit={formik.handleSubmit}>
            <Form.Group controlId="formWithdrawAddress">
              <Form.Label>
                <span style={{ color: "red" }}>* </span>
                <b>{formik.values.selectTokenName}</b> wallet address to receive
                wallet
              </Form.Label>
              <Form.Control
                name="formReciveAddress"
                type="text"
                placeholder="input Receive address"
                onChange={e => {
                  formik.setFieldValue("receiveAddress", e.target.value);
                }}
              />
            </Form.Group>
            <Form.Group controlId="formSendAmount">
              <Form.Label>
                <span style={{ color: "red" }}>* </span>Amount(
                {formik.values.selectTokenName})
              </Form.Label>
              <Form.Control
                name="formSendAmount"
                type="number"
                step={0.000000001}
                placeholder="input token amount"
                onChange={e => {
                  // 수량검증
                  if (
                    parseFloat(sendTokenWallet.total) <
                    parseFloat(e.target.value)
                  ) {
                    toast.error(
                      `Insufficient amount (maximum: ${sendTokenWallet.total})`,
                      {
                        position: "top-right",
                        autoClose: 2000,
                        hideProgressBar: false,
                        closeOnClick: true,
                        pauseOnHover: true,
                        draggable: true
                      }
                    );
                    return;
                  }

                  formik.setFieldValue("sendAmount", e.target.value);
                }}
              />
            </Form.Group>
            <Form.Group controlId="formBasicChecbox">
              <div style={{ marginBottom: "3px" }}>
                <span style={{ fontWeight: "bold" }}>
                  If you send to the wrong address, it cannot be recovered!
                </span>
                <br />
                Token transfers incur a fee. (
                <a
                  style={{ color: "#000000" }}
                  href="https://solanabeach.io/transactions"
                  target="_blnak"
                >
                  fee Status
                </a>
                )<br />
                <em>Fees are withdrawn from the deposit wallet</em>
              </div>
              <Form.Check
                name="formBasicChecbox"
                type="checkbox"
                label="I have double checked my wallet address."
                onChange={e => {
                  formik.setFieldValue("addressChecbox", e.target.checked);
                }}
              />
            </Form.Group>
            <Button
              disabled={isSendLoading}
              style={{ marginRight: "6px" }}
              variant="primary"
              type="submit"
            >
              {!isSendLoading ? "Send" : <Spinner animation="border" />}
            </Button>
            <Button
              disabled={isSendLoading}
              variant="secondary"
              onClick={() => {
                setSendTokenModalShow(false);
              }}
            >
              Close
            </Button>
          </Form>
          {isSendLoading && (
            <div style={{ marginTop: "10px" }}>
              <span style={{ color: "#646464" }}>
                Transaction is being confirmed. Please wait...
              </span>
            </div>
          )}
        </Modal.Body>
      </Modal>
      {emailTwoFactoreConfirmModalShow && baseRequestBody && (
        <EmailTwoFactoreConfirmModal
          title={`Two-factor Authentication`}
          message={
            <>
              Email secondary authentication is required for security.
              <br />
              Please enter the security code <b>sent to your email</b>
            </>
          }
          authToken={authToken}
          authType={`TOKEN_TRANSFER_TRANSACTION`}
          requestBody={baseRequestBody}
          confirmAction={emailAuthConfirm}
          cancelAction={emailAuthCancel}
        />
      )}
      {addressVerificationConfirmModalShow && baseRequestBody && (
        <ConfirmModal
          showConfirm={addressVerificationConfirmModalShow}
          title={"Confirm Address"}
          message={"This address has no funds. Are you sure it's correct?"}
          confirmAction={() => {
            handleTokenSendSubmit(false);
            setAddressVerificationConfirmModalShow(false);
          }}
          cancelAction={() => {
            setAddressVerificationConfirmModalShow(false);
          }}
        />
      )}
    </>
  );
}
