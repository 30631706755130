/**
 * config
 */
const projectConfig = {
  APP_VERSION: process.env.APP_VERSION || "Beta v0.1.3",
  API_BASE_URL:
    process.env.API_BASE_URL || "https://mintinglab-api.dexlab.space",
  // API_BASE_URL: process.env.API_BASE_URL || "http://localhost:8080",
  RECAPTCHA_SITE_KEY:
    process.env.RECAPTCHA_SITE_KEY ||
    "6LcSKJMcAAAAAJdaHYO2GiDBVDNaruUV03G9Vgbk",
  NETWORK_CONFIG_KEY: process.env.NETWORK_CONFIG_KEY || "networkConfig",
  night_mode: false,
  firebaseConfig: {
    // PROD
    apiKey:
      process.env.FIREBASE_API_KEY || "AIzaSyCTVArHEbQgo5H8uS6mBrTmrR9BMh-H2l4",
    authDomain:
      process.env.FIREBASE_AUTH_DOMAIN ||
      "mintinglab-production.firebaseapp.com",
    projectId: process.env.FIREBASE_PROJECT_ID || "mintinglab-production",
    storageBucket:
      process.env.FIREBASE_STORAGE_BUCKET ||
      "mintinglab-production.appspot.com",
    messagingSenderId:
      process.env.FIREBASE_MESSAGING_SENDER_ID || "534971610433",
    appId:
      process.env.FIREBASE_APP_ID ||
      "1:534971610433:web:08e583040f82a9e8e49dc0",
    measurementId: process.env.FIREBASE_MEASUREMENT_ID || "G-08QKV62RX1",
    // DEV
    // apiKey: "AIzaSyAVhGVOLrwj5-iMPxysLTMS2_ikfBbNXeQ",
    // authDomain: "dexlab-mintinglab-dev.firebaseapp.com",
    // projectId: "dexlab-mintinglab-dev",
    // storageBucket: "dexlab-mintinglab-dev.appspot.com",
    // messagingSenderId: "904383649046",
    // appId: "1:904383649046:web:caae02a07e386603a2ac37"
  },
  SOLANA_HOST:
    process.env.SOLANA_HOST || "https://global-rpc.dexlab.space/v1/rpc",
  SOLANA_SERUM_HOST:
    process.env.SOLANA_SERUM_HOST || "https://global-rpc.dexlab.space/v1/rpc",
  SOLANA_DEV_NET_HOST:
    process.env.SOLANA_DEV_NET_HOST || "https://api.devnet.solana.com",
  SOLANA_TEST_NET_HOST:
    process.env.SOLANA_TEST_NET_HOST || "https://api.testnet.solana.com",
};
export default projectConfig;
