import React from "react";
import { Alert, Button } from "react-bootstrap";

export default function NetworkError({ retry }) {
  return (
    <>
      <Alert show={true} variant="danger">
        <Alert.Heading>Ooops!</Alert.Heading>
        <p>
          A temporary network error has occurred.
          <br />
          Please try again in a few minutes.
          <br />
          <br />
          The issue has been reported to the Dexlab team for quick resolution.
        </p>
        <hr />
        <div className="d-flex justify-content-end">
          <Button
            style={{ color: "#FFF", borderColor: "#FFF", marginRight: "6px" }}
            onClick={() => {
              retry();
            }}
            variant="success"
          >
            Retry
          </Button>
          <Button
            style={{ color: "#FFF", borderColor: "#FFF", marginRight: "6px" }}
            onClick={() => {
              window.open("https://discord.gg/XcQDV7z2Hz");
            }}
            variant="outline-success"
          >
            Contact us
          </Button>
        </div>
      </Alert>
    </>
  );
}
