import React, { useState } from "react";
import _ from "lodash";
import { Button, Modal, Spinner, Form, Col, Row } from "react-bootstrap";
import { toast } from "react-toastify";
import { importChildTokenWallet } from "../../../../client/WalletManagerClient";
import { convertNetworkNameToExplorerPath } from "../../../../app/utils/util";

export default function InputChildTokenWalletInfoModal({
  authToken,
  ownerPublicKey,
  wallet,
  reloadChildTokenWallets,
  setInputChildTokenWalletInfoModal
}) {
  const [isAddLoading, setIsAddLoading] = useState(false);
  const [inputName, setInputName] = useState("");
  const [inputSymbol, setInputSymbol] = useState(
    wallet.name.length < 10 ? wallet.name : ""
  );

  const handleAddWallet = () => {
    addWallet();
  };

  async function addWallet() {
    if (!inputName || _.isEmpty(inputSymbol)) {
      toast.error(`Enter wallet name`, {
        position: "top-right",
        autoClose: 3000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true
      });
      return;
    }

    if (!inputSymbol || _.isEmpty(inputSymbol)) {
      toast.error(`Enter token symbol`, {
        position: "top-right",
        autoClose: 3000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true
      });
      return;
    }

    try {
      const body = {
        name: inputName,
        symbol: inputSymbol.toUpperCase(),
        tokenAddress: wallet.walletTokenAddress,
        tokenWalletAddress: wallet.walletAddress
      };
      setIsAddLoading(true);
      const response = await importChildTokenWallet({
        authToken,
        ownerPublicKey,
        body
      });
      if (response) {
        reloadChildTokenWallets();
        setInputChildTokenWalletInfoModal(false);
      } else {
        setIsAddLoading(false);
      }
    } catch (e) {
      toast.error(e.response ? e.response.data.errorMessage : `Error.`, {
        position: "top-right",
        autoClose: 3000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true
      });
      setIsAddLoading(false);
    }
  }

  return (
    <Modal
      show={true}
      size="lg"
      aria-labelledby="contained-modal-title-vcenter"
      onHide={() => {
        setInputChildTokenWalletInfoModal(false);
      }}
      centered
    >
      <Modal.Header closeButton>
        <Modal.Title id="contained-modal-title-vcenter">
          {wallet.name}{" "}
          <span style={{ fontSize: "10px", color: "#646464" }}>Add Wallet</span>
        </Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <h4>Enter wallet information</h4>
        <Form>
          <Form.Group as={Row} controlId="formWalletName">
            <Form.Label column sm="2">
              <span style={{ color: "#FF0000" }}>*</span> Name
            </Form.Label>
            <Col sm="10">
              <Form.Control
                required
                type="text"
                placeholder="Enter wallet name"
                value={inputName}
                onChange={e => {
                  setInputName(e.target.value);
                }}
              />
            </Col>
          </Form.Group>
          <Form.Group as={Row} controlId="formWalletMemo">
            <Form.Label column sm="2">
              <span style={{ color: "#ff0000" }}>*</span> Symbol
            </Form.Label>
            <Col sm="10">
              <Form.Control
                type="text"
                placeholder="Enter token symbol"
                value={inputSymbol}
                onChange={e => {
                  setInputSymbol(e.target.value);
                }}
              />
            </Col>
          </Form.Group>
        </Form>
        <p>
          Mint:{" "}
          <a
            href={`https://explorer.solana.com/address/${
              wallet.walletTokenAddress
            }${convertNetworkNameToExplorerPath(wallet.network)}`}
            target="_blank"
            rel="noopener noreferrer"
            variant="primary"
          >
            <b>{wallet.walletTokenAddress}</b>
          </a>
          <br />
          Wallet:{" "}
          <a
            href={`https://explorer.solana.com/address/${
              wallet.walletAddress
            }${convertNetworkNameToExplorerPath(wallet.network)}`}
            target="_blank"
            rel="noopener noreferrer"
            variant="primary"
          >
            <b>{wallet.walletAddress}</b>
          </a>
        </p>
      </Modal.Body>
      <Modal.Footer>
        <Button
          variant="primary"
          onClick={() => {
            handleAddWallet();
          }}
        >
          {isAddLoading ? <Spinner animation="grow" /> : "Add"}
        </Button>
        <Button
          variant="secondary"
          onClick={() => {
            setInputChildTokenWalletInfoModal(false);
          }}
        >
          {isAddLoading ? <Spinner animation="grow" /> : "Close"}
        </Button>
      </Modal.Footer>
    </Modal>
  );
}
