import React, { useEffect, useState } from "react";
import { Link } from "react-router-dom";
import { Alert } from "react-bootstrap";
import {
  MintingLabMixedWidget,
  MintingLabTxWidget,
  MintingLabStatsWidget,
  MintingLabStatsWidget2
} from "../widgets";
import {
  fetchMyTokens,
  fetchTokenHistory
} from "../../../client/TokenManagerClient";
import { firebaseAuth } from "../../../app/utils/firebase";

export function MintingLabV1Dashboard({ authToken }) {
  const currentUser = firebaseAuth.currentUser;

  const [myOwnerTokens, setMyOwnerTokens] = useState([]);
  const [selectToken, setSelectToken] = useState(null);
  const [tokenHistory, setTokenHistory] = useState(null);

  useEffect(() => {
    getMyOwnerTokens();
  }, []); // eslint-disable-line react-hooks/exhaustive-deps

  useEffect(() => {
    if (selectToken) {
      getTokenTransferAndBurnHistory(selectToken.tokenMint);
    }
  }, [selectToken]); // eslint-disable-line react-hooks/exhaustive-deps

  async function getMyOwnerTokens() {
    const response = await fetchMyTokens({ authToken });
    if (response) {
      if (!selectToken) {
        setSelectToken(response[0]);
      }
      setMyOwnerTokens(response);
    }
  }

  async function getTokenTransferAndBurnHistory(tokenMint) {
    try {
      const response = await fetchTokenHistory({ authToken, mint: tokenMint });
      if (response) {
        setTokenHistory(response);
      }
    } catch (e) {
      console.log(e);
    }
  }

  return (
    <>
      <Alert style={{ textAlign: "center" }} variant="dark">
        This project is in alpha stage and has not been audited.{" "}
        <b>Use at your own risk.</b>
      </Alert>
      {currentUser && !currentUser.emailVerified ? (
        <Alert variant="info">
          E-mail authentication is required to use all functions.
          <Link
            style={{ color: "#FFFFFF", fontWeight: "bold", marginLeft: "6px" }}
            to="/user-profile/email-settings"
          >
            Verify your email
          </Link>
        </Alert>
      ) : null}
      <div className="row">
        <div className="col-lg-6 col-xxl-4">
          <MintingLabMixedWidget
            selectToken={selectToken}
            setSelectToken={setSelectToken}
            myOwnerTokens={myOwnerTokens}
            className="card-stretch gutter-b"
          />
        </div>
        <div className="col-lg-6 col-xxl-4">
          <MintingLabTxWidget
            selectToken={selectToken}
            tokenHistory={tokenHistory ? tokenHistory.transferHistory : []}
            className="card-stretch gutter-b"
          />
        </div>

        <div className="col-lg-6 col-xxl-4">
          <MintingLabStatsWidget
            className="card-stretch card-stretch-half gutter-b"
            symbolShape="circle"
            baseColor="success"
          />
          <MintingLabStatsWidget2 className="card-stretch card-stretch-half gutter-b" />
        </div>

        {/* <div className="col-lg-6 col-xxl-4 order-1 order-xxl-1">
          <MintingLabTransferHistory className="card-stretch gutter-b" />
        </div>
        <div className="col-xxl-8 order-2 order-xxl-1">
          <MintingLabTopHolders className="card-stretch gutter-b" />
        </div> */}
      </div>
      <div style={{ textAlign: "center", marginTop: "30px" }}>
        <span style={{ fontSize: "20px", fontWeight: "bold" }}>
          Dashboard is being prepared.
        </span>
        <br />
        We will provide a lot of information so that you can see it at a glance
        :)
      </div>
    </>
  );
}
