import React, { useState } from "react";
import _ from "lodash";
import { Modal, Spinner } from "react-bootstrap";
import { toast } from "react-toastify";
import { makeStyles, withStyles } from "@material-ui/core/styles";
import { deepOrange, deepPurple } from "@material-ui/core/colors";
import {
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
  Paper,
  Button
} from "@material-ui/core";
import {
  abbreviateAddress,
  convertNetworkNameToExplorerPath,
  numberWithCommasNormal
} from "../../../../app/utils/util";
import InputChildTokenWalletInfoModal from "./InputChildTokenWalletInfoModal";
import MySPLWalletDetailModal from "./MySPLWalletDetailModal";

const StyledTableCell = withStyles(theme => ({
  head: {
    backgroundColor: "#8950FC",
    color: theme.palette.common.white
  },
  body: {
    fontSize: 14
  }
}))(TableCell);

const useStyles = makeStyles(theme => ({
  root: {
    width: "100%",
    marginTop: theme.spacing(3),
    overflowX: "auto"
  },
  table: {
    minWidth: 650
  },
  avatar: {
    margin: 10
  },
  orangeAvatar: {
    margin: 10,
    color: "#fff",
    backgroundColor: deepOrange[500]
  },
  purpleAvatar: {
    margin: 10,
    color: "#fff",
    backgroundColor: deepPurple[500]
  },
  button: {
    margin: theme.spacing(1)
  },
  input: {
    display: "none"
  }
}));

export default function MySPLChildTokenWalletListModal({
  authToken,
  ownerPublicKey,
  selectToken,
  childTokenWallets,
  isChildTokenWalletLoding,
  isReloadLoading,
  reloadChildTokenWallets,
  handleReceiveToken,
  handleSendToken,
  setMySPLChildTokenWalletListModal,
  setMySPLWalletDetailModalShow
}) {
  const classes = useStyles();
  const [
    inputChildTokenWalletInfoModal,
    setInputChildTokenWalletInfoModal
  ] = useState(false);
  const [selectAddWallet, setSelectAddWallet] = useState(undefined);
  const [
    mySPLChildTokenWalletDetailModalShow,
    setMySPLChildTokenWalletDetailModalShow
  ] = useState(false);
  const [selectChildToken, setSelectChildToken] = useState(undefined);

  const handleAddWallet = wallet => {
    setSelectAddWallet(wallet);
    setInputChildTokenWalletInfoModal(true);
  };
  return (
    <>
      <Modal
        show={true}
        size="xl"
        aria-labelledby="contained-modal-title-vcenter"
        onHide={() => {
          setMySPLChildTokenWalletListModal(false);
        }}
        centered
      >
        <Modal.Header closeButton>
          <Modal.Title id="contained-modal-title-vcenter">
            <div>
              {selectToken.name}{" "}
              <span style={{ fontSize: "10px", color: "#646464" }}>Wallet</span>
            </div>
            {ownerPublicKey !== selectToken.name ? (
              <div style={{ fontSize: "11px", color: "#646464" }}>
                {ownerPublicKey}
              </div>
            ) : null}
          </Modal.Title>
        </Modal.Header>
        <Modal.Body style={{ maxHeight: "650px", overflowX: "scroll" }}>
          <div style={{ marginBottom: "16px" }}>
            If necessary, register a sub-token wallet in Minting Lab.
            <br />
            {_.size(childTokenWallets) > 0 && (
              <span>
                There are a total of <b>{_.size(childTokenWallets)}</b> token
                wallets.
              </span>
            )}
          </div>
          <h4>
            Token Wallets{" "}
            {isReloadLoading && (
              <Spinner
                animation="border"
                size="sm"
                style={{ marginBottom: "3px" }}
              />
            )}
          </h4>
          {isChildTokenWalletLoding ? (
            <div style={{ textAlign: "center", marginTop: "15px" }}>
              <Spinner animation="grow" />
            </div>
          ) : (
            <Paper className={classes.root}>
              <Table className={classes.table}>
                <TableHead>
                  <TableRow>
                    <StyledTableCell style={{ wwordBreak: "break-all" }}>
                      Name
                    </StyledTableCell>
                    <StyledTableCell style={{ wwordBreak: "break-all" }}>
                      Mint
                    </StyledTableCell>
                    <StyledTableCell style={{ wwordBreak: "break-all" }}>
                      Wallet Address
                    </StyledTableCell>
                    <StyledTableCell style={{ wwordBreak: "break-all" }}>
                      Balance
                    </StyledTableCell>
                    <StyledTableCell style={{ wwordBreak: "break-all" }}>
                      Add
                    </StyledTableCell>
                  </TableRow>
                </TableHead>
                <TableBody>
                  {childTokenWallets.map((wallet, idx) => {
                    return (
                      <TableRow key={idx}>
                        <StyledTableCell component="th" scope="row">
                          <span
                            style={{ cursor: "pointer" }}
                            onClick={() => {
                              if (wallet.isAdded) {
                                setSelectChildToken(wallet);
                                setMySPLChildTokenWalletDetailModalShow(true);
                              } else {
                                toast.info(
                                  "Please register your token wallet first through the Add button.",
                                  {
                                    position: "top-right",
                                    autoClose: 3000,
                                    hideProgressBar: false,
                                    closeOnClick: true,
                                    pauseOnHover: true,
                                    draggable: true
                                  }
                                );
                              }
                            }}
                          >
                            {wallet.name.length > 20
                              ? abbreviateAddress(wallet.name)
                              : wallet.name}
                          </span>
                        </StyledTableCell>
                        <StyledTableCell component="th" scope="row">
                          <a
                            href={`https://explorer.solana.com/address/${
                              wallet.walletTokenAddress
                            }${convertNetworkNameToExplorerPath(
                              wallet.network
                            )}`}
                            target="_blank"
                            rel="noopener noreferrer"
                            variant="primary"
                          >
                            {wallet.symbol
                              ? wallet.symbol.length > 10
                                ? abbreviateAddress(wallet.symbol, 5)
                                : wallet.symbol
                              : abbreviateAddress(wallet.walletTokenAddress, 5)}
                          </a>
                        </StyledTableCell>
                        <StyledTableCell
                          align="right"
                          component="th"
                          scope="row"
                        >
                          <span
                            style={{ fontSize: "13px", marginRight: "10px" }}
                          >
                            {wallet.walletAddress}
                          </span>

                          <div>
                            <Button
                              variant="outlined"
                              className={classes.button}
                              onClick={() => {
                                window.open(
                                  `https://explorer.solana.com/address/${
                                    wallet.walletAddress
                                  }${convertNetworkNameToExplorerPath()}`
                                );
                              }}
                            >
                              View Explorer
                            </Button>
                            {wallet.isAdded && (
                              <>
                                <Button
                                  variant="outlined"
                                  color="secondary"
                                  className={classes.button}
                                  onClick={() => {
                                    handleReceiveToken(wallet);
                                  }}
                                >
                                  Receive
                                </Button>
                                <Button
                                  variant="outlined"
                                  color="secondary"
                                  className={classes.button}
                                  onClick={() => {
                                    handleSendToken(wallet);
                                  }}
                                >
                                  Send
                                </Button>
                              </>
                            )}
                          </div>
                        </StyledTableCell>
                        <StyledTableCell
                          align="right"
                          component="th"
                          scope="row"
                        >
                          <div style={{ marginRight: "10px" }}>
                            <span
                              style={{ fontSize: "18px", fontWeight: "bold" }}
                            >
                              {numberWithCommasNormal(wallet.walletBalance)}
                            </span>
                            {wallet.symbol && wallet.symbol.length < 10 ? (
                              <span style={{ fontSize: "10px" }}>
                                {wallet.symbol}
                              </span>
                            ) : null}
                          </div>
                        </StyledTableCell>
                        <StyledTableCell component="th" scope="row">
                          <Button
                            disabled={wallet.isAdded}
                            onClick={() => {
                              handleAddWallet(wallet);
                            }}
                          >
                            {wallet.isAdded ? "Added" : "Add"}
                          </Button>
                        </StyledTableCell>
                      </TableRow>
                    );
                  })}
                </TableBody>
              </Table>
            </Paper>
          )}
        </Modal.Body>
        <Modal.Footer>
          <Button
            disabled={isReloadLoading}
            variant="text"
            onClick={() => {
              reloadChildTokenWallets();
            }}
          >
            Refresh
          </Button>
          <Button
            variant="text"
            onClick={() => {
              setMySPLWalletDetailModalShow(true);
            }}
          >
            SOL Wallet Info
          </Button>
          <Button
            variant="text"
            onClick={() => {
              setMySPLChildTokenWalletListModal(false);
            }}
          >
            Close
          </Button>
        </Modal.Footer>
      </Modal>

      {inputChildTokenWalletInfoModal && selectAddWallet && (
        <InputChildTokenWalletInfoModal
          authToken={authToken}
          ownerPublicKey={ownerPublicKey}
          wallet={selectAddWallet}
          reloadChildTokenWallets={reloadChildTokenWallets}
          setInputChildTokenWalletInfoModal={setInputChildTokenWalletInfoModal}
        />
      )}
      {mySPLChildTokenWalletDetailModalShow && selectChildToken && (
        <MySPLWalletDetailModal
          authToken={authToken}
          selectSplWallet={selectChildToken}
          getSplWallets={reloadChildTokenWallets}
          setMySPLWalletDetailModalShow={
            setMySPLChildTokenWalletDetailModalShow
          }
        />
      )}
    </>
  );
}
