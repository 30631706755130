import React, { useEffect, useState } from "react";
import { Modal, Button, Spinner } from "react-bootstrap";
import { toast } from "react-toastify";
import { requestEmailTwoFactoreAuthCode } from "../../../client/TwoFactorAuthClient";

/**
 *
 * @param authType 인증타입 TOKEN_TRANSFER_TRANSACTION, DEPOSIT_TRANSFER_TRANSACTION, CHANGE_TOKEN_PERMISSIONS
 * @param requestBody {
 *       fromWallet,
 *       toWallet,
 *       amount,
 *       authType,
 * }
 * @returns
 */
export default function EmailTwoFactoreConfirmModal({
  title,
  message,
  authToken,
  authType,
  requestBody,
  confirmAction,
  cancelAction
}) {
  const [isLoading, setIsLoading] = useState(true);
  const [expiredDateTime, setExpiredDateTime] = useState(null);
  const [inputAuthCode, setInputAuthCode] = useState(null);

  // 호출되는 순간 즉시 인증메일 요청( 소유자 이메일로 바로 메일이 가도록 하기 위해 전송버튼을 따로 두지 않음 )
  useEffect(() => {
    requestEmailAuthCode();
  }, []); // eslint-disable-line react-hooks/exhaustive-deps

  // 이메일 인증코드 요청
  async function requestEmailAuthCode() {
    try {
      setIsLoading(true);
      const response = await requestEmailTwoFactoreAuthCode({
        authToken,
        body: {
          fromWallet: requestBody.from,
          toWallet: requestBody.to,
          amount:
            authType !== "CHANGE_TOKEN_PERMISSIONS" ? requestBody.amount : 0,
          tokenMint:
            authType === "CHANGE_TOKEN_PERMISSIONS"
              ? requestBody.tokenMint
              : null,
          authType
        }
      });
      if (response) {
        setExpiredDateTime(response.expiredDateTime);
        setIsLoading(false);
      } else {
        toast.error(`Failed to send verification code, please try again.`, {
          position: "top-right",
          autoClose: 2000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true
        });
        cancelAction();
      }
    } catch (e) {
      toast.error(`Failed to send verification code, please try again.`, {
        position: "top-right",
        autoClose: 2000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true
      });
      setIsLoading(false);
      cancelAction();
    }
  }

  return (
    <Modal show={true} aria-labelledby="contained-modal-title-vcenter" centered>
      <Modal.Header closeButton>
        <Modal.Title id="contained-modal-title-vcenter">{title}</Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <div>
          <p>{message}</p>
        </div>
        <div>
          {isLoading && !expiredDateTime ? (
            <div style={{ textAlign: "center", marginTop: "15px" }}>
              <Spinner animation="grow" />
            </div>
          ) : (
            <form style={{ marginTop: "30px" }}>
              {" "}
              <div className="form-group row">
                <div className="col-lg-12 col-xl-12">
                  <input
                    required
                    type="number"
                    placeholder="Enter the verification code sent to you by email"
                    className={`form-control form-control-lg form-control-solid`}
                    name="authCode"
                    onChange={e => {
                      setInputAuthCode(e.target.value);
                    }}
                  />
                  <span style={{ fontSize: "13px", color: "red" }}>
                    Please enter within <b>10 minutes</b>
                  </span>
                </div>
              </div>
            </form>
          )}
        </div>
      </Modal.Body>
      <Modal.Footer>
        <Button
          variant="primary"
          onClick={() => {
            if (!inputAuthCode) {
              toast.error(`Please enter verification code`, {
                position: "top-right",
                autoClose: 2000,
                hideProgressBar: false,
                closeOnClick: true,
                pauseOnHover: true,
                draggable: true
              });
              return;
            }
            confirmAction(inputAuthCode);
          }}
        >
          Confirm
        </Button>
        <Button
          variant="secondary"
          onClick={() => {
            cancelAction();
          }}
        >
          Close
        </Button>
      </Modal.Footer>
    </Modal>
  );
}
