import React from "react";
import { Alert, Button } from "react-bootstrap";

export default function DepositWalletFAQ({ createWallet }) {
  return (
    <Alert show={true} variant="info">
      <Alert.Heading>Deposit wallet information</Alert.Heading>
      <p>
        <br />
        <b>What is a deposit wallet?</b>
        <br />
        Keep your SOL or DXL tokens in your deposit wallet. Everything done in
        MintingLab <br />
        Fees for transaction operations are paid from the deposit wallet
        <br />
        <br />
        <b>What are the benefits of using a deposit wallet?</b>
        <br />
        If you have multiple tokens and wallets, there is no need to put a fee
        for each wallet.
        <br />
        Also, with DXL, there is a discount on fees based on the deposit.({" "}
        <a
          style={{ color: "#FFF", fontWeight: "bold" }}
          href="https://docs.dexlab.space/products/minting-lab/mintinglab-tutorial/fee-discount-program"
          target="_blank"
          rel="noopener noreferrer"
        >
          Fee Discount Program
        </a>{" "}
        )
        <br />
        <br />
      </p>
      <hr />
      <div className="d-flex justify-content-end">
        <Button
          style={{ color: "#FFF", borderColor: "#FFF" }}
          onClick={() => {
            createWallet();
          }}
          variant="outline-success"
        >
          Create deposit wallet
        </Button>
      </div>
    </Alert>
  );
}
