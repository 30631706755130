import React from "react";
import { Alert } from "react-bootstrap";

export const EmptyPage = () => {
  return (
    <Alert variant="info">
      <Alert.Heading>Coming Soon</Alert.Heading>
      <p>
        This feature will become available soon.
        <br />
        Please check back for updates and news from the Dexlab team.
        <br />
        <br />
        Thank you.
        <br />
      </p>
    </Alert>
  );
};
