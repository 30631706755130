/* eslint-disable jsx-a11y/role-supports-aria-props */
/* eslint-disable no-script-url,jsx-a11y/anchor-is-valid */
import React, { useEffect, useState } from "react";
import { useLocation } from "react-router";
import { NavLink } from "react-router-dom";
import SVG from "react-inlinesvg";
// import { useInterval } from "react-use";
import { useSelector, shallowEqual, connect } from "react-redux";
import { toAbsoluteUrl, checkIsActive } from "../../../../_helpers";
// import { healthCheck } from "../../../../../client/WalletManagerClient";
import * as auth from "../../../../../app/modules/Auth";
import { Steps } from "intro.js-react";

const depositGuideSteps = [
  {
    element: ".start",
    intro: (
      <>
        <b>Welcome to Minting Lab.</b>
        <br />
        Shall we briefly learn about Minting Lab?
      </>
    ),
    position: "right"
  },
  {
    element: ".token-menu",
    intro: "You can create and manage tokens.",
    position: "right"
  },
  {
    element: ".deposit-menu",
    intro: "Manage the wallet to be used for transaction fees.",
    position: "right"
  },
  {
    element: ".wallet-menu",
    intro:
      "You can create and import SPL wallets. Provides functionality similar to sollet.",
    position: "right"
  }
];

function AsideMenuList({ layoutProps }) {
  const location = useLocation();
  const user = useSelector(state => state.auth.user, shallowEqual);
  useEffect(() => {}, [user]);

  // const [isApiHealthGood, setIsApiHealthGood] = useState(true);
  const getMenuItemActive = (url, hasSubmenu = false) => {
    return checkIsActive(location, url)
      ? ` ${!hasSubmenu &&
          "menu-item-active"} menu-item-open menu-item-not-hightlighted`
      : "";
  };

  // API 상태체크(5분 단위)
  // useInterval(() => {
  //   apiHealthCheck();
  // }, 300000);

  // async function apiHealthCheck() {
  //   try {
  //     await healthCheck();
  //     setIsApiHealthGood(true);
  //   } catch (e) {
  //     console.log(e);
  //     setIsApiHealthGood(false);
  //   }
  // }
  let tutorialStatus = false;
  if (
    !localStorage.getItem(`MAIN_TUTORIAL`) ||
    localStorage.getItem(`MAIN_TUTORIAL`) === "N"
  ) {
    tutorialStatus = true;
  }
  const [stepsEnabled, setStepsEnabled] = useState(tutorialStatus);
  const handleOnDepositGuideExit = () => {
    localStorage.setItem(`MAIN_TUTORIAL`, "Y");
    setStepsEnabled(false);
  };

  return (
    <>
      <Steps
        enabled={stepsEnabled}
        steps={depositGuideSteps}
        initialStep={0}
        onExit={handleOnDepositGuideExit}
      />
      {/* begin::Menu Nav */}
      <ul className={`menu-nav ${layoutProps.ulClasses}`}>
        {/*begin::1 Level*/}
        <li
          className={`menu-item ${getMenuItemActive("/dashboard", false)}`}
          aria-haspopup="true"
        >
          <NavLink className="menu-link" to="/dashboard">
            <span className="svg-icon menu-icon">
              <SVG src={toAbsoluteUrl("/media/svg/icons/Design/Layers.svg")} />
            </span>
            <span className="menu-text">Dashboard</span>
          </NavLink>
        </li>
        {/*end::1 Level*/}

        {/*begin::1 Level*/}
        <li
          className={`menu-item ${getMenuItemActive("/analytics", false)}`}
          aria-haspopup="true"
        >
          <NavLink className="menu-link" to="/analytics">
            <span className="svg-icon menu-icon">
              <SVG src={toAbsoluteUrl("/media/svg/icons/Home/Library.svg")} />
            </span>
            <span className="menu-text">Analytics</span>
          </NavLink>
        </li>
        {/*end::1 Level*/}

        {/* Components */}
        {/* begin::section */}
        <li className="menu-section ">
          <h4 className="menu-text">MANAGEMENT</h4>
          <i className="menu-icon flaticon-more-v2"></i>
        </li>
        {/* end:: section */}

        {/* Tokens */}
        <li
          className={`menu-item menu-item-submenu ${getMenuItemActive(
            "/management/token",
            true
          )} token-menu`}
          aria-haspopup="true"
          data-menu-toggle="hover"
        >
          <NavLink className="menu-link" to="/management/token">
            <span className="svg-icon menu-icon">
              <SVG src={toAbsoluteUrl("/media/svg/icons/Shopping/Box2.svg")} />
            </span>
            <span className="menu-text">Token</span>
            <i className="menu-arrow" />
          </NavLink>

          <div className="menu-submenu">
            <i className="menu-arrow" />
            <ul className="menu-subnav">
              <li
                className={`menu-item ${getMenuItemActive(
                  "/management/token/list"
                )}`}
                aria-haspopup="true"
              >
                <NavLink className="menu-link" to="/management/token/list">
                  <i className="menu-bullet menu-bullet-dot">
                    <span />
                  </i>
                  <span className="menu-text">My Token List</span>
                </NavLink>
              </li>
              {/* <li
                className={`menu-item ${getMenuItemActive(
                  "/management/token/vesting"
                )}`}
                aria-haspopup="true"
              >
                <NavLink className="menu-link" to="/management/token/vesting">
                  <i className="menu-bullet menu-bullet-dot">
                    <span />
                  </i>
                  <span className="menu-text">Token Vesting</span>
                </NavLink>
              </li> */}
              <li
                className={`menu-item ${getMenuItemActive(
                  "/management/token/stake"
                )}`}
                aria-haspopup="true"
              >
                <NavLink className="menu-link" to="/management/token/stake">
                  <i className="menu-bullet menu-bullet-dot">
                    <span />
                  </i>
                  <span className="menu-text">Token Staking</span>
                </NavLink>
              </li>
              <li
                className={`menu-item ${getMenuItemActive(
                  "/management/token/solana"
                )}`}
                aria-haspopup="true"
              >
                <NavLink className="menu-link" to="/management/token/solana">
                  <i className="menu-bullet menu-bullet-dot">
                    <span />
                  </i>
                  <span className="menu-text">Solana Tokens</span>
                </NavLink>
              </li>
            </ul>
          </div>
        </li>

        {/* Deposit */}
        <li
          className={`menu-item menu-item-submenu ${getMenuItemActive(
            "/management/deposit-wallets",
            true
          )} deposit-menu`}
          aria-haspopup="true"
          data-menu-toggle="hover"
        >
          <NavLink
            className="menu-link menu-toggle"
            to="/management/deposit-wallets"
          >
            <span className="svg-icon menu-icon">
              <SVG src={toAbsoluteUrl("/media/svg/icons/Design/Bucket.svg")} />
            </span>
            <span className="menu-text">Deposit Wallet</span>
          </NavLink>
        </li>

        {/* Wallets */}
        <li
          className={`menu-item menu-item-submenu ${getMenuItemActive(
            "/management/spl-wallets",
            true
          )} wallet-menu`}
          aria-haspopup="true"
          data-menu-toggle="hover"
        >
          <NavLink
            className="menu-link menu-toggle"
            to="/management/spl-wallets"
          >
            <span className="svg-icon menu-icon">
              <SVG src={toAbsoluteUrl("/media/svg/icons/Design/Cap-2.svg")} />
            </span>
            <span className="menu-text">SPL Wallet</span>
          </NavLink>
        </li>

        {/* Applications */}
        {/* begin::section */}
        <li className="menu-section ">
          <h4 className="menu-text">Applications</h4>
          <i className="menu-icon flaticon-more-v2"></i>
        </li>
        {/* end:: section */}

        {/* Market Listing */}
        <li
          className={`menu-item menu-item-submenu ${getMenuItemActive(
            "/applications/market",
            true
          )}`}
          aria-haspopup="true"
          data-menu-toggle="hover"
        >
          <NavLink className="menu-link" to="/applications/market">
            <span className="svg-icon menu-icon">
              <SVG src={toAbsoluteUrl("/media/svg/icons/Shopping/Bag2.svg")} />
            </span>
            <span className="menu-text">Markets</span>
            <i className="menu-arrow" />
          </NavLink>
          <div className="menu-submenu">
            <i className="menu-arrow" />
            <ul className="menu-subnav">
              <li
                className={`menu-item ${getMenuItemActive(
                  "/applications/market/list"
                )}`}
                aria-haspopup="true"
              >
                <NavLink className="menu-link" to="/applications/market/list">
                  <i className="menu-bullet menu-bullet-dot">
                    <span />
                  </i>
                  <span className="menu-text">My Market List</span>
                </NavLink>
              </li>
              <li
                className={`menu-item ${getMenuItemActive(
                  "/applications/market/new"
                )}`}
                aria-haspopup="true"
              >
                <NavLink className="menu-link" to="/applications/market/new">
                  <i className="menu-bullet menu-bullet-dot">
                    <span />
                  </i>
                  <span className="menu-text">New Listing</span>
                </NavLink>
              </li>
            </ul>
          </div>
        </li>

        {/* IDO */}
        {/* <li
          className={`menu-item ${getMenuItemActive(
            "/applications/ido",
            false
          )}`}
          aria-haspopup="true"
        >
          <NavLink className="menu-link" to="/applications/ido">
            <span className="svg-icon menu-icon">
              <SVG
                src={toAbsoluteUrl(
                  "/media/svg/icons/Communication/Add-user.svg"
                )}
              />
            </span>
            <span className="menu-text">IDO</span>
            <i className="menu-arrow" />
          </NavLink>
          <div className="menu-submenu">
            <i className="menu-arrow" />
            <ul className="menu-subnav">
              <li
                className={`menu-item ${getMenuItemActive(
                  "/applications/ido/white-list"
                )}`}
                aria-haspopup="true"
              >
                <NavLink
                  className="menu-link"
                  to="/applications/ido/white-list"
                >
                  <i className="menu-bullet menu-bullet-dot">
                    <span />
                  </i>
                  <span className="menu-text">Whitelist Management</span>
                </NavLink>
              </li>
              <li
                className={`menu-item ${getMenuItemActive(
                  "/applications/ido/kyc"
                )}`}
                aria-haspopup="true"
              >
                <NavLink className="menu-link" to="/applications/ido/kyc">
                  <i className="menu-bullet menu-bullet-dot">
                    <span />
                  </i>
                  <span className="menu-text">KYC Management</span>
                </NavLink>
              </li>
            </ul>
          </div>
        </li> */}

        {/* account security */}
        {/* begin::section */}
        <li className="menu-section ">
          <h4 className="menu-text">SETTINGS</h4>
          <i className="menu-icon flaticon-more-v2"></i>
        </li>

        <li
          className={`menu-item menu-item-submenu ${getMenuItemActive(
            "/user-profile/api-management",
            true
          )}`}
          aria-haspopup="true"
          data-menu-toggle="hover"
        >
          <NavLink
            className="menu-link menu-toggle"
            to="/user-profile/api-management"
          >
            <span className="svg-icon menu-icon">
              <SVG src={toAbsoluteUrl("/media/svg/icons/Code/Terminal.svg")} />
            </span>
            <span className="menu-text">API Management</span>
          </NavLink>
        </li>
        <li
          className={`menu-item menu-item-submenu ${getMenuItemActive(
            "/user-profile/2fa-settings",
            true
          )}`}
          aria-haspopup="true"
          data-menu-toggle="hover"
        >
          <NavLink
            className="menu-link menu-toggle"
            to="/user-profile/2fa-settings"
          >
            <span className="svg-icon menu-icon">
              <SVG
                src={toAbsoluteUrl(
                  "/media/svg/icons/Communication/Shield-user.svg"
                )}
              />
            </span>
            <span className="menu-text">Account security</span>
          </NavLink>
        </li>

        {user && user.isGovernanceVoter && (
          <>
            {" "}
            <li className="menu-section ">
              <h4 className="menu-text">GOVERNANCE MANAGER</h4>
              <i className="menu-icon flaticon-more-v2"></i>
            </li>
            <li
              className={`menu-item menu-item-submenu ${getMenuItemActive(
                "/governance/apply/list",
                true
              )}`}
              aria-haspopup="true"
              data-menu-toggle="hover"
            >
              <NavLink
                className="menu-link menu-toggle"
                to="/governance/apply/list"
              >
                <span className="svg-icon menu-icon">
                  <SVG
                    src={toAbsoluteUrl(
                      "/media/svg/icons/Communication/Shield-user.svg"
                    )}
                  />
                </span>
                <span className="menu-text">Apply Applications</span>
              </NavLink>
            </li>
          </>
        )}

        {user && user.userLevel === 99 && (
          <>
            {" "}
            <li className="menu-section ">
              <h4 className="menu-text">DEXLAB ADMIN SYSTEM</h4>
              <i className="menu-icon flaticon-more-v2"></i>
            </li>
            <li
              className={`menu-item menu-item-submenu ${getMenuItemActive(
                "/admin/dashboard",
                true
              )}`}
              aria-haspopup="true"
              data-menu-toggle="hover"
            >
              <NavLink className="menu-link menu-toggle" to="/admin/dashboard">
                <span className="menu-text">Dashboard</span>
              </NavLink>
            </li>
            <li
              className={`menu-item menu-item-submenu ${getMenuItemActive(
                "/admin/governance/apply/list",
                true
              )}`}
              aria-haspopup="true"
              data-menu-toggle="hover"
            >
              <NavLink
                className="menu-link menu-toggle"
                to="/admin/governance/apply/list"
              >
                <span className="menu-text">Governance</span>
              </NavLink>
            </li>
            <li
              className={`menu-item menu-item-submenu ${getMenuItemActive(
                "/admin/users",
                true
              )}`}
              aria-haspopup="true"
              data-menu-toggle="hover"
            >
              <NavLink className="menu-link menu-toggle" to="/admin/users">
                <span className="menu-text">Users</span>
              </NavLink>
            </li>
            <li
              className={`menu-item menu-item-submenu ${getMenuItemActive(
                "/admin/tokens",
                true
              )}`}
              aria-haspopup="true"
              data-menu-toggle="hover"
            >
              <NavLink className="menu-link menu-toggle" to="/admin/tokens">
                <span className="menu-text">Tokens</span>
              </NavLink>
            </li>
          </>
        )}
      </ul>

      {/* end::Menu Nav */}
      {/* <div style={{ position: "absolute", bottom: "10px", left: "10px" }}>
        <a
          style={{ color: "#646464" }}
          href="https://stats.uptimerobot.com/8OZwzIjnn1"
          target="_blank"
          rel="noopener noreferrer"
        >
          Service Status:{" "}
          <span style={{ color: isApiHealthGood ? "#1DBC60" : "#FF4040" }}>
            {isApiHealthGood ? "Operational" : "Instability"}
          </span>
        </a>
      </div> */}
    </>
  );
}

export default connect(null, auth.actions)(AsideMenuList);
