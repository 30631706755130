import React, { useState } from "react";
import { Link } from "react-router-dom";
import _ from "lodash";
import { AiOutlineInbox } from "react-icons/ai";
import { makeStyles, withStyles } from "@material-ui/core/styles";
import {
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
  Paper,
  Button
} from "@material-ui/core";
import { toast } from "react-toastify";
import { Badge, Spinner } from "react-bootstrap";
import copy from "clipboard-copy";
import {
  convertNetworkNameToExplorerPath,
  abbreviateAddress,
  numberWithCommasNormal,
  SOL_MINT
} from "../../../../app/utils/util";
import MySPLWalletSendTokenModal from "./MySPLWalletSendTokenModal";
import MySPLWalletReceiveTokenModal from "./MySPLWalletReceiveTokenModal";
import MySPLChildTokenWalletListModal from "./MySPLChildTokenWalletListModal";
import MySPLWalletDetailModal from "./MySPLWalletDetailModal";

import { fetchMyChildTokenWallets } from "../../../../client/WalletManagerClient";

const StyledTableCell = withStyles(theme => ({
  head: {
    backgroundColor: "#8950FC",
    color: theme.palette.common.white
  },
  body: {
    fontSize: 14
  }
}))(TableCell);

const useStyles = makeStyles(theme => ({
  root: {
    width: "100%",
    marginTop: theme.spacing(3),
    overflowX: "auto"
  },
  table: {
    minWidth: 650
  },
  avatar: {
    margin: 10
  },
  bigAvatar: {
    width: 60,
    height: 60
  },
  button: {
    margin: theme.spacing(1)
  },
  input: {
    display: "none"
  }
}));

export default function MySPLWalletList({
  authToken,
  mySplWallets,
  isWalletBalanceLoading,
  isBalanceError,
  getSplWallets
}) {
  const classes = useStyles();
  const [sendTokenModalShow, setSendTokenModalShow] = useState(false);
  const [
    receiveAddressInfoModalShow,
    setReceiveAddressInfoModalShow
  ] = useState(false);
  const [
    mySPLChildTokenWalletListModal,
    setMySPLChildTokenWalletListModal
  ] = useState(false);
  const [selectToken, setSelectToken] = useState(undefined);
  const [mySPLWalletDetailModalShow, setMySPLWalletDetailModalShow] = useState(
    false
  );

  const [isChildTokenWalletLoding, setIsChildTokenWalletLoding] = useState(
    false
  );
  const [isReloadLoading, setIsReloadLoading] = useState(false);
  const [childTokenWallets, setChildTokenWallets] = useState([]);

  const handleSendToken = token => {
    setSelectToken(token);
    setSendTokenModalShow(true);
  };

  const handleReceiveToken = token => {
    setSelectToken(token);
    setReceiveAddressInfoModalShow(true);
  };

  const copyWalletAddress = walletAddress => {
    copy(walletAddress).then(() => {
      toast.info(`Copy success.`, {
        position: "top-right",
        autoClose: 2000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true
      });
    });
  };

  async function getSubTokenWallets(wallet, isLoading = true) {
    if (wallet.walletTokenAddress !== SOL_MINT) {
      setSelectToken(wallet);
      setMySPLWalletDetailModalShow(true);
      return;
    }
    setSelectToken(wallet);
    setMySPLChildTokenWalletListModal(true);

    if (isLoading) {
      setIsChildTokenWalletLoding(true);
    } else {
      setIsReloadLoading(true);
    }
    try {
      const response = await fetchMyChildTokenWallets({
        authToken,
        ownerPublicKey: wallet.ownerPublicKey
      });
      setChildTokenWallets(response);
      setIsChildTokenWalletLoding(false);
      setIsReloadLoading(false);
    } catch (e) {
      console.log(e);
      setIsChildTokenWalletLoding(false);
      setIsReloadLoading(false);
    }
  }

  async function reloadChildTokenWallets() {
    getSubTokenWallets(selectToken, false);
  }

  return (
    <Paper className={classes.root}>
      <Table className={classes.table}>
        <TableHead>
          <TableRow>
            <StyledTableCell style={{ wwordBreak: "break-all" }}>
              Name
            </StyledTableCell>
            <StyledTableCell align="right">Mint</StyledTableCell>
            <StyledTableCell style={{ wordBreak: "break-all" }} align="right">
              Wallet Address
            </StyledTableCell>
            <StyledTableCell align="right">Balance</StyledTableCell>
            <StyledTableCell align="right">Memo</StyledTableCell>
          </TableRow>
        </TableHead>
        <TableBody>
          {_.sortBy(mySplWallets, ["isTokenOwner", "walletBalance"])
            .reverse()
            .map((wallet, idx) => (
              <TableRow key={idx}>
                <StyledTableCell component="th" scope="row">
                  <span
                    style={{ cursor: "pointer" }}
                    onClick={() => {
                      getSubTokenWallets(wallet);
                    }}
                  >
                    {wallet.name.length > 20
                      ? abbreviateAddress(wallet.name, 10)
                      : wallet.name}
                    {wallet.walletTokenAddress === SOL_MINT ? (
                      <AiOutlineInbox style={{ paddingBottom: "2px" }} />
                    ) : null}
                  </span>
                </StyledTableCell>
                <StyledTableCell align="center">
                  {wallet.isTokenOwner ? (
                    <div style={{ fontSize: "10px" }}>
                      <Badge pill variant="info">
                        Owner
                      </Badge>
                    </div>
                  ) : (
                    ""
                  )}

                  {wallet.isTokenOwner ? (
                    <Link
                      to={`/management/token/${wallet.walletTokenAddress}/detail`}
                    >
                      {wallet.symbol
                        ? wallet.symbol
                        : abbreviateAddress(wallet.walletTokenAddress, 6)}{" "}
                    </Link>
                  ) : (
                    <a
                      href={`https://explorer.solana.com/address/${
                        wallet.walletTokenAddress
                      }${convertNetworkNameToExplorerPath()}`}
                      target="_blank"
                      rel="noopener noreferrer"
                    >
                      {wallet.symbol
                        ? wallet.symbol
                        : abbreviateAddress(wallet.walletTokenAddress, 6)}{" "}
                    </a>
                  )}
                </StyledTableCell>
                <StyledTableCell align="right">
                  <div>
                    <span
                      style={{
                        width: "30px",
                        wordBreak: "break-all",
                        cursor: "pointer"
                      }}
                      onClick={() => {
                        copyWalletAddress(wallet.walletAddress);
                      }}
                    >
                      {wallet.enable ? (
                        wallet.walletAddress
                      ) : (
                        <del>{wallet.walletAddress}</del>
                      )}
                    </span>
                  </div>
                  <div>
                    <Button
                      variant="outlined"
                      className={classes.button}
                      onClick={() => {
                        window.open(
                          `https://explorer.solana.com/address/${
                            wallet.walletAddress
                          }${convertNetworkNameToExplorerPath()}`
                        );
                      }}
                    >
                      View Explorer
                    </Button>
                    <Button
                      variant="outlined"
                      color="secondary"
                      className={classes.button}
                      onClick={() => {
                        handleReceiveToken(wallet);
                      }}
                    >
                      Receive
                    </Button>
                    <Button
                      variant="outlined"
                      color="secondary"
                      className={classes.button}
                      onClick={() => {
                        handleSendToken(wallet);
                      }}
                    >
                      Send
                    </Button>
                  </div>
                </StyledTableCell>
                <StyledTableCell align="right">
                  {isWalletBalanceLoading ? (
                    <Spinner animation="grow" />
                  ) : (
                    <>
                      <span style={{ fontSize: "20px", fontWeight: "bold" }}>
                        {!isBalanceError
                          ? numberWithCommasNormal(
                              parseFloat(wallet.walletBalance)
                            )
                          : "-"}
                      </span>{" "}
                      <span style={{ fontSize: "10px" }}>{wallet.symbol}</span>
                    </>
                  )}
                </StyledTableCell>
                <StyledTableCell
                  style={{ maxWidth: "200px", wordBreak: "break-all" }}
                  align="right"
                >
                  {wallet.memo}
                </StyledTableCell>
              </TableRow>
            ))}
        </TableBody>
      </Table>
      {authToken && sendTokenModalShow && selectToken && (
        <MySPLWalletSendTokenModal
          authToken={authToken}
          sendTokenWallet={selectToken}
          sendTokenModalShow={sendTokenModalShow}
          getSplWallets={getSplWallets}
          setSendTokenModalShow={setSendTokenModalShow}
        />
      )}
      {receiveAddressInfoModalShow && selectToken && (
        <MySPLWalletReceiveTokenModal
          receiveWalletInfo={selectToken}
          receiveAddressInfoModalShow={receiveAddressInfoModalShow}
          setReceiveAddressInfoModalShow={setReceiveAddressInfoModalShow}
        />
      )}
      {mySPLChildTokenWalletListModal && (
        <MySPLChildTokenWalletListModal
          authToken={authToken}
          ownerPublicKey={selectToken.ownerPublicKey}
          selectToken={selectToken}
          childTokenWallets={childTokenWallets}
          reloadChildTokenWallets={reloadChildTokenWallets}
          isChildTokenWalletLoding={isChildTokenWalletLoding}
          isReloadLoading={isReloadLoading}
          handleReceiveToken={handleReceiveToken}
          handleSendToken={handleSendToken}
          setSelectToken={setSelectToken}
          setMySPLChildTokenWalletListModal={setMySPLChildTokenWalletListModal}
          setMySPLWalletDetailModalShow={setMySPLWalletDetailModalShow}
        />
      )}
      {mySPLWalletDetailModalShow && selectToken && (
        <MySPLWalletDetailModal
          authToken={authToken}
          selectSplWallet={selectToken}
          getSplWallets={getSplWallets}
          setMySPLWalletDetailModalShow={setMySPLWalletDetailModalShow}
        />
      )}
    </Paper>
  );
}
