import { Connection, PublicKey } from "@solana/web3.js";
import { getSolanaNetworkClusterHost } from "../app/utils/util";

export async function tokenAccountInfo(mint: string) {
  const connection = new Connection(getSolanaNetworkClusterHost(), "confirmed");
  const tokenAccountInfo: any = await connection.getParsedAccountInfo(
    new PublicKey(mint)
  );
  if (tokenAccountInfo) {
    let totalSupply =
      Number(tokenAccountInfo.value.data.parsed.info.supply) /
      10 ** Number(tokenAccountInfo.value.data.parsed.info.decimals);
    return {
      mint,
      supply: totalSupply,
      decimals: tokenAccountInfo.value.data.parsed.info.decimals,
      mintAuthority:
        tokenAccountInfo.value.data.parsed.info.mintAuthority ?? "Fixed Supply",
      isFixedSupplyToken: tokenAccountInfo.value.data.parsed.info.mintAuthority
        ? false
        : true
    };
  }
  return undefined;
}
