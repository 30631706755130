import React from "react";
import { connect } from "react-redux";
import { GovernanceApplyManager } from "../../_dexlab/_partials";

const GovernanceApplyManagerPage = props => {
  return <GovernanceApplyManager props={props} />;
};

const mapStateToProps = auth => ({ auth: auth });
export default connect(mapStateToProps)(GovernanceApplyManagerPage);
