import React, { useState } from "react";
import _ from "lodash";
import * as Yup from "yup";
import { useFormik } from "formik";
import { Spinner, Button, Modal, Form } from "react-bootstrap";
import { toast } from "react-toastify";
import ConfirmModal from "../../common/ConfirmModal";
import { burnToken as requestBurnToken } from "../../../../client/TokenManagerClient";
import { numberWithCommasNormal } from "../../../../app/utils/util";

export default function TokenBurnModal({
  authToken,
  tokenInfo,
  tokenWallets,
  setBurnTokenModalShow,
  getTokenWallets
}) {
  const [isTokenBurnLoading, setIsTokenBurnLoading] = useState(false);
  const [burnTokenConfirm, setBurnTokenConfirm] = useState(false);
  const [burnValues, setBurnValues] = useState(null);

  async function burnToken(body) {
    try {
      setIsTokenBurnLoading(true);
      const response = await requestBurnToken({
        authToken,
        mint: tokenInfo.tokenMint,
        body
      });
      if (response) {
        toast.info(
          `${numberWithCommasNormal(response.amount)}${
            tokenInfo.symbol
          } tokens have been burned.`,
          {
            position: "top-right",
            autoClose: 3500,
            hideProgressBar: false,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true
          }
        );
        getTokenWallets();
        setIsTokenBurnLoading(false);
        setBurnTokenModalShow(false);
      } else {
        toast.error(`There is a temporary error. please try again`, {
          position: "top-right",
          autoClose: 3000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true
        });
        setIsTokenBurnLoading(false);
      }
    } catch (e) {
      if (e.response) {
        if (e.response.status === 401) {
          toast.error(`The session has expired. please log in again`, {
            position: "top-right",
            autoClose: 3000,
            hideProgressBar: false,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true
          });
          window.location.reload();
        } else {
          toast.error(e.response.data.errorMessage, {
            position: "top-right",
            autoClose: 3000,
            hideProgressBar: false,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true
          });
        }
      }
      setIsTokenBurnLoading(false);
    }
  }

  const Schema = Yup.object().shape({
    tokenAccountAddress: Yup.string().required(
      "Select Token wallet is required"
    ),
    burnAmount: Yup.string()
      .required("Burn Amount is required")
      .min(1, "Please enter between 1 ~")
  });

  const getInputClasses = fieldname => {
    if (formik.touched[fieldname] && formik.errors[fieldname]) {
      return "is-invalid";
    }

    if (formik.touched[fieldname] && !formik.errors[fieldname]) {
      return "is-valid";
    }

    return "";
  };

  const formik = useFormik({
    initialValues: {
      tokenAccountAddress: undefined,
      burnAmount: 0
    },
    validationSchema: Schema,
    onSubmit: (values, { setSubmitting }) => {
      if (!values.tokenAccountAddress) {
        toast.error(`Select wallet address`, {
          position: "top-right",
          autoClose: 2000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true
        });
        return;
      }
      if (parseFloat(values.burnAmount) <= 0) {
        toast.error(`Enter the burn amount`, {
          position: "top-right",
          autoClose: 2000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true
        });
        return;
      }

      if (!values.burnChecbox) {
        toast.error(`Have you checked all the information?`, {
          position: "top-right",
          autoClose: 2000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true
        });
        return;
      }

      setTimeout(() => {
        setSubmitting(false);
        setBurnValues(values);
        handleBurnTokenConfirm();
      }, 500);
    }
  });

  function getSelectWallet() {
    if (formik.values.tokenAccountAddress) {
      const findSelectWallet = tokenWallets.find(
        f => f.walletAddress === formik.values.tokenAccountAddress
      );
      return findSelectWallet;
    }
    return undefined;
  }

  const handleBurnTokenConfirm = () => {
    setBurnTokenConfirm(!burnTokenConfirm);
  };

  return (
    <>
      <Modal
        show={true}
        backdrop="static"
        keyboard={false}
        size="lg"
        aria-labelledby="contained-modal-title-vcenter"
        onHide={() => {
          setBurnTokenModalShow(false);
        }}
        centered
      >
        <Modal.Header closeButton>
          <Modal.Title id="contained-modal-title-vcenter">
            Token Burn
          </Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <Form onSubmit={formik.handleSubmit}>
            <Form.Group controlId="formSelectBurnTokenWallet">
              <Form.Label>
                <b>Select token wallet</b>
              </Form.Label>
              <div>
                <select
                  required
                  className={`form-control form-control-lg form-control-solid ${getInputClasses(
                    "tokenAccountAddress"
                  )}`}
                  name="tokenAccountAddress"
                  {...formik.getFieldProps("tokenAccountAddress")}
                >
                  {!_.isEmpty(tokenWallets) ? (
                    <>
                      <option>Select Wallet</option>
                      {tokenWallets.map(wallet => {
                        return (
                          <option
                            key={wallet.walletAddress}
                            value={wallet.walletAddress}
                          >
                            {wallet.name}({wallet.walletAddress})
                          </option>
                        );
                      })}
                    </>
                  ) : (
                    <option value={null}>Create a wallet first.</option>
                  )}
                </select>
                {formik.touched.tokenAccountAddress &&
                formik.errors.tokenAccountAddress ? (
                  <div className="invalid-feedback">
                    {formik.errors.tokenAccountAddress}
                  </div>
                ) : null}
                <div style={{ textAlign: "right", color: "#646464" }}>
                  <span
                    style={{ cursor: "pointer" }}
                    onClick={() => {
                      getTokenWallets();
                    }}
                  >
                    Reload
                  </span>
                </div>
              </div>
            </Form.Group>
            <Form.Group controlId="formBurnAmount">
              <Form.Label>
                <span style={{ color: "red" }}>* </span>Amount(
                {tokenInfo.symbol})
              </Form.Label>
              <Form.Control
                required
                disabled={!formik.values.tokenAccountAddress}
                name="formBurnAmount"
                type="number"
                step={0.000000001}
                placeholder="input burn amount"
                onChange={e => {
                  const wallet = getSelectWallet();
                  // 소각 가능수량검증
                  if (
                    wallet.walletBalance &&
                    parseFloat(wallet.walletBalance) <
                      parseFloat(e.target.value)
                  ) {
                    toast.error(
                      `Insufficient burn amount (maximum: ${numberWithCommasNormal(
                        wallet.walletBalance
                      )}${tokenInfo.symbol}`,
                      {
                        position: "top-right",
                        autoClose: 3000,
                        hideProgressBar: false,
                        closeOnClick: true,
                        pauseOnHover: true,
                        draggable: true
                      }
                    );
                    return;
                  }
                  formik.setFieldValue("burnAmount", e.target.value);
                }}
              />
              {formik.values.tokenAccountAddress && getSelectWallet() && (
                <div style={{ textAlign: "right" }}>
                  {`Balance: ${numberWithCommasNormal(
                    getSelectWallet().walletBalance
                  )}`}{" "}
                  <span style={{ fontSize: "9px", color: "#646464" }}>
                    {tokenInfo.symbol}
                  </span>
                </div>
              )}
            </Form.Group>
            <Form.Group controlId="formBasicChecbox">
              <Form.Check
                name="formBasicChecbox"
                type="checkbox"
                label="I have double checked token burn."
                s
                onChange={e => {
                  formik.setFieldValue("burnChecbox", e.target.checked);
                }}
              />
            </Form.Group>
            <Button
              disabled={isTokenBurnLoading}
              style={{ marginRight: "6px" }}
              variant="primary"
              type="submit"
            >
              {!isTokenBurnLoading ? (
                "Request Burn"
              ) : (
                <Spinner animation="border" />
              )}
            </Button>
            <Button
              disabled={isTokenBurnLoading}
              variant="secondary"
              onClick={() => {
                setBurnTokenModalShow(false);
              }}
            >
              Close
            </Button>
          </Form>
          {isTokenBurnLoading && (
            <div style={{ marginTop: "10px" }}>
              <span style={{ color: "#646464" }}>
                Transaction is being confirmed. Please wait...
              </span>
            </div>
          )}
        </Modal.Body>
      </Modal>

      <ConfirmModal
        showConfirm={burnValues && burnTokenConfirm}
        title={"Confirm Burn Token"}
        message={
          <>
            <div
              style={{ fontSize: "15px", color: "red", marginBottom: "10px" }}
            >
              Warning! This action is irreversible.
            </div>
            <p>
              Token burning.
              <br />
              Tokens once burned cannot be returned.
              <br />
              <br />
              Should I burn the tokens?
            </p>
          </>
        }
        confirmAction={() => {
          handleBurnTokenConfirm();
          burnToken(burnValues);
        }}
        cancelAction={handleBurnTokenConfirm}
      />
    </>
  );
}
