import React from "react";
import { useSubheader } from "../../../_dexlab/layout";
// import { fetchMyToken } from "../../../client/TokenManagerClient";

export function TokenExternalDetail({ props }) {
  const suhbeader = useSubheader();
  suhbeader.setTitle(`External Token Info`);
  // const {
  //   auth: { authToken }
  // } = props.auth;

  console.log(`MINT: ${props.match.params.mint}`);

  // const tokenMint = props.match.params.mint;
  // const [tokenInfo, setTokenInfo] = useState(null);

  // useEffect(() => {
  //   getTokenInfo();
  // }, []);

  // async function getTokenInfo() {
  //   try {
  //     const response = await fetchMyToken({ authToken, mint: tokenMint });
  //     console.log(response);
  //     if (response) {
  //       setTokenInfo(response);
  //     }
  //   } catch (e) {
  //     console.log(e);
  //   }
  // }

  return (
    <>
      <div>
        외부 제공용 페이지 제공에 대한 정보
        <br />
        코인게코, 코인마켓캡등 외부에 토큰정보를 제공해야 하는 경우 아래 페이지
        및 API를 제공하세요
        <br />
        <br />
        1. 토큰소개 공개용 페이지( URL )<br />
        2. 토큰 총 발행량 URL(API)
        <br />
        3. Dexlab이 제공하는 마켓정보 조회 API( 시세, 오더북 까지) 안내(
        mint주소가 입력되어져 있음 )<br />
        4. 토큰정보 제공 위젯(TBD) - 토큰명/심볼/홈페이지/SNS링크등이 포함된
        위젯 제공(페이지 삽입용)
      </div>
    </>
  );
}
