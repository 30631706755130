import React from "react";
import { Alert } from "react-bootstrap";

export default function MySPLWalletFAQ({ handleCreateWalletModal }) {
  return (
    <Alert show={true} variant="info">
      <Alert.Heading>SPL Wallet Management</Alert.Heading>
      <p>
        <br />
        <b>What is a wallet?</b>
        <br />
        You can create and manage Solana wallets.
        <br />
        A fully managed wallet service that allows you to register any SPL token
        wallet.
        <br />
        <br />
        Also, with DXL, there is a discount on fees based on the deposit.({" "}
        <a
          style={{ color: "#FFF", fontWeight: "bold" }}
          href="https://docs.dexlab.space/products/minting-lab/mintinglab-tutorial/fee-discount-program"
          target="_blank"
          rel="noopener noreferrer"
        >
          Fee Discount Program
        </a>{" "}
        )
        <br />
        <br />
      </p>
      <hr />
      {/* <div className="d-flex justify-content-end">
        <Button
          style={{ color: "#FFF", borderColor: "#FFF" }}
          onClick={handleCreateWalletModal}
          variant="outline-success"
        >
          Create First wallet
        </Button>
      </div> */}
    </Alert>
  );
}
