import axios from "axios";
import projectConfig from "../../../../application";

export const LOGIN_URL = `${projectConfig.API_BASE_URL}/v1/auth/login`;
export const REGISTER_URL = `${projectConfig.API_BASE_URL}/v1/auth/register`;
export const REQUEST_PASSWORD_URL = `${projectConfig.API_BASE_URL}/v1/auth/forgot-password`;
export const ME_URL = `${projectConfig.API_BASE_URL}/v1/auth/me`;

// 아래 2개 API는 redux에서 사용됨
export function register() {
  return axios.post(REGISTER_URL, {});
}

export function getUserByToken() {
  return axios.get(ME_URL);
}

// x-re-captcha-token
// 직접 호출용
const axiosClient = ({ authToken, reCaptchaToken, body }) =>
  axios.create({
    baseURL: `${projectConfig.API_BASE_URL}`,
    data: body ?? {},
    timeout: 6000,
    headers: {
      Authorization: `Bearer ${authToken}`,
      "x-re-captcha-token": reCaptchaToken ?? ""
    }
  });

export async function registerMintingLab({ authToken, reCaptchaToken }) {
  return (
    await axiosClient({ authToken, reCaptchaToken }).post("/v1/auth/register")
  ).data;
}

export async function getMe({ authToken }) {
  return (await axiosClient({ authToken }).get("/v1/auth/me")).data;
}

export async function requestPassword(email, reCaptchaToken) {
  return (
    await axiosClient({ reCaptchaToken, body: { email } }).post(
      "/v1/auth/forgot-password"
    )
  ).data;
}
