import React from "react";
import { Button, Modal } from "react-bootstrap";
import copy from "clipboard-copy";
import { toast } from "react-toastify";

export default function DepositAddressInfoModal({
  depositWallets,
  depositAddressInfoModalShow,
  setDepositAddressInfoModalShow
}) {
  const copyCode = text => {
    copy(text).then(() => {
      toast.info(`Copy complete`, {
        position: "top-right",
        autoClose: 1500,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true
      });
    });
  };

  const solAddress = depositWallets[0].ownerPublicKey;
  const dxlAddress = depositWallets[0].depositWalletAddress ?? "-";

  return (
    <Modal
      show={depositAddressInfoModalShow}
      size="lg"
      aria-labelledby="contained-modal-title-vcenter"
      onHide={() => {
        setDepositAddressInfoModalShow(false);
      }}
      centered
    >
      <Modal.Header closeButton>
        <Modal.Title id="contained-modal-title-vcenter">Deposit</Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <div style={{ marginBottom: "16px" }}>
          Please deposit tokens to the addresses below.
          <br />
        </div>
        <h4>SOL Deposit Address</h4>
        <p>
          <span
            className={`form-control form-control-solid h-auto py-5 px-6`}
            style={{ cursor: "pointer", color: "blue" }}
            onClick={() => {
              copyCode(solAddress);
            }}
          >
            {solAddress}
          </span>
        </p>
        <h4 style={{ marginTop: "35px" }}>DXL Deposit Address</h4>
        <p>
          <span
            className={`form-control form-control-solid h-auto py-5 px-6`}
            style={{ cursor: "pointer", color: "blue" }}
            onClick={() => {
              copyCode(dxlAddress);
            }}
          >
            {dxlAddress}
          </span>
        </p>
      </Modal.Body>
      <Modal.Footer>
        <Button
          onClick={() => {
            setDepositAddressInfoModalShow(false);
          }}
        >
          Close
        </Button>
      </Modal.Footer>
    </Modal>
  );
}
