import React, { useState, useEffect } from "react";
import {
  TokenListContainer,
  TokenListProvider
} from "@solana/spl-token-registry";
import { makeStyles } from "@material-ui/core/styles";
import Paper from "@material-ui/core/Paper";
import Table from "@material-ui/core/Table";
import TableBody from "@material-ui/core/TableBody";
import TableCell from "@material-ui/core/TableCell";
import TableContainer from "@material-ui/core/TableContainer";
import TableHead from "@material-ui/core/TableHead";
import TablePagination from "@material-ui/core/TablePagination";
import TableRow from "@material-ui/core/TableRow";
import Avatar from "@material-ui/core/Avatar";

interface Column {
  id: "name" | "symbol" | "address" | "logoURI";
  label: string;
  minWidth?: number;
  align?: "right";
  format?: (value: any) => any;
}

const columns: Column[] = [
  {
    id: "logoURI",
    label: "Logo",
    format: (value: string) => {
      return <Avatar src={value} />;
    }
  },
  {
    id: "name",
    label: "Name",
    format: (value: string) => value
  },
  {
    id: "symbol",
    label: "Symbol",
    format: (value: string) => value
  },
  {
    id: "address",
    label: "Mint Address",
    format: (value: string) => {
      return (
        <a
          href={`https://explorer.solana.com/address/${value}`}
          target="_blank"
          rel="noopener noreferrer"
        >
          {value}
        </a>
      );
    }
  }
];

const useStyles = makeStyles({
  root: {
    width: "100%"
  },
  container: {
    maxHeight: "100%"
  }
});

export default function SolanaTokenList() {
  const classes = useStyles();
  const [tokenList, setTokenList] = useState<TokenListContainer | null>(null);
  const [page, setPage] = React.useState(0);
  const [rowsPerPage, setRowsPerPage] = React.useState(100);

  useEffect(() => {
    new TokenListProvider().resolve().then(setTokenList);
  }, [setTokenList]);

  const handleChangePage = (event: unknown, newPage: number) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (
    event: React.ChangeEvent<HTMLInputElement>
  ) => {
    setRowsPerPage(+event.target.value);
    setPage(0);
  };

  const rows = tokenList?.getList().filter(f => f.chainId === 101) ?? [];
  return (
    tokenList && (
      <Paper className={classes.root}>
        <TableContainer className={classes.container}>
          <Table stickyHeader aria-label="sticky table">
            <TableHead>
              <TableRow>
                {columns.map(column => (
                  <TableCell
                    key={column.id}
                    align={column.align}
                    style={{ minWidth: column.minWidth }}
                  >
                    {column.label}
                  </TableCell>
                ))}
              </TableRow>
            </TableHead>
            <TableBody>
              {rows
                .slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
                .map((row, idx) => {
                  return (
                    <TableRow hover role="checkbox" tabIndex={-1} key={idx}>
                      {columns.map((column, sidx) => {
                        const value = row[column.id];
                        return (
                          <TableCell key={sidx} align={column.align}>
                            {column.format ? column.format(value) : value}
                          </TableCell>
                        );
                      })}
                    </TableRow>
                  );
                })}
            </TableBody>
          </Table>
        </TableContainer>
        {/* @ts-ignore */}
        <TablePagination
          rowsPerPageOptions={[100, 300, 500, 1000]}
          component="div"
          count={rows.length}
          rowsPerPage={rowsPerPage}
          page={page}
          onChangePage={handleChangePage}
          onChangeRowsPerPage={handleChangeRowsPerPage}
        />
      </Paper>
    )
  );
}
