import React, { useEffect, useState } from "react";
import { Link } from "react-router-dom";
import _ from "lodash";
import { Spinner, Alert, Button } from "react-bootstrap";
import { toast } from "react-toastify";
import {
  fetchMyDpositWallets,
  createDepositWallet,
  fetchTestSolAirdropByDepositWallet
} from "../../../client/WalletManagerClient";
import { firebaseAuth } from "../../../app/utils/firebase";
import {
  getNetworkClusterName,
  convertNetworkNameToExplorerPath,
  showErrorToastMessage
} from "../../../app/utils/util";
import DepositWalletFAQ from "./component/DepositWalletFAQ";
import DepostWalletList from "./component/DepostWalletList";
import DepositWithdrawModal from "./component/DepositWithdrawModal";
import DepositAddressInfoModal from "./component/DepositAddressInfoModal";
import NetworkError from "../common/NetworkError";

export function MyDepositWallet({ props }) {
  const {
    auth: { authToken }
  } = props.auth;

  const currentUser = firebaseAuth.currentUser;

  const [isError, setIsError] = useState(false);
  const [isLoading, setIsLoading] = useState(true);
  const [isAirdropLoading, setIsAirdropLoading] = useState(false);
  const [depositWallets, setDepositWallets] = useState([]);
  const [
    depositAddressInfoModalShow,
    setDepositAddressInfoModalShow
  ] = useState(false);
  const [
    withdrawAddressInfoModalShow,
    setWithdrawAddressInfoModalShow
  ] = useState(false);

  useEffect(() => {
    getDepositWallets();
  }, []); // eslint-disable-line react-hooks/exhaustive-deps

  async function requestAirdrop() {
    setIsAirdropLoading(true);
    try {
      if (_.isEmpty(depositWallets)) {
        showErrorToastMessage(null, `Create a deposit wallet first`);
        return;
      }
      const response = await fetchTestSolAirdropByDepositWallet({
        authToken,
        walletAddress: depositWallets[0].ownerPublicKey
      });
      toast.info(
        <>
          Success! Please wait up to 30 seconds for the SOL tokens to appear in
          your wallet.
          <br />
          <a
            style={{ color: "#FFFFFF", fontWeight: "bold" }}
            href={`https://explorer.solana.com/tx/${
              response.txId
            }${convertNetworkNameToExplorerPath(getNetworkClusterName())}`}
            target="_blank"
            rel="noopener noreferrer"
          >
            View Transaction
          </a>
        </>,
        {
          position: "top-right",
          autoClose: 3000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true
        }
      );

      // 3초 후 가격새로고침
      setTimeout(() => {
        getDepositWallets(false);
      }, 3000);
    } catch (e) {
      showErrorToastMessage(e);
    }
    setIsAirdropLoading(false);
  }

  async function getDepositWallets(isLoadingFlag = true) {
    try {
      if (!isLoading && isLoadingFlag) {
        setIsLoading(true);
      }
      const response = await fetchMyDpositWallets({ authToken });
      setDepositWallets(response);
      setIsLoading(false);
      if (isError) {
        setIsError(false);
      }
    } catch (e) {
      showErrorToastMessage(e);

      setIsError(true);
      setIsLoading(false);
    }
  }

  async function handleCreateWallet() {
    if (!isLoading) {
      setIsLoading(true);
    }

    try {
      const response = await createDepositWallet({ authToken });
      if (response) {
        toast.info(`Deposit wallet has been created.`, {
          position: "top-right",
          autoClose: 2000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true
        });
        getDepositWallets();
      } else {
        showErrorToastMessage(
          null,
          `Failed to create wallet. please try again`
        );
        setIsLoading(false);
      }
    } catch (e) {
      showErrorToastMessage(e, `Failed to create wallet. please try again`);
      setIsLoading(false);
    }
  }

  if (isLoading) {
    return (
      <div style={{ textAlign: "center", marginTop: "60px" }}>
        <Spinner animation="grow" />
      </div>
    );
  }
  if (isError) {
    return <NetworkError retry={getDepositWallets} />;
  }

  // 예치금 월렛이 없는 경우
  if (!isLoading && _.isEmpty(depositWallets)) {
    return (
      <>
        {currentUser && !currentUser.emailVerified ? (
          <Alert variant="danger">
            E-mail authentication is required to use all functions.
            <Link
              style={{
                color: "#FFFFFF",
                fontWeight: "bold",
                marginLeft: "6px"
              }}
              to="/user-profile/email-settings"
            >
              Verify your email
            </Link>
          </Alert>
        ) : null}
        <DepositWalletFAQ createWallet={handleCreateWallet} />
        <div style={{ textAlign: "center", marginTop: "60px" }}>
          There is no deposit wallet.
        </div>
      </>
    );
  }

  return (
    <>
      <Alert show={true} variant="primary">
        <Alert.Heading>Deposits Management</Alert.Heading>
        <p>
          Deposit and manage your funds and assets.
          <br />
          Any transaction fees incurred will be covered by your funds here.
          <br />
        </p>
        <hr />
        {getNetworkClusterName() !== "MAIN_NET" && (
          <Button
            disabled={isAirdropLoading}
            style={{ borderColor: "#FFF", marginRight: "6px" }}
            onClick={() => {
              requestAirdrop();
            }}
            variant="primary"
          >
            {isAirdropLoading ? (
              <Spinner animation="grow" />
            ) : (
              "Request Airdrop"
            )}
          </Button>
        )}
        <div className="d-flex justify-content-end">
          <Button
            style={{ color: "#FFF", borderColor: "#FFF", marginRight: "6px" }}
            onClick={() => {
              setDepositAddressInfoModalShow(true);
            }}
            variant="success"
          >
            Deposit
          </Button>
          <Button
            style={{ color: "#FFF", borderColor: "#FFF" }}
            onClick={() => {
              setWithdrawAddressInfoModalShow(true);
            }}
            variant="danger"
          >
            Withdraw
          </Button>
          <Button
            style={{ color: "#FFF", borderColor: "#FFF", marginLeft: "6px" }}
            onClick={() => {
              getDepositWallets();
            }}
            variant="outline-info"
          >
            Refresh
          </Button>
        </div>
      </Alert>
      <DepostWalletList depositWallets={depositWallets} />
      {!_.isEmpty(depositWallets) && authToken && (
        <DepositAddressInfoModal
          depositWallets={depositWallets}
          depositAddressInfoModalShow={depositAddressInfoModalShow}
          setDepositAddressInfoModalShow={setDepositAddressInfoModalShow}
        />
      )}
      {!_.isEmpty(depositWallets) && authToken && (
        <DepositWithdrawModal
          authToken={authToken}
          depositWallets={depositWallets}
          withdrawAddressInfoModalShow={withdrawAddressInfoModalShow}
          getDepositWallets={getDepositWallets}
          setWithdrawAddressInfoModalShow={setWithdrawAddressInfoModalShow}
        />
      )}
    </>
  );
}
