import * as Crypto from "crypto";
import projectConfig from "../../application";
import { toast } from "react-toastify";

export const SOL_MINT = "So11111111111111111111111111111111111111112";
export const DXL_MINT = "GsNzxJfFn6zQdJGeYsupJWzUAm57Ba7335mfhWvFiE9Z";

export const DEFAULT_NETWORK = "MAIN_NET";

/**
 * AES Algorithm Based Data Encrypt Function
 * @param secretKey -  Encrypt Key
 * @param plainText - The Data Of To Be Encrypt
 */
export const encryptWithAES256 = (plainText, secretKey) => {
  let setKey = secretKey;
  const count = 32 - secretKey.length;
  let i = 0;
  while (i < count) {
    setKey = setKey + "0";
    i = i + 1;
  }
  const secretKeyToByteArray = Buffer.from(setKey, "utf8");
  const ivParameter = Buffer.from(setKey.slice(0, 16));
  const cipher = Crypto.createCipheriv(
    "aes-256-cbc",
    secretKeyToByteArray,
    ivParameter
  );
  let encryptedValue = cipher.update(plainText, "utf8", "base64");
  encryptedValue += cipher.final("base64");
  return encryptedValue;
};

export function getNetworkClusterName() {
  const getNetwork =
    localStorage.getItem(projectConfig.NETWORK_CONFIG_KEY) ??
    JSON.stringify({ selectedNetWork: DEFAULT_NETWORK });

  return JSON.parse(getNetwork).selectedNetWork ?? DEFAULT_NETWORK;
}

export function getSolanaNetworkClusterHost() {
  const getNetwork =
    localStorage.getItem(projectConfig.NETWORK_CONFIG_KEY) ??
    JSON.stringify({ selectedNetWork: DEFAULT_NETWORK });

  const network = JSON.parse(getNetwork).selectedNetWork ?? DEFAULT_NETWORK;
  if (network === "DEV_NET") {
    return projectConfig.SOLANA_DEV_NET_HOST;
  } else if (network === "TEST_NET") {
    return projectConfig.SOLANA_TEST_NET_HOST;
  }
  return projectConfig.SOLANA_SERUM_HOST;
}

export const convertNetworkNameToExplorerPath = network => {
  const nt = network ? network : getNetworkClusterName();
  if (nt === "DEV_NET") {
    return "?cluster=devnet";
  } else if (nt === "TEST_NET") {
    return "?cluster=testnet";
  }
  return "";
};

// address 길이를 4자로 자른다.
export function abbreviateAddress(address, size = 4) {
  return address.slice(0, size) + "…" + address.slice(-size);
}

export function numberWithCommasNormal(n) {
  if (!n || n === 0) {
    return 0;
  }
  var parts = n.toString().split(".");
  return (
    parts[0].replace(/\B(?=(\d{3})+(?!\d))/g, ",") +
    (parts[1] ? "." + parts[1] : "")
  );
}

export function showErrorToastMessage(
  e,
  defaultMessage = `A temporary network error has occurred.`
) {
  if (e && e.response && window) {
    if (e.response.status === 401) {
      toast.error(`The session has expired. please log in again`, {
        position: "top-right",
        autoClose: 3000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true
      });
      window.location.reload();
    } else {
      toast.error(e.response ? e.response.data.errorMessage : defaultMessage, {
        position: "top-right",
        autoClose: 3000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true
      });
    }
  } else {
    toast.error(e.response ? e.response.data.errorMessage : defaultMessage, {
      position: "top-right",
      autoClose: 3000,
      hideProgressBar: false,
      closeOnClick: true,
      pauseOnHover: true,
      draggable: true
    });
  }
}
