import firebase from 'firebase/app'
import projectConfig from '../../application'
import 'firebase/auth'

if (!firebase.apps.length) {
  firebase.initializeApp(projectConfig.firebaseConfig)
}

export const firebaseAuth = firebase.auth()
// export const googleProvider = new firebase.auth.GoogleAuthProvider()

export default firebase
