import React, { useEffect, useState } from "react";
import { Link } from "react-router-dom";
import _ from "lodash";
import { makeStyles, withStyles } from "@material-ui/core/styles";
import {
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
  Paper,
  Avatar
} from "@material-ui/core";
import { deepOrange, deepPurple } from "@material-ui/core/colors";
import {
  abbreviateAddress,
  numberWithCommasNormal,
  convertNetworkNameToExplorerPath
} from "../../../../app/utils/util";
import { tokenAccountInfo } from "../../../../util/solanaApi";
import { Spinner } from "react-bootstrap";

const StyledTableCell = withStyles(theme => ({
  head: {
    backgroundColor: "#8950FC",
    color: theme.palette.common.white
  },
  body: {
    fontSize: 14
  }
}))(TableCell);

const useStyles = makeStyles(theme => ({
  root: {
    width: "100%",
    marginTop: theme.spacing(3),
    overflowX: "auto"
  },
  table: {
    minWidth: 650
  },
  avatar: {
    margin: 10
  },
  orangeAvatar: {
    margin: 10,
    color: "#fff",
    backgroundColor: deepOrange[500]
  },
  purpleAvatar: {
    margin: 10,
    color: "#fff",
    backgroundColor: deepPurple[500]
  },
  button: {
    margin: theme.spacing(1)
  },
  input: {
    display: "none"
  }
}));

export default function MyTokenList({ myTokens }) {
  const classes = useStyles();

  const [tokenAccountInfos, setTokenAccountInfos] = useState([]);

  async function getTokenAccountInfo(mint) {
    const accountInfo = await tokenAccountInfo(mint);
    if (accountInfo === undefined) {
      console.log(`Not found token info.`);
    } else {
      return accountInfo;
    }
  }

  useEffect(() => {
    async function getTokenInfos() {
      const results = [];
      for (let i = 0; i < _.size(myTokens); i++) {
        const tokenInfo = await getTokenAccountInfo(myTokens[i].tokenMint);
        results.push(tokenInfo);
      }
      setTokenAccountInfos(results);
    }

    if (!_.isEmpty(myTokens)) {
      getTokenInfos();
    }
  }, [myTokens]);

  return (
    <Paper className={classes.root}>
      <Table className={classes.table}>
        <TableHead>
          <TableRow>
            <StyledTableCell style={{ wwordBreak: "break-all" }}>
              Icon
            </StyledTableCell>
            <StyledTableCell style={{ wwordBreak: "break-all" }}>
              Name
            </StyledTableCell>
            <StyledTableCell align="right">Symbol</StyledTableCell>
            <StyledTableCell align="right">Owner Wallet</StyledTableCell>
            <StyledTableCell style={{ wordBreak: "break-all" }} align="right">
              Mint
            </StyledTableCell>
            <StyledTableCell align="right">Supply</StyledTableCell>
          </TableRow>
        </TableHead>
        <TableBody>
          {myTokens.map((token, idx) => (
            <TableRow key={idx}>
              <StyledTableCell component="th" scope="row">
                <Avatar
                  alt={token.symbol}
                  src={token.symbolIconUrl}
                  className={classes.purpleAvatar}
                />
              </StyledTableCell>
              <StyledTableCell component="th" scope="row">
                <Link to={`/management/token/${token.tokenMint}/detail`}>
                  {token.nameEn}
                </Link>
              </StyledTableCell>
              <StyledTableCell align="right">{token.symbol}</StyledTableCell>
              <StyledTableCell align="right">
                {abbreviateAddress(token.mintAuthority, 6)}
              </StyledTableCell>
              <StyledTableCell align="right">
                <a
                  href={`https://explorer.solana.com/address/${
                    token.tokenMint
                  }${convertNetworkNameToExplorerPath(token.network)}`}
                  target="_blank"
                  rel="noopener noreferrer"
                  variant="primary"
                >
                  {abbreviateAddress(token.tokenMint, 6)}
                </a>
              </StyledTableCell>
              <StyledTableCell align="right">
                {tokenAccountInfos &&
                tokenAccountInfos.find(f => f.mint === token.tokenMint) ? (
                  numberWithCommasNormal(
                    tokenAccountInfos.find(f => f.mint === token.tokenMint)
                      .supply
                  )
                ) : (
                  <Spinner animation="grow" />
                )}
                <span style={{ fontSize: "10px", color: "#646464" }}>
                  {" "}
                  {token.symbol}
                </span>
              </StyledTableCell>
            </TableRow>
          ))}
        </TableBody>
      </Table>
    </Paper>
  );
}
